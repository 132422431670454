import { useEffect, useRef, useState } from "react";
import styles from "./Welcome.module.scss";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { Button, Modal, Select, Spin, Tooltip } from "antd";
import { ExpandOutlined, CloseOutlined } from "@ant-design/icons";
import sqImage from "../../../../img/vertical_logo_colour_icon_white-text.png";
import ScanningBenefits from "../scanning-benefits/ScanningBenefits";
import { actions, getBenchmark } from "../../../../store/benchmark";
import { useParams, useSearchParams } from "react-router-dom";
import TitlePlaceholder from "../../../platform/shared/modal/TitlePlaceholder";
import { IWelcome } from "../../../../models/benchmark/welcome/Welcome";
import classNames from "classnames";

const WelcomePage = () => {
  const videoRef = useRef<any>();
  const [openScanBenModal, setOpenScanBenModal] = useState<boolean>(false);
  const [dispatched, setDispatched] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);
  const [welcomeData, setWelcomeData] = useState<IWelcome | null>(null);

  const { buildingId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (buildingId === "251") {
      document.title = 'Light Projects - Beacon House';
    }
  }, [buildingId])

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (dispatched && benchmark) {
      setWelcomeData(benchmark?.welcome ?? null);
    }
  }, [dispatched, benchmark]);

  const setVideoPlayBack = () => {
    videoRef.current.playbackRate = 0.5;
  };

  const [activeItem, setActiveItem] = useState<number>((buildingId == "73") ? 8 : 0);
  const handleClick = (e: any) => {
    let value = e.target.getAttribute("data-value");
    if (value !== null) {
      setActiveItem(parseInt(value));
    }
  };

  const handleFloorChange = (floorIndex: number) => {
    setActiveItem(floorIndex);
  };

  const sliderWidthStyle = {
    width: `${(welcomeData?.benchmarkRawFootageLinks?.length ?? 1) * 100}%`,
  };
  const activeStyle =
    activeItem >= 1
      ? {
          transform: `translateX(-${
            activeItem *
            (100 / (welcomeData?.benchmarkRawFootageLinks?.length ?? 1))
          }%)`,
        }
      : {};

  return (
    <div className={styles.container}>
      {pending && (
        <Spin
          style={{
            display: "inline-flex",
            height: "calc(100vh - 64px)",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            zIndex: 5,
          }}
        />
      )}
      {!pending && welcomeData?.benchmarkLandingPageImage && (
        <img
          alt="cover"
          className={styles.custom}
          src={welcomeData?.benchmarkLandingPageImage}
        />
      )}

      <div className={styles.contentContainer}>
        <div
          className={styles.videoContainer}
          style={{ opacity: pending ? "0" : "1" }}
        >
          {!pending && welcomeData?.benchmarkRawFootageLinks && (
            <>
              <div className={styles.floorSelectContainer}>
                {buildingId !== "251" && (
                  <Select
                  style={{ minWidth: "200px", backgroundColor: "#130F21" }}
                  placeholder="Select Floor"
                  options={welcomeData.benchmarkRawFootageLinks?.map(
                    (scan, index) => ({
                      value: index,
                      label: scan.floorName,
                    })
                  )}
                  value={activeItem}
                  onChange={handleFloorChange}
                ></Select>
                )}
              </div>
              {welcomeData.benchmarkRawFootageLinks.some((scan) =>
                scan.scanRawFootageLink?.includes("matterport.com")
              ) ? (
                <div className={styles.slider}>
                  <div
                    className={styles.slideItems}
                    style={{ ...sliderWidthStyle, ...activeStyle }}
                  >
                    {welcomeData.benchmarkRawFootageLinks?.map(
                      (scan, index) => {
                        return (
                          <div
                            className={styles.item}
                            style={{
                              maxWidth: `
                                ${
                                  100 /
                                  (welcomeData.benchmarkRawFootageLinks
                                    ?.length ?? 1)
                                }%`,
                            }}
                            key={index}
                          >
                            <iframe
                              title="Matterport scan"
                              width="100%"
                              height="100%"
                              src={`${
                                scan.scanRawFootageLink
                              }&wts=3&ts=5&st=1000&qs=1&${
                                index === 0 ? "play=1" : "play=0"
                              }`}
                              frameBorder="0"
                              allowFullScreen
                              allow="xr-spatial-tracking"
                            ></iframe>
                          </div>
                        );
                      }
                    )}
                  </div>
                  {activeItem >= 1 && (
                    <button
                      className={classNames(
                        styles.slideArrow,
                        styles.slidePrev
                      )}
                      onClick={handleClick}
                      data-value={activeItem - 1}
                    >
                      <span></span>
                    </button>
                  )}
                  {activeItem <
                    welcomeData.benchmarkRawFootageLinks.length - 1 && (
                    <button
                      className={classNames(
                        styles.slideArrow,
                        styles.slideNext
                      )}
                      onClick={handleClick}
                      data-value={activeItem + 1}
                    >
                      <span></span>
                    </button>
                  )}
                  <ul onClick={handleClick} className={styles.indicators}>
                    {welcomeData?.benchmarkRawFootageLinks?.map(
                      (scanRawFootageLink, index) => {
                        return (
                          <Tooltip
                            key={index}
                            title={scanRawFootageLink.floorName}
                          >
                            <li
                              data-value={index}
                              key={index}
                              className={
                                activeItem === index ? styles.active : ""
                              }
                            ></li>
                          </Tooltip>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : (
                <div className={styles.slider}>
                  <div
                    className={styles.slideItems}
                    style={{ ...sliderWidthStyle, ...activeStyle }}
                  >
                    {welcomeData.benchmarkRawFootageLinks?.map(
                      (link, index) => {
                        return (
                          <div
                            className={styles.item}
                            style={{
                              maxWidth: `
                              ${
                                100 /
                                (welcomeData.benchmarkRawFootageLinks?.length ??
                                  1)
                              }%`,
                            }}
                            key={index}
                          >
                            <video
                              ref={videoRef}
                              className="video-js vjs-default-skin"
                              controls
                              autoPlay
                              loop
                              muted
                              onCanPlay={() => setVideoPlayBack()}
                              preload="true"
                              width="100%"
                              height="100%"
                            >
                              <source
                                src={link.scanRawFootageLink ?? ""}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        );
                      }
                    )}
                  </div>
                  {activeItem >= 1 && (
                    <button
                      className={classNames(
                        styles.slideArrow,
                        styles.slidePrev
                      )}
                      onClick={handleClick}
                      data-value={activeItem - 1}
                    >
                      <span></span>
                    </button>
                  )}
                  {activeItem <
                    welcomeData.benchmarkRawFootageLinks.length - 1 && (
                    <button
                      className={classNames(
                        styles.slideArrow,
                        styles.slideNext
                      )}
                      onClick={handleClick}
                      data-value={activeItem + 1}
                    >
                      <span></span>
                    </button>
                  )}
                  <ul onClick={handleClick} className={styles.indicators}>
                    {welcomeData?.benchmarkRawFootageLinks?.map(
                      (scanRawFootageLink, index) => {
                        return (
                          <Tooltip
                            key={index}
                            title={scanRawFootageLink.floorName}
                          >
                            <li
                              data-value={index}
                              key={index}
                              className={
                                activeItem === index ? styles.active : ""
                              }
                            ></li>
                          </Tooltip>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.scanBenContainer}>
          {buildingId !== "251" && (
            <img alt="sqLogo" src={sqImage} />
          )}
          {buildingId === "251" && (
            <img alt="sqLogo" src={"https://lightprojects.co.uk/wp-content/uploads/2019/04/logo.png"} />
          )}
          
          <Button
            size="middle"
            style={{
              borderRadius: "6px",
              height: "40px",
              width: "100%",
              color: "#FFFFFF",
              backgroundColor: "#171833",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "16px",
              border: "none",
              background:
                "linear-gradient(90deg, #F30531 0%, #752889 55.63%, #313AB8 100%)",
              boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.04)",
            }}
            onClick={() => setOpenScanBenModal(true)}
          >
            <ExpandOutlined />
            Scanning benefits
          </Button>
        </div>
      </div>

      <Modal
        title={<TitlePlaceholder />}
        closeIcon={
          <CloseOutlined style={{ color: "#FFFFFF", fontSize: "24px" }} />
        }
        bodyStyle={{ margin: "-50px -25px -20px -25px" }}
        open={openScanBenModal}
        width="100%"
        centered
        onCancel={() => setOpenScanBenModal(false)}
        footer={null}
      >
        <ScanningBenefits />
      </Modal>
    </div>
  );
};

export default WelcomePage;
