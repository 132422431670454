import { useEffect } from "react";
import { updateGridLayer, useLeafletContext } from "@react-leaflet/core";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import L from "leaflet";

import polylabel from "polylabel";
import {
  findMissingRoomNumbers,
  randomHexColor,
} from "../../../../../utils/miscellaneous";
import { IRoom } from "../../../../../models/floorplan/Floorplan";

const Geoman = ({
  floorId,
  editable,
  rooms,
  handleActiveRoom,
  handleCreateRoom,
  handleRoomDelete,
  handleUpdateRoom,
}: {
  floorId: number;
  editable?: boolean | null;
  rooms: IRoom[];
  handleActiveRoom: (activeFeature: any) => void;
  handleCreateRoom: (newRoom: any, roomLayers: any[]) => void;
  handleRoomDelete: (roomId: number, roomLayers: any[]) => void;
  handleUpdateRoom: (roomLayer: any, roomLayers: any[]) => void;
}) => {
  const context = useLeafletContext();

  useEffect(() => {
    const leafletContainer: any = context.layerContainer || context.map;
    if (editable) {
      leafletContainer.pm.addControls({
        drawPolyline: false,
        drawMarker: false,
        drawCircleMarker: false,
        drawCircle: false,
        cutPolygon: false,
        deleteLayer: false,
      });
    }

    leafletContainer.pm.setGlobalOptions({ pmIgnore: false });

    //on add polygon layers to map
    leafletContainer.on("layeradd", (e: any) => {
      const layer = e.layer;
      const geomanLayers = leafletContainer.pm.getGeomanLayers();
      //skip layer for map image and rooms with defined area tooltip
      if (
        !layer._image &&
        !layer._container &&
        !layer._icon &&
        !layer.areaTooltip &&
        !layer._pmTempLayer
      ) {
        let roomNumber = findMissingRoomNumbers(
          [...geomanLayers]
            .filter(
              (gl) => gl.properties && gl.properties.drawType === "Polygon"
            )
            .map((gl) => gl.properties.roomNumber)
        );

        roomNumber = roomNumber ? roomNumber : 1;
        let newRoom: IRoom = {};
        if (!layer.properties) {
          // Trigged when a new layer (room) is created
          const newColor = randomHexColor();
          layer.setStyle({ color: newColor });
          layer.properties = {
            color: newColor,
            drawType: "Polygon",
            id: 0,
            name: "",
            roomNumber: roomNumber ? roomNumber : 1,
          };
          layer.floorId = floorId;

          newRoom = {
            type: "Feature",
            properties: layer.properties,
            geometry: layer.toGeoJSON().geometry,
            floorId: floorId,
          };
        }
        layer.on("pm:edit", (e: any) => {
          console.log("edit code", rooms);
          updateAreaTooltip(e.layer, `${e.layer.properties.roomNumber}`);
          handleUpdateRoom(e.layer, rooms);
        });
        layer.on("click", (e: any) => {
          if (!isEditModeActive(leafletContainer.pm)) {
            if (e.target.feature) handleActiveRoom(e.target.feature);
            const newRoom = {
              type: "Feature",
              properties: e.target.properties,
              geometry: e.target.toGeoJSON().geometry,
              floorId: floorId,
            };
            handleActiveRoom(newRoom);
          }
        });
        createAreaTooltip(layer, `${roomNumber}`);
        if (Object.keys(newRoom).length) {
          console.log("create code", rooms);
          handleCreateRoom(newRoom, getRoomLayers(geomanLayers));
        }
      }
      // console.log(leafletContainer.pm.getGeomanLayers(true).toGeoJSON());
      return null;
    });

    function importGeoJSON(room: IRoom, index: number) {
      if (room) {
        const feature: any = createFeature(room, index);
        var geoLayer = L.geoJSON(feature, {
          style: function (feat) {
            if (feat) return feat.properties.options;
          },
          pointToLayer: function (feature, latlng) {
            switch (feature.properties.drawType) {
              case "marker":
                return new L.Marker(latlng);
              case "circle":
                return new L.Circle(latlng, feature.properties.options);
              case "circlemarker":
                return new L.CircleMarker(latlng, feature.properties.options);
              default:
                return new L.Marker(latlng);
            }
          },
        });

        geoLayer.getLayers().forEach((layer: any, index: number) => {
          layer.properties = feature.properties;
          layer.properties.id = feature.properties.id;
          layer.floorId = feature.floorId;
          const color = layer.feature.properties.color
            ? layer.feature.properties.color
            : randomHexColor();
          layer.setStyle({ color: color });
          layer.addTo(leafletContainer);
        });
      }
    }

    function updateLayer(room: IRoom) {
      leafletContainer.eachLayer((layer: any) => {
        if (room.id === layer.properties?.id) {
          layer.properties.name = room.name;
          layer.properties.color = room.color;
          layer.setStyle({ color: room.color });
          if (layer.feature?.properties) {
            layer.feature.properties.name = room.name;
            layer.feature.properties.color = room.color;
          }
        }
      });
    }

    function createAreaTooltip(layer: any, content: string | number) {
      if (layer.areaTooltip) {
        return;
      }
      layer.areaTooltip = L.tooltip({
        permanent: true,
        direction: "center",
        className: "area-tooltip",
      });

      layer.areaTooltip.properties = {
        type: "tooltip",
      };

      if (leafletContainer.pm.map.hasLayer(layer)) {
        updateAreaTooltip(layer, content);
        layer.areaTooltip.addTo(leafletContainer.pm.map);
      }
    }

    function updateAreaTooltip(layer: any, content: string | number) {
      const layerGeoJson = layer.toGeoJSON();
      var polylineCenterCoords = polylabel(
        layerGeoJson.geometry.coordinates,
        1.0
      );
      var latlng = L.latLng(polylineCenterCoords[1], polylineCenterCoords[0]);

      layer.areaTooltip.setContent(content).setLatLng(latlng);
    }

    //remove layers from map that are not in the rooms array
    leafletContainer.eachLayer((layer: any) => {
      if (layer._image || layer._icon || !layer.areaTooltip) return;

      layer.areaTooltip.remove();
      leafletContainer.removeLayer(layer);
    });
    //add layers to map if they are in rooms array
    rooms.forEach((room: IRoom, index: number) => {
      importGeoJSON(room, index);
    });

    return () => {
      leafletContainer.pm.removeControls();
      leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
    };
  }, [
    context,
    rooms,
    handleActiveRoom,
    editable,
    floorId,
    handleCreateRoom,
    handleUpdateRoom,
    handleRoomDelete,
  ]);

  return null;
};

export default Geoman;

const createFeature = (room: IRoom, index: number) => {
  const feature = {
    type: "Feature",
    floorId: room.floorId,
    properties: {
      id: room.id,
      name: room.name,
      roomNumber: index,
      color: room.color,
      drawType: room.transformedGeometry?.type,
      grossArea: room.grossArea,
      voidArea: room.voidArea,
      fixedFurnitureArea: room.fixedFurnitureArea,
    },
    geometry: room.transformedGeometry,
  };
  return feature;
};

const isEditModeActive = (leafletContainerPm: any) => {
  if (leafletContainerPm.globalEditModeEnabled()) return true;
  else if (leafletContainerPm.globalDrawModeEnabled()) return true;
  else if (leafletContainerPm.globalDragModeEnabled()) return true;
  else if (leafletContainerPm.globalRemovalModeEnabled()) return true;
  else if (leafletContainerPm.globalCutModeEnabled()) return true;
  else if (leafletContainerPm.globalRotateModeEnabled()) return true;
  else return false;
};

const isRoomUpdated = (room: IRoom, loadedLayer: any) => {
  if (
    room.name !== loadedLayer.properties?.name ||
    room.color !== loadedLayer.properties?.color
  ) {
    return true;
  } else return false;
};

const getRoomLayers = (geomanLayers: any[]) => {
  const roomLayers = geomanLayers.filter(
    (layer) =>
      !layer._image &&
      !layer._container &&
      !layer._icon &&
      layer.areaTooltip &&
      !layer._pmTempLayer
  );
  return roomLayers;
};
