import { Col, Form, Input, InputNumber, Row, Select, Space, Spin } from "antd";
import { RoomMapping } from "../../../shared/roomMapping/RoomMapping";

import styles from "../Buildings.module.css";

import { useEffect, useState } from "react";
import { Typography } from "antd";
import ScansService from "../../../../../services/scans/ScansService";
import AssetsService from "../../../../../services/assets/AssetsService";
import {
  IAsset,
  IAssetCleaningTask,
  IFloor,
  IRoom,
  IRoomAsset,
} from "../../../../../models/floorplan/Floorplan";
import BuildingsService from "../../../../../services/buildings/BuildingsService";
import AssetCleaningTasksService from "../../../../../services/assetcleaningtasks/AssetCleaningTasksService";
import { extractMatterportFileId } from "../../../../../utils/miscellaneous";
import MatterportSpacesService from "../../../../../services/import/MatterportSpacesService";
import { IScanResponse } from "../../../../../models/scans/ScanResponse";

const { Title } = Typography;
const emptyFloor: IFloor = {
  id: 0,
  name: "",
  rooms: [],
  key: 0,
  imageComponent: "",
};

const Floorplan = ({ building }: { building: any }) => {
  const [inputGross, setInputGross] = useState<any>("");
  const [inputWalkingDistance, setInputWalkingDistance] = useState<any>("");
  const [inputVoid, setInputVoid] = useState<any>("");
  const [inputCleanable, setInputCleanable] = useState("");
  const [inputFixedFurniture, setInputFixedFurniture] = useState<any>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFloorId, setSelectedFloorId] = useState<number | null>(null);
  const [selectedFloor, setSelectedFloor] = useState<IFloor | null>(null);
  const [floors, setFloors] = useState<IFloor[] | null>(null);
  const [rooms, setRooms] = useState<IRoom[] | null>(null);
  const [assets, setAssets] = useState<IAsset[] | null>(null);
  const [floorplanImgUrl, setFloorplanImgUrl] = useState<string | null>(null);
  const [matterportFileIds, setMatterportFileIds] = useState<string[] | null>(
    null
  );
  const [buildingScans, setBuildingScans] = useState<IScanResponse[] | null>(
    null
  );
  const [loadedScan, setLoadedScan] = useState<IScanResponse | null>(null);
  const [assetCleaningTasks, setAssetCleaningTasks] = useState<
    IAssetCleaningTask[] | null
  >();

  useEffect(() => {
    if (building?.id) {
      setLoading(true);
      BuildingsService.get(building.id).then((response: any) => {
        const building = response.data;
        if (building.floors.length > 0) {
          setSelectedFloorId(building.floors[0].id ?? 0);
          setSelectedFloor(building.floors[0] ?? emptyFloor);
          setFloors(building.floors ?? []);
          setRooms(building.floors?.flatMap((f: any) => f.rooms) ?? []);
        } else {
          setSelectedFloorId(0);
          setSelectedFloor(emptyFloor);
          setFloors([]);
          setRooms([]);
        }

        setBuildingScans(building.scans ?? []);

        AssetCleaningTasksService.get(building.buildingTypeId).then(
          (response: any) => {
            setAssets(
              response.data
                .map((a: IAssetCleaningTask) => a.asset)
                .filter(
                  (item: IAsset, index: number, self: any) =>
                    self.findIndex((s: IAsset) => item.id === s.id) === index
                ) ?? []
            );
            setAssetCleaningTasks(
              response.data.filter(
                (a: IAssetCleaningTask) =>
                  a.asset &&
                  a.roomType &&
                  a.assetMaterial &&
                  a.assetSize &&
                  a.cleaningTask
              ) ?? []
            );
          }
        );
      });
    }
  }, [building]);

  useEffect(() => {
    if (buildingScans && buildingScans.length > 0 && selectedFloor) {
      // If the selectedFloor doesn't belong under loadedScan, load the appropriate scan
      if (selectedFloor?.scanId && selectedFloor?.scanId !== loadedScan?.id) {
        const matterportFileId = extractMatterportFileId(
          buildingScans.find((scan) => scan.id === selectedFloor?.scanId)
        );
        if (matterportFileId) {
          MatterportSpacesService.get(matterportFileId).then(
            (response: any) => {
              if (response.data?.assets?.floorplans) {
                const floorplanMediaAssets =
                  response.data.assets.floorplans.find(
                    (f: any) =>
                      f.filename ===
                      `colorplan_00${selectedFloor.scanSequence}.jpg`
                  );
                if (floorplanMediaAssets) {
                  //TODO check validUnitl field
                  setFloorplanImgUrl(floorplanMediaAssets.url);
                } else setFloorplanImgUrl("");
              } else setFloorplanImgUrl("");
            }
          );
        }
      } else if (loadedScan) {
        const matterportFileId = extractMatterportFileId(loadedScan);
        if (matterportFileId) {
          MatterportSpacesService.get(matterportFileId).then(
            (response: any) => {
              if (response.data?.assets?.floorplans) {
                const floorplanMediaAssets =
                  response.data.assets.floorplans.find(
                    (f: any) =>
                      f.filename ===
                      `colorplan_00${selectedFloor.scanSequence}.jpg`
                  );
                if (floorplanMediaAssets) {
                  //TODO check validUnitl field
                  setFloorplanImgUrl(floorplanMediaAssets.url);
                } else setFloorplanImgUrl("");
              } else setFloorplanImgUrl("");
            }
          );
        }
      }
    }
  }, [buildingScans, loadedScan, selectedFloor]);

  useEffect(() => {
    if (selectedFloorId === null) return;
    else if (selectedFloor === null) return;
    else if (floors === null) return;
    else if (rooms === null) return;
    else if (assets === null) return;
    else if (floorplanImgUrl === null) return;
    else if (assetCleaningTasks === null) return;
    else if (buildingScans === null) return;

    setLoading(false);
  }, [
    selectedFloorId,
    selectedFloor,
    floors,
    rooms,
    assets,
    floorplanImgUrl,
    assetCleaningTasks,
    buildingScans,
  ]);

  const handleFloorChange = (value: number) => {
    if (value) {
      const activeFloor = floors?.find((f: IFloor) => f.id === value);
      setSelectedFloor(activeFloor ?? emptyFloor);
      setSelectedFloorId(activeFloor?.id ?? 0);
    }
  };

  const onGrossInputChange = (value: number | null) => {
    setInputGross(value);
  };

  const onWalkingDistanceInputChange = (value: number | null) => {
    setInputWalkingDistance(value);
  };

  const onVoidInputChange = (value: number | null) => {
    setInputVoid(value);
  };

  const onFixedFurnitureInputChange = (value: number | null) => {
    setInputFixedFurniture(value);
  };

  return (
    <div className={styles.formWrap}>
      <>
        {loading && (
          <Spin
            style={{
              transform: "translate(-50%, -50%)",
              left: "calc(50vw + 125px)",
              top: "50vh",
              position: "fixed",
            }}
          />
        )}
        {!loading && (
          <>
            <div>
              <Form
                className={styles.formSection}
                name="Floorplan"
                initialValues={{
                  remember: true,
                  floor: floors?.[0]?.id,
                  gross: floors?.[0]?.grossArea ?? 0,
                  walkingDistance: 0,
                  void: floors?.[0]?.voidArea ?? 0,
                  cleanable: 0,
                  fixedFurniture: floors?.[0]?.fixedFurnitureArea ?? 0,
                }}
                onFinish={() => null}
              >
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      // labelCol={{ span: 4 }}
                      // wrapperCol={{ offset: 2 }}
                      // label="Name"
                      name="floor"
                    >
                      <Select
                        placeholder="Select Floor"
                        options={floors?.map((x: IFloor) => ({
                          value: x.id,
                          label: x.name,
                        }))}
                        onChange={handleFloorChange}
                        loading={loading}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
                  Floorplan data
                </Title>
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ offset: 2 }}
                      label="Gross"
                      name="gross"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        value={inputGross}
                        onChange={onGrossInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ offset: 2 }}
                      label="Walking distance"
                      name="walkingDistance"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        value={inputWalkingDistance}
                        onChange={onWalkingDistanceInputChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ offset: 2 }}
                      label="Void"
                      name="void"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        value={inputVoid}
                        onChange={onVoidInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ offset: 2 }}
                      label="Cleanable"
                      name="cleanable"
                    >
                      <Input
                        bordered={false}
                        value={inputCleanable}
                        //   onInput={onCleanableInputChange}
                        placeholder="name"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ offset: 2 }}
                      label="Fixed furniture"
                      name="fixedFurniture"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        value={inputFixedFurniture}
                        onChange={onFixedFurnitureInputChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            {floors &&
            rooms &&
            assets &&
            assetCleaningTasks &&
            selectedFloorId &&
            selectedFloor &&
            floorplanImgUrl ? (
              <RoomMapping
                buildingId={building.id}
                floors={floors}
                rooms={rooms}
                assets={assets}
                assetCleaningTasks={assetCleaningTasks}
                selectedFloorId={selectedFloorId}
                selectedFloor={selectedFloor}
                floorplanImgUrl={floorplanImgUrl}
                matterportLink={
                  buildingScans?.find(
                    (scan) => scan.id === selectedFloor.scanId
                  )?.benchmarkRawFootageLink
                }
                config={{
                  editable: true,
                  minified: true,
                  minZoom: 3.5,
                  enableVirtualWalk: true,
                  floorSelect: false,
                }}
                handleActiveFloorChange={handleFloorChange}
              />
            ) : (
              <Space
                style={{
                  height: "400px",
                  marginLeft: "-165px",
                  justifyContent: "center",
                  fontSize: "16px",
                }}
                align="center"
              >
                <label>No Floorplan Scan Data</label>
              </Space>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Floorplan;
