import styles from "../dashboard/Dashboard.module.scss";
import { Button, Carousel, Image, Progress, Space, Table, Tag } from "antd";
import shape1 from "../../../../img/shape_dashboard.png";
import shape2 from "../../../../img/shape_info.png";
import logoBlue from "../../../../img/Logomark_dblue.png";
import {
  ArrowRightOutlined,
  LinkOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  DesktopOutlined,
  EditOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { Typography } from "antd";
import { useEffect, useState } from "react";
import BuildingTypesService from "../../../../services/buildingtypes/BuildingTypesService";
import BuildingsService from "../../../../services/buildings/BuildingsService";
import { ROUTES } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import { actions } from "../../../../store/benchmark";
import { useAppDispatch } from "../../../../store/hooks";
import ScansService from "../../../../services/scans/ScansService";
import { useSelector } from "react-redux";

const { Title } = Typography;

const Dashboard = () => {
  const { profile } = useSelector((state: any) => ({
    profile: state.profile.profile,
  }));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshTriggered, setRefreshTriggered] = useState<any>(false);
  const [buildingTypes, setBuildingTypes] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isDateWithin24Hours = (date: any) => {
    const then = new Date(date);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    if (hoursBetweenDates < 24) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setInterval(() => getBuildings(), 5 * 1000);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, []);

  const getBuildings = () => {
    if (!document.hidden) {
      BuildingTypesService.getAll().then((response: any) => {
        setBuildingTypes(response.data);

        ScansService.getAll().then((scansResponse: any) => {
          setData(
            scansResponse.data
              .filter((s: any) => !profile.isGuestUser || (profile.associatedScan ? s.id == profile.associatedScan : true))
              .sort(function (scanA: any, scanB: any) {
                const scannedBuildingA = scanA.buildings
                  ? scanA.buildings[0]
                  : null;
                const scannedBuildingB = scanB.buildings
                  ? scanB.buildings[0]
                  : null;

                return scannedBuildingB.id - scannedBuildingA.id;
              })
              .slice(0, 5)
              .map((scan: any) => {
                const scannedBuilding = scan.buildings
                  ? scan.buildings[0]
                  : null; // TODO - handle multiple buildings
                return {
                  ...scannedBuilding,
                  floorCount: scannedBuilding.floors.length,
                  type: response.data.find(
                    (y: any) => y.id === scannedBuilding.buildingTypeId
                  ).name,
                  scan: scan.name,
                  scanStatus: scan.status,
                  createTime: scannedBuilding.createTime.split(".")[0],
                  isNew: isDateWithin24Hours(scannedBuilding.createTime),
                  assets: scan && scan.log ? scan.log.assets : "N/A",
                  percentageDone: scan && scan.log ? scan.log.percentComplete : -1,
                  timeLeftInSec: scan && scan.log ? scan.log.timeLeftInSec : 0,
                };
              })
          );
          setIsLoading(false);
        });
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    BuildingTypesService.getAll().then((response: any) => {
      setBuildingTypes(response.data);

      ScansService.getAll().then((scansResponse: any) => {
        setData(
          scansResponse.data
            .filter((s: any) => !profile.isGuestUser || (profile.associatedScan ? s.id == profile.associatedScan : true))
            .sort(function (scanA: any, scanB: any) {
              const scannedBuildingA = scanA.buildings
                ? scanA.buildings[0]
                : null;
              const scannedBuildingB = scanB.buildings
                ? scanB.buildings[0]
                : null;

              return scannedBuildingB.id - scannedBuildingA.id;
            })
            .slice(0, 5)
            .map((scan: any) => {
              const scannedBuilding = scan.buildings ? scan.buildings[0] : null; // TODO - handle multiple buildings
              return {
                ...scannedBuilding,
                floorCount: scannedBuilding.floors.length,
                type: response.data.find(
                  (y: any) => y.id === scannedBuilding.buildingTypeId
                ).name,
                scan: scan.name,
                scanStatus: scan.status,
                createTime: scannedBuilding.createTime.split(".")[0],
                isNew: isDateWithin24Hours(scannedBuilding.createTime),
                assets: scan && scan.log ? scan.log.assets : "N/A",
                percentageDone: scan && scan.log ? scan.log.percentComplete : -1,
                timeLeftInSec: scan && scan.log ? scan.log.timeLeftInSec : 0,
              };
            })
        );
        setIsLoading(false);
      });
    });
  }, [profile]);

  const handleRowClick = (buildingId: number) => {
    window.open(`/benchmark/${buildingId}/welcome`, "_blank");
  };

  const handleGetStartedClick = () => {
    navigate("/import");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
      render: (text: any, record: any) => (
        <Space>
          <span>{text}</span>
          {record.isNew && <Tag color="purple">NEW</Tag>}
        </Space>
      ),
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   elipsis: true,
    // },
    // {
    //   title: "Scan",
    //   dataIndex: "scan",
    //   key: "scan",
    //   elipsis: true,
    // },
    {
      title: "Scan status",
      dataIndex: "scanStatus",
      key: "scanStatus",
      elipsis: true,
      render: (text: any, record: any) => (
        <div>
          {text === "Success" ||
            (text === "Completed" && (
              <Tag icon={<CheckCircleOutlined />} color="success">
                completed
              </Tag>
            ))}
          {text === "Processing" && (
            <Tag icon={<SyncOutlined spin />} color="processing">
              processing
            </Tag>
          )}
          {text === "NotStarted" && (
            <Tag
              icon={<ClockCircleOutlined />}
              color="waiting"
              style={{ background: "rgba(0, 0, 0, 0.40)" }}
            >
              in queue
            </Tag>
          )}
          {text === "Failed" && (
            <Tag icon={<CloseCircleOutlined />} color="error">
              error
            </Tag>
          )}
        </div>
      ),
    },
    // {
    //   title: "Floors",
    //   dataIndex: "floorCount",
    //   key: "floorCount",
    //   elipsis: true,
    // },
    {
      title: "Assets",
      dataIndex: "assets",
      key: "assets",
      elipsis: true,
    },
    {
      title: "Scanning Done",
      dataIndex: "percentageDone",
      key: "percentageDone",
      elipsis: true,
      render: (text:any, record: any) => (
        <div>
          {!text || text === -1 && (
            <>N/A</>
          )}
          {text && text !== -1 && (
            <Progress percent={text} />
          )}
        </div>
      )
    },
    {
      title: "Time left",
      dataIndex: "timeLeftInSec",
      key: "timeLeftInSec",
      elipsis: true,
      render: (text: any, record: any) => (
        <div>
          {!text && (
            <>N/A</>
          )}
          {/* {(text == 0) && (
            <Tag icon={<CheckCircleOutlined />} color="success">
              completed
            </Tag>
          )} */}
          {text != 0 && (
            <>Approx. {Math.round(text/60)} mins</>
          )}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "createTime",
      key: "createTime",
      elipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<DesktopOutlined />}
            href={ROUTES.benchmark.welcome.route}
            target="_blank"
            style={{ color: "#313AB8" }}
          />
          {/* <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditBuilding(record)}
            style={{ color: "#313AB8" }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{ color: "#313AB8" }}
          /> */}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.bodyWrap}>
      <div>
        <div className={styles.welcomeMessage}>
          <Title className={styles.welcomeTitle}>
            Welcome to ScanQuo Platform, {profile.firstName}
          </Title>
          <p>Company: ScanQuo Ltd</p>
        </div>
        <div className={styles.mobileWelcomeMessage}>
          <Title className={styles.welcomeTitle}>
            Welcome to ScanQuo Platform, {profile.firstName}
          </Title>
          <p>Company: ScanQuo Ltd</p>
        </div>
        <div className={styles.gradient}></div>
      </div>

      <div className={styles.infoTitle}>
        <Title style={{ margin: "0" }} level={3}>
          Let's get started
        </Title>
      </div>

      <div className={styles.bodyContent}>
        <div className={styles.infoContainer}>
          <div className={styles.infoCard}>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={2}
            >
              1
            </Title>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={4}
            >
              Scan your area
            </Title>
            <div className={styles.infoText}>
              Scan a room, floor or a complete building using a camera device,
              one of professional tools like Matterport or ScanQuo Mobile App.
            </div>
          </div>
          <div className={styles.infoCard}>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={2}
            >
              2
            </Title>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={4}
            >
              Upload into Platform
            </Title>
            <div className={styles.infoText}>
              Upload your captured scan files, floorplans into ScanQuo Platform.
              Our automations will kick in, process and extract data for your
              benchmark.
            </div>
          </div>
          <div className={styles.infoCard}>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={2}
            >
              3
            </Title>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={4}
            >
              Customize your data
            </Title>
            <div className={styles.infoText}>
              Attach scan to a building, start entering building data, benchmark
              calculation variables, customize imported floorplan and much more!
            </div>
          </div>
          <div className={styles.infoCard}>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={2}
            >
              4
            </Title>
            <Title
              style={{ margin: "20px", color: "#fff", zIndex: "100" }}
              level={4}
            >
              Present your benchmark
            </Title>
            <div className={styles.infoText}>
              Use our ScanQuo Benchmark tool to visualize calculated benchmarks,
              all derivated from your input source & calculation variables!
            </div>
          </div>
        </div>
        <div className={styles.mobileInfoContainer}>
          <Carousel>
            <div className={styles.infoCard}>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={2}
              >
                1
              </Title>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={4}
              >
                Scan your area
              </Title>
              <div className={styles.infoText}>
                Scan a room, floor or a complete building using a camera device,
                one of professional tools like Matterport or ScanQuo Mobile App.
              </div>
            </div>
            <div className={styles.infoCard}>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={2}
              >
                2
              </Title>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={4}
              >
                Upload into Platform
              </Title>
              <div className={styles.infoText}>
                Upload your captured scan files, floorplans into ScanQuo
                Platform. Our automations will kick in, process and extract data
                for your benchmark.
              </div>
            </div>
            <div className={styles.infoCard}>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={2}
              >
                3
              </Title>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={4}
              >
                Customize your data
              </Title>
              <div className={styles.infoText}>
                Attach scan to a building, start entering building data,
                benchmark calculation variables, customize imported floorplan
                and much more!
              </div>
            </div>
            <div className={styles.infoCard}>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={2}
              >
                4
              </Title>
              <Title
                style={{
                  margin: "20px",
                  color: "#fff",
                  zIndex: "100",
                  textAlign: "center",
                }}
                level={4}
              >
                Present your benchmark
              </Title>
              <div className={styles.infoText}>
                Use our ScanQuo Benchmark tool to visualize calculated
                benchmarks, all derivated from your input source & calculation
                variables!
              </div>
            </div>
          </Carousel>
        </div>

        {!profile.isGuestUser && (
          <div className={styles.buttonContainer}>
            <div
              className={styles.startingButton}
              onClick={handleGetStartedClick}
            >
              <p>You have scanned your area? Get started with importing!</p>
              <Button
                style={{ color: "#fff" }}
                type="text"
                icon={<ArrowRightOutlined />}
              />
            </div>
          </div>
        )}

        <div className={styles.benchamrkTitle}>
          <Title style={{ margin: "0" }} level={3}>
            Benchmark Results
          </Title>
        </div>

        <div className={styles.benchmarkResults}>
          <Table
            pagination={false}
            loading={isLoading}
            size="middle"
            dataSource={data}
            columns={columns}
            rowClassName={styles.tableRow}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  dispatch(actions.resetBenchmark());
                  handleRowClick(record.id ?? 1);
                },
              };
            }}
          />
        </div>

        {/* <div className={styles.statisticsContainer}>
          <div className={styles.statisticsCard}>
            <Title style={{ marginTop: "20px", marginLeft: "20px" }} level={4}>
              Subscription status
            </Title>
            <div className={styles.contentWrap}>
              <div className={styles.content}>
                <span className={styles.label}>Status:</span>
                <Tag color="green">Active</Tag>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>Buildings:</span>
                <Progress percent={50} size="small" />
              </div>
              <div className={styles.content}>
                <span className={styles.label}>Users:</span>
                <Progress percent={90} size="small" />
              </div>
              <div className={styles.content}>
                <span className={styles.label}>Lite Users:</span>
                <Progress percent={0} size="small" />
              </div>
            </div>
          </div>
          <div className={styles.statisticsCard}>
            <Title style={{ marginTop: "20px", marginLeft: "20px" }} level={4}>
              Recent edits
            </Title>
            <div className={styles.contentWrap}>
              <div className={styles.content}>
                <span className={styles.label}>NHS</span>
                <div className={styles.date_link}>
                  <span>11/12/2022, 14:33</span>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>Test 1</span>
                <div className={styles.date_link}>
                  <span>11/12/2022, 14:33</span>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>OCS</span>
                <div className={styles.date_link}>
                  <span>11/12/2022, 14:33</span>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>OCS 2</span>
                <div className={styles.date_link}>
                  <span>11/12/2022, 14:33</span>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>Santander</span>
                <div className={styles.date_link}>
                  <span>11/12/2022, 14:33</span>
                  <LinkOutlined />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.statisticsCard}>
            <Title style={{ marginTop: "20px", marginLeft: "20px" }} level={4}>
              Newest scans
            </Title>
            <div className={styles.contentWrap}>
              <div className={styles.content}>
                <span className={styles.label}>Scan_005</span>
                <div className={styles.date_link}>
                  <Tag color="default" icon={<MinusCircleOutlined />}>
                    In queue
                  </Tag>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>Test 1</span>
                <div className={styles.date_link}>
                  <Tag color="processing" icon={<SyncOutlined />}>
                    Processing
                  </Tag>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>OCS</span>
                <div className={styles.date_link}>
                  <Tag color="success" icon={<CheckCircleOutlined />}>
                    Success
                  </Tag>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>OCS 2</span>
                <div className={styles.date_link}>
                  <Tag color="success" icon={<CheckCircleOutlined />}>
                    Success
                  </Tag>
                  <LinkOutlined />
                </div>
              </div>
              <div className={styles.content}>
                <span className={styles.label}>Santander</span>
                <div className={styles.date_link}>
                  <Tag color="success" icon={<CheckCircleOutlined />}>
                    Success
                  </Tag>
                  <LinkOutlined />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
