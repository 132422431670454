import http, { requestConfig } from "../../http-common";
import { UpdateRoomRequest } from "../../models/rooms/UpdateRoomRequest";

const RESOURCE_NAME = "rooms";

const update = (model: UpdateRoomRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const create = (model: UpdateRoomRequest) => {
  return http.post(`${RESOURCE_NAME}`, model, requestConfig());
};
const remove = (id: number) => {
  return http.delete(`${RESOURCE_NAME}/${id}`, requestConfig());
};

const RoomsService = {
  update,
  create,
  remove,
};
export default RoomsService;
