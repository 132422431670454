import http, { requestConfig } from "../../http-common";

const RESOURCE_NAME = "scans";

const get = (id: number | string) => {
  return http.get(`${RESOURCE_NAME}/${id}`, requestConfig());
};

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const ScansService = {
  get,
  getAll,
};
export default ScansService;
