import PageHeader from "../../../shared/header/PageHeader";
import React from "react";
import { useEffect, useState } from "react";
import styles from "../regions/Regions.module.css";
import { UpsertRegionRequest } from "../../../../../models/regions/UpsertRegionRequest";
import RegionsService from "../../../../../services/regions/RegionsService";

import {
  PlusOutlined,
  ApartmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Drawer, message, Popconfirm, Space, Table } from "antd";

import RegionsEditForm from "./components/RegionsEditForm";
import { ROUTES } from "../../../../../config/constants";
import { useNavigate, useParams } from "react-router-dom";

const Regions = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [item, setItem] = useState<UpsertRegionRequest>();
  const [regions, setRegions] = useState<any>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.adminRegions.route,
    section: "Configuration",
    subcategory: "Location",
    name: ROUTES.general.adminRegions.name,
  };

  useEffect(() => {
    resetItem();
  }, []);

  const resetItem = () => {
    const model: UpsertRegionRequest = {
      tenantId: 1,
      name: "",
      productivityRate: 0,
      currencyId: 1,
      measurementSystemId: 1,
      overheadTemplate: {
        tenantId: 1,
        name: "",
        cleaningMaterialsPercentage: 0,
        holidayBonusPercentage: 0,
        insurancePercentage: 0,
        profitsPercentage: 0,
        employerNIPercentage: 0,
        hourlyPercentage: 0,
        managementPercentage: 0,
        totalPercentage: 0,
        equipmentPercentage: 0,
        hourlyWagePercentage: 0,
        pensionPercentage: 0,
      },
      standardCleaningTemplate: {
        tenantId: 1,
        name: "",
        setupTimeSeconds: 0,
        finishTimeSeconds: 0,
        walkingSpeedPerSecond: 0,
      },
      eveningCleaningTemplate: {
        tenantId: 1,
        name: "",
        totalTimeSeconds: 0,
        secondsPerCleaner: 0,
        hourlyWage: 0,
        cleanersPerSupervisor: 0,
        auditPassRatePercentage: 0,
        secondsPerSupervisor: 0,
        supervisorHourlyWage: 0,
      },
      periodicCleaningTemplate: {
        tenantId: 1,
        name: "",
        profitMarginPercentage: 0,
        hourlyWage: 0,
      },
      roboticVacuumingTemplate: {
        tenantId: 1,
        name: "",
      },
    };

    setItem({ ...model });
  };

  useEffect(() => {
    if (isTableLoading) {
      RegionsService.getAll().then((response: any) => {
        const data = response.data;
        setRegions(data);
        setIsTableLoading(false);
      });
    }
  }, [isTableLoading]);

  useEffect(() => {
    if (regions && regions.length > 0 && id) {
      const region = regions.find((x: any) => x.id === parseInt(id));
      if (region) {
        setShowAddNewForm(true);
        setItem(region);
      }
    } else {
      setShowAddNewForm(false);
    }
  }, [regions, id]);

  const handleAddNewRegion = () => {
    setShowAddNewForm(true);
  };

  const handleCancelAdd = () => {
    resetItem();
    setShowAddNewForm(false);
    setLoadingButton(false);
    navigate(ROUTES.general.adminRegions.route);
  };

  const handleEditRegion = (record: any) => {
    setShowAddNewForm(true);
    setItem(record);
    navigate(`${ROUTES.general.adminRegions.route}/${record.id}`);
  };

  const onSaveRegion = () => {
    setLoadingButton(true);

    item!.eveningCleaningTemplate.secondsPerSupervisor =
      item!.eveningCleaningTemplate.secondsPerSupervisor * 3600;
    item!.eveningCleaningTemplate.secondsPerCleaner =
      item!.eveningCleaningTemplate.secondsPerCleaner * 3600;

    if (item?.id) {
      RegionsService.update(item!).then((response: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        setItem(item);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminRegions.route);
      });
    } else {
      RegionsService.create(item!).then((respone: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminRegions.route);
      });
    }
  };

  const handleDeleteRegion = (id: number) => {
    RegionsService.remove(id).then((response: any) => {
      resetItem();
      setIsTableLoading(true);
    });
  };

  const onDoubleClickRow = (record: any) => {
    setItem(record);
    navigate(`${ROUTES.general.adminRegions.route}/${record.id}`);
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };

  const headerButton = (
    <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNewRegion}>
      Add
    </Button>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Productivity Rate",
      dataIndex: "productivityRate",
      key: "productivityRate",
      elipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditRegion(record)}
            style={{ color: "#313AB8" }}
          />
          <Popconfirm
            title="Are you sure you want to delete this region?"
            placement="topRight"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteRegion(record?.id!)}
            onCancel={() => cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{ color: "#313AB8" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Regions"}
        icon={<ApartmentOutlined />}
        mainAction={headerButton}
        setMainActionDisabled={false}
        additionalBreadcrumbs={
          !showAddNewForm && !item?.id ? null : [item?.name ?? ""]
        }
        route={route}
      />
      <div className={styles.bodyContent}>
        <Table
          size="middle"
          dataSource={regions}
          columns={columns}
          loading={isTableLoading}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                setShowAddNewForm(true);
                onDoubleClickRow(record);
              },
            };
          }}
        />
        <Drawer
          title={item?.id ? "Edit" : "Add"}
          placement="right"
          open={showAddNewForm}
          width="80%"
          closable={false}
          extra={
            <Space size="middle">
              <Button
                type="default"
                // onClick={handleAddNewRegion}
              >
                Audit
              </Button>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleCancelAdd}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={onSaveRegion}
                loading={loadingButton}
              >
                Save
              </Button>
            </Space>
          }
        >
          <RegionsEditForm item={item} setItem={setItem} />
        </Drawer>
      </div>
    </div>
  );
};

export default Regions;
