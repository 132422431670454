import { Col, Form, Input, InputNumber, Row, Select } from "antd";

import styles from "../Buildings.module.css";

import { useEffect, useState } from "react";
import { Typography } from "antd";
import BuildingTypesService from "../../../../../services/buildingtypes/BuildingTypesService";
import RegionsService from "../../../../../services/regions/RegionsService";
import CountriesService from "../../../../../services/countries/CountriesService";
import { useForm } from "antd/es/form/Form";
import { IUpdateBuildingRequest } from "../../../../../models/buildings/UpdateBuildingRequest";

const { Title } = Typography;
const { Option } = Select;

const BuildingDataTab = ({
  item,
  setItem,
}: {
  item: IUpdateBuildingRequest | undefined;
  setItem: any;
}) => {
  const [drawerForm] = useForm();
  const [selectedRegion, setSelectedRegion] = useState<any>("");
  const [selectedScan, setSelectedScan] = useState<any>("");
  const [buildingTypes, setBuildingTypes] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [filteredCountries, setFilteredCountries] = useState<any>([]);

  const onNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, name: inputValue });
  };

  const handleTypeChange = (value: number) => {
    setItem({ ...item, buildingTypeId: value });
  };

  const onAddressInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, address: inputValue });
  };

  const handleCountryChange = (value: number) => {
    setItem({ ...item, countryId: value });
  };

  const handleRegionChange = (value: number) => {
    setItem({ ...item, regionId: value });
    setFilteredCountries(countries.filter((x: any) => x.regionId === value));
  };

  const handleScanChange = (value: string) => {
    setSelectedScan(value);
  };

  const onSetupTimeChange = (value: number | null) => {
    setItem({
      ...item,
      standardCleaningTemplate: {
        ...item?.standardCleaningTemplate,
        setupTimeSeconds: value,
      },
    });
  };

  const onInputFinishTime = (value: number | null) => {
    setItem({
      ...item,
      standardCleaningTemplate: {
        ...item?.standardCleaningTemplate,
        finishTimeSeconds: value,
      },
    });
  };

  const onWalkingSpeedChange = (value: number | null) => {
    setItem({
      ...item,
      standardCleaningTemplate: {
        ...item?.standardCleaningTemplate,
        walkingSpeedPerSecond: value,
      },
    });
  };

  const onSecondsperCleanerChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        secondsPerCleaner: value,
      },
    });
  };

  const onCleanerperSupervisorChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        cleanersPerSupervisor: value,
      },
    });
  };

  const onAuditPassRateChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        auditPassRatePercentage: value,
      },
    });
  };

  const onSupervisorWorkingHoursChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        secondsPerSupervisor: value,
      },
    });
  };

  const onHourlyWageChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        hourlyWage: value,
      },
    });
  };

  const onSupervisorHourlyWageChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        supervisorHourlyWage: value,
      },
    });
  };

  const onHourlyWagePeriodic = (value: number | null) => {
    setItem({
      ...item,
      periodicCleaningTemplate: {
        ...item?.periodicCleaningTemplate,
        hourlyWage: value,
      },
    });
  };

  const onProfitMarginChange = (value: number | null) => {
    setItem({
      ...item,
      periodicCleaningTemplate: {
        ...item?.periodicCleaningTemplate,
        profitMarginPercentage: value,
      },
    });
  };

  const onCleaningMaterialsChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        cleaningMaterialsPercentage: value,
      },
    });
  };
  const onEmployerNiChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        employerNIPercentage: value,
      },
    });
  };

  const onEquipementChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        equipmentPercentage: value,
      },
    });
  };
  const onHolidayBonusChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        holidayBonusPercentage: value,
      },
    });
  };
  const onHourlyChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        hourlyPercentage: value,
      },
    });
  };
  const onHourlyWagePercentageChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        hourlyWagePercentage: value,
      },
    });
  };
  const onInsuranceChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        insurancePercentage: value,
      },
    });
  };
  const onManagementFeeChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        managementPercentage: value,
      },
    });
  };
  const onPensionChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        pensionPercentage: value,
      },
    });
  };
  const onProfitsChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        profitsPercentage: value,
      },
    });
  };
  const onTotalChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        totalPercentage: value,
      },
    });
  };

  useEffect(() => {
    BuildingTypesService.getAll().then((response: any) => {
      const data = response.data;
      setBuildingTypes(data);
    });
    RegionsService.getAll().then((response: any) => {
      const data = response.data;
      setRegions(data);
    });
    CountriesService.getAll().then((response: any) => {
      const data = response.data;
      setCountries(data);
      setFilteredCountries(data);
    });
  }, []);

  useEffect(() => {
    if (item && countries) {
      const selectedRegionId = countries.find(
        (x: any) => x.id === item?.countryId
      )?.regionId;
      setSelectedRegion(selectedRegionId);
      drawerForm.setFieldsValue({
        region: selectedRegionId,
      });
    }
  }, [item, countries]);

  useEffect(() => {
    if (item) {
      drawerForm.setFieldsValue({
        name: item?.name,
        address: item?.name,
        type: item?.buildingTypeId,
        country: item?.countryId,

        setupTime: item?.standardCleaningTemplate?.setupTimeSeconds,
        finishTime: item?.standardCleaningTemplate?.finishTimeSeconds,
        walkingSpeed: item?.standardCleaningTemplate?.walkingSpeedPerSecond,
        cleanerWorkingHours:
          item?.eveningCleaningTemplate?.secondsPerSupervisor,
        superWorkingHours: item?.eveningCleaningTemplate?.secondsPerSupervisor,
        operativeWorkingHr: item?.eveningCleaningTemplate?.secondsPerCleaner,
        operativesPerSup: item?.eveningCleaningTemplate?.cleanersPerSupervisor,
        auditPassRate: item?.eveningCleaningTemplate?.auditPassRatePercentage,
        superHourlyWage: item?.eveningCleaningTemplate?.supervisorHourlyWage,
        hourlyWagePeriodic: item?.periodicCleaningTemplate?.hourlyWage,
        profitMargin: item?.periodicCleaningTemplate?.profitMarginPercentage,
        cleaningMaterials: item?.overheadTemplate?.cleaningMaterialsPercentage,
        employerNI: item?.overheadTemplate?.employerNIPercentage,
        equipement: item?.overheadTemplate?.equipmentPercentage,
        holidaybonus: item?.overheadTemplate?.holidayBonusPercentage,
        hourly: item?.overheadTemplate?.hourlyPercentage,
        hourlyWagPercentage: item?.overheadTemplate?.hourlyWagePercentage,
        insurance: item?.overheadTemplate?.insurancePercentage,
        managementFee: item?.overheadTemplate?.managementPercentage,
        pension: item?.overheadTemplate?.pensionPercentage,
        profits: item?.overheadTemplate?.profitsPercentage,
        total: item?.overheadTemplate?.totalPercentage,

        currency: item?.currencyId,
        measurementsystem: item?.measurementSystemId,
      });
    }
  }, [item, drawerForm]);

  return (
    <div className={styles.container}>
      <Form form={drawerForm} className={styles.formWrap}>
        <div className={styles.formWrap}>
          <>
            <div className={styles.formSection}>
              <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
                General
              </Title>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Name"
                    name="name"
                  >
                    <Input
                      value={item?.name ?? ""}
                      onInput={onNameInputChange}
                      placeholder="name"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Type"
                    name="type"
                  >
                    <Select
                      placeholder="Select Type"
                      allowClear
                      value={item?.buildingTypeId}
                      onChange={handleTypeChange}
                      options={buildingTypes.map((x: any) => ({
                        label: x.name,
                        value: x.id,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Region"
                    name="region"
                  >
                    <Select
                      placeholder="Select Region"
                      allowClear
                      showSearch
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      options={regions
                        .map((x: any) => ({
                          value: x.id,
                          label: x.name,
                        }))
                        .sort(function (a: any, b: any) {
                          if (a.label.toLowerCase() < b.label.toLowerCase())
                            return -1;
                          if (a.label.toLowerCase() > b.label.toLowerCase())
                            return 1;
                          return 0;
                        })}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Address"
                    name="address"
                  >
                    <Input
                      value={item?.address ?? ""}
                      onInput={onAddressInputChange}
                      placeholder="address"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Country"
                    name="country"
                  >
                    <Select
                      placeholder="Select Country"
                      disabled={!selectedRegion}
                      allowClear
                      showSearch
                      value={item?.countryId}
                      onChange={handleCountryChange}
                      options={filteredCountries
                        .map((x: any) => ({
                          label: x.name,
                          value: x.id,
                        }))
                        .sort(function (a: any, b: any) {
                          if (a.label.toLowerCase() < b.label.toLowerCase())
                            return -1;
                          if (a.label.toLowerCase() > b.label.toLowerCase())
                            return 1;
                          return 0;
                        })}
                      filterOption={(inputValue: any, option: any) =>
                        option!.label
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                {/* <Col span={8}>
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ offset: 2 }}
                  label="Scan"
                  name="scan"
                >
                  <Select
                    placeholder="select scan"
                    allowClear
                    value={selectedScan}
                    onChange={handleScanChange}
                  >
                    <Option value="UK">UK</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              </Row>
            </div>
            <div className={styles.formSection}>
              <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
                Cleaning
              </Title>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Setup Time (s)"
                    name="setupTime"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={item?.standardCleaningTemplate?.setupTimeSeconds}
                      onChange={onSetupTimeChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Finish Time (s)"
                    name="finishTime"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={item?.standardCleaningTemplate?.finishTimeSeconds}
                      onChange={onInputFinishTime}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Walking Speed"
                    name="walkingSpeed"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={
                        item?.standardCleaningTemplate?.walkingSpeedPerSecond
                      }
                      onChange={onWalkingSpeedChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.formSection}>
              <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
                Evening Cleaning
              </Title>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                {/* <Col span={8}>
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ offset: 2 }}
                  label="Total Time (s)"
                  name="totalTime"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    max={100}
                    value={inputTotalTime}
                    onChange={onTotalTimeChange}
                  />
                </Form.Item>
              </Col> */}
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Cleaner Working Hours"
                    name="cleanerWorkingHours"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={item?.eveningCleaningTemplate?.secondsPerCleaner}
                      onChange={onSecondsperCleanerChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Cleaners per Supervisor (s)"
                    name="cleanersPerSuper"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={
                        item?.eveningCleaningTemplate?.cleanersPerSupervisor
                      }
                      onChange={onCleanerperSupervisorChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Audit Pass Rate"
                    name="auditPassRate"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={
                        item?.eveningCleaningTemplate?.auditPassRatePercentage
                      }
                      onChange={onAuditPassRateChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Supervisor Working Hours"
                    name="superWorkingHours"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={
                        item?.eveningCleaningTemplate?.secondsPerSupervisor
                      }
                      onChange={onSupervisorWorkingHoursChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Hourly Wage"
                    name="hourlyWage"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={item?.eveningCleaningTemplate?.hourlyWage}
                      onChange={onHourlyWageChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Supervisor Hourly Wage"
                    name="superHourlyWage"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={
                        item?.eveningCleaningTemplate?.supervisorHourlyWage
                      }
                      onChange={onSupervisorHourlyWageChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.formSection}>
              <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
                Periodic Cleaning
              </Title>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Hourly Wage"
                    name="hourlyWagePeriodic"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      value={item?.periodicCleaningTemplate?.hourlyWage}
                      onChange={onHourlyWagePeriodic}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Profit margin (%)"
                    name="profitMargin"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={
                        item?.periodicCleaningTemplate?.profitMarginPercentage
                      }
                      onChange={onProfitMarginChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.formSection}>
              <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
                Overheads
              </Title>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Cleaning materials (%)"
                    name="cleaningMaterials"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={
                        item?.overheadTemplate?.cleaningMaterialsPercentage
                      }
                      onChange={onCleaningMaterialsChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Employer NI (%)"
                    name="employerNI"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.employerNIPercentage}
                      onChange={onEmployerNiChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Equipement (%)"
                    name="equipement"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.equipmentPercentage}
                      onChange={onEquipementChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Holiday Bonus (%)"
                    name="holidaybonus"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.holidayBonusPercentage}
                      onChange={onHolidayBonusChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Hourly (%)"
                    name="hourly"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.hourlyPercentage}
                      onChange={onHourlyChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Hourly Wage (%)"
                    name="hourlyWagPercentage"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.hourlyWagePercentage}
                      onChange={onHourlyWagePercentageChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Insurance (%)"
                    name="insurance"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.insurancePercentage}
                      onChange={onInsuranceChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Management fee (%)"
                    name="managementFee"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.managementPercentage}
                      onChange={onManagementFeeChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Pension (%)"
                    name="pension"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.pensionPercentage}
                      onChange={onPensionChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Profits (%)"
                    name="profits"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.profitsPercentage}
                      onChange={onProfitsChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ offset: 2 }}
                    label="Total (%)"
                    name="total"
                    initialValue={0}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={item?.overheadTemplate?.totalPercentage}
                      onChange={onTotalChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </>
        </div>
      </Form>
    </div>
  );
};

export default BuildingDataTab;
