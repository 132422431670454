import {Button, Checkbox, Form, Input, Layout} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import {Outlet, useParams} from "react-router-dom";

import BenchmarkSidebarLayout from "./layout/sidebar/SidebarLayout";
import BenchmarkNavbarLayout from "./layout/navbar/NavbarLayout";
import Head from "./layout/Head";
import styles from "../../modules/auth/Login.module.css";
import React, {useEffect, useState} from "react";
import {authBenchmark} from "../../store/auth";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {CloseOutlined, LockOutlined, LoginOutlined, UserOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";

const Benchmark = () => {
    const dispatch = useAppDispatch()<any>;
    
    const [password, setPassword] = useState("");
    const [isVerified, setIsVerified] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    
    const { buildingId } = useParams();
    
    const { auth, pending } = useAppSelector((state) => state.auth);

    const checkPw = () => {
        if (password) {
            setIsLoading(true);
            dispatch(authBenchmark({ buildingId: buildingId, password: password }));    
        }
    };

    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        const inputValue = event.target.value;
        setPassword(inputValue);
    };

    // useEffect(() => {
    //     if (buildingId == "214") {
    //         setIsVerified(false)
    //     }
    // }, [buildingId]);
    //
    // useEffect(() => {
    //     if (auth?.data) {
    //         setIsVerified(true)
    //     }
    //     setIsLoading(false);
    // }, [auth]);
    
    return (
      <>
          {/*{auth && isVerified ? (*/}
          {isVerified ? (
              <Layout id="benchmark-layout" style={{ minHeight: "100vh" }}>
                  <BenchmarkSidebarLayout />

                  <Layout
                      className="main-layout site-drawer-render-in-current-wrapper"
                      style={{ height: "100vh" }}
                  >
                      <BenchmarkNavbarLayout />
                      <Header
                          style={{
                              backgroundColor: "unset",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              height: "64px",
                              padding: "0",
                          }}
                      >
                          <Head />
                      </Header>
                      <Content className={styles.mainContainer}>
                          <Outlet></Outlet>
                      </Content>
                  </Layout>
              </Layout>
          ) : (
              <div className={styles.loginModal}>
                  <div className={styles.modalHeader}>
                      <Title style={{ margin: "0px" }} level={4}>
                          Welcome to ScanQuo Benchmark!
                      </Title>
                  </div>
                  <div className={styles.formContainer}>
                      <Form
                          name="normal_login"
                          className={styles.loginInputs}
                          initialValues={{ remember: true }}
                      >
                          <label>
                              In order to access this benchmark, please provide a password that has been shared with you.
                          </label>
                          <Form.Item
                              name="password"
                              rules={[
                                  { required: true, message: "Please input your Password!" },
                              ]}
                          >
                              <Input
                                  prefix={<LockOutlined className="site-form-item-icon" />}
                                  type="password"
                                  placeholder="Password"
                                  value={password}
                                  onInput={onPasswordChange}
                              />
                          </Form.Item>
                          <Form.Item>
                              <Button
                                  block
                                  type="primary"
                                  htmlType="submit"
                                  className={styles.coloredButton}
                                  icon={<LoginOutlined />}
                                  onClick={checkPw}
                                  loading={isLoading}
                              >
                                  Sign In
                              </Button>
                          </Form.Item>
                      </Form>
                  </div>
              </div>
          )}
      </>
  );
};

export default Benchmark;
