import PageHeader from "../../shared/header/PageHeader";
import styles from "../import/Import.module.css";

import { ImportOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Checkbox,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  Upload,
  notification,
} from "antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { useEffect, useState } from "react";
import MatterportSpacesService from "../../../../services/import/MatterportSpacesService";
import BuildingTypesService from "../../../../services/buildingtypes/BuildingTypesService";
import RegionsService from "../../../../services/regions/RegionsService";
import CountriesService from "../../../../services/countries/CountriesService";
import { CreateBuildingRequest } from "../../../../models/buildings/CreateBuildingRequest";
import { useForm } from "antd/es/form/Form";
import BuildingsService from "../../../../services/buildings/BuildingsService";
import { useNavigate } from "react-router-dom";
import VerticalsService from "../../../../services/verticals/VerticalsService";
import { ROUTES } from "../../../../config/constants";
import { NotificationPlacement } from "antd/es/notification/interface";
import {AxiosError} from "axios";

const Import = () => {
  const [drawerForm] = useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<any>(undefined);
  const [selectedVertical, setSelectedVertical] = useState<any>(undefined);
  const [radioChange, setRadioChange] = useState<any>("new");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [spaces, setSpaces] = useState<any>([]);
  const [buildingTypes, setBuildingTypes] = useState<any>([]);
  const [verticals, setVerticals] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [filteredCountries, setFilteredCountries] = useState<any>([]);
  const [filteredTypes, setFilteredTypes] = useState<any>([]);
  const [request, setRequest] = useState<CreateBuildingRequest>();
  //Adding additional forms
  const [importSourceForm, setImportSourceForm] = useState([{ source: "" }]);
  const [floors, setFloors] = useState<any>([]);
  const [floorsLoading, setFloorsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.import.route,
    section: "Scan",
    name: ROUTES.general.import.name,
  };

  const sourceTypes = [
    {
      label: "Matterport Account",
      value: 0,
    },
    {
      label: "Matterport URL",
      value: 5,
    },
    {
      label: "Pupil", // TODO Temp added on behalf of SQ
      value: 3,
    },
    {
      label: "Insta360", // TODO Temp added on behalf of SQ
      value: 4,
    },
    // {
    //   label: "Video",
    //   value: 1,
    // },
    // {
    //   label: "Excel file",
    //   value: 2,
    // },
  ];

  useEffect(() => {
    resetItem();
  }, []);

  const resetItem = () => {
    const model: CreateBuildingRequest = {
      name: "",
      address: "",
      typeId: 1,
      countryId: 1,
      scans: [
        {
          type: -1,
          name: "",
          operator: undefined,
          matterportSpaceId: undefined,
          matterportSpacePassword: undefined,
          demo: false,
          floors: [],
        },
      ],
    };
    setRequest({ ...model });
  };

  useEffect(() => {
    MatterportSpacesService.getAll().then((response: any) => {
      const data = response.data;
      setSpaces(data);
    });
    BuildingTypesService.getAll().then((response: any) => {
      const data = response.data;
      setBuildingTypes(data);
    });
    RegionsService.getAll().then((response: any) => {
      const data = response.data;
      setRegions(data);
    });
    CountriesService.getAll().then((response: any) => {
      const data = response.data;
      setCountries(data);
      setFilteredCountries(data);
    });
    VerticalsService.getAll().then((response: any) => {
      const data = response.data;
      setVerticals(data);
      setFilteredTypes(data);
    });
  }, []);

  useEffect(() => {
    if (request) {
      drawerForm.setFieldsValue({
        name: request.name,
        address: request.address,
      });
    }
  }, [request, drawerForm]);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setRadioChange(e.target.value);
  };

  const onNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setRequest({ ...request, name: inputValue });
  };

  const handleSelectedType = (value: number | undefined) => {
    setRequest({ ...request, typeId: value });
  };

  const handleSelectedRegion = (value: number) => {
    setSelectedRegion(value);
    setFilteredCountries(countries.filter((x: any) => x.regionId === value));

    handleSelectedCountry(undefined);
    drawerForm.setFieldsValue({
      country: request?.countryId,
    });
  };

  const handleSelectedVertical = (value: number) => {
    setSelectedVertical(value);
    setFilteredTypes(buildingTypes.filter((x: any) => x.id === value));

    handleSelectedType(undefined);
    drawerForm.setFieldsValue({
      buildingTypes: request?.typeId,
    });
  };

  const onAddressInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setRequest({ ...request, address: inputValue });
  };

  const handleSelectedCountry = (value: number | undefined) => {
    setRequest({ ...request, countryId: value });
  };

  const handleSelectedSource = (value: number, index: number) => {
    let scans = request?.scans!;
    scans[index].type = value;
    setRequest({ ...request, scans: scans });
    // setSelectedSource(value);
  };

  const onScanInputChange = (value: string, index: number) => {
    let scans = request?.scans!;
    scans[index].matterportSpaceId = value;
    setRequest({ ...request, scans: scans });
  };

  const onPasswordInputChange = (value: string, index: number) => {
    let scans = request?.scans!;
    scans[index].matterportSpacePassword = value;
    setRequest({ ...request, scans: scans });
  };

  const onSourceNameInputChange = (value: string, index: number) => {
    let scans = request?.scans!;
    scans[index].name = value;
    setRequest({ ...request, scans: scans });
  };

  const onOperatorInputChange = (value: string, index: number) => {
    let scans = request?.scans!;
    scans[index].operator = value;
    setRequest({ ...request, scans: scans });
  };

  const onFloorNameChange = (
    value: string,
    scanIndex: number,
    floorIndex: number
  ) => {
    let scans = request?.scans!;
    if (!scans[scanIndex].floors || !scans[scanIndex].floors[floorIndex]) {
      scans[scanIndex].floors[floorIndex] = {
        name: value,
        sequence: floorIndex,
      };
    } else {
      scans[scanIndex].floors[floorIndex].name = value;
    }

    setRequest({ ...request, scans: scans });
  };

  const onDescriptionInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setDescriptionInput(inputValue);
  };

  const handleSelectedScanChange = (value: any, index: number) => {
    let scans = request?.scans!;
    scans[index].matterportSpaceId = value;
    setRequest({ ...request, scans: scans });
  };

  const onDemoCheckboxChange = (e: CheckboxChangeEvent, index: number) => {
    let scans = request?.scans!;
    scans[index].demo = e.target.checked;
    setRequest({ ...request, scans: scans });
  };

  let addFormFields = () => {
    setImportSourceForm([...importSourceForm, { source: "" }]);
  };

  let removeAdditionalForm = (i: any) => {
    let newFormValues = [...importSourceForm];
    newFormValues.splice(i, 1);
    setImportSourceForm(newFormValues);
  };

  const onImportClick = () => {
    if (!request || !request.scans) {
      return;
    }

    setLoadingButton(true);
    for (let i = 0; i < request!.scans!.length; i++) {
      if (request.scans[i].type == 5) {
        request.scans[i].matterportSpaceId = request.scans[i].matterportSpaceId?.split("?m=")[1];
      }
    }
    
    BuildingsService.create(request!).then((response: any) => {
      setRequest(request);
      resetItem();
      setLoadingButton(false);

      notification.success({
        message: `Success`,
        description: `Scan successfully imported.`,
        placement: "bottomRight",
      });

      navigate("/");
    }).catch((error: AxiosError) => {
      // @ts-ignore
      if (error.response && error.response.status < 500 && error.response.data['errors']) {
        // @ts-ignore
        for (const field in error.response.data['errors']) {
          notification.error({
            message: `Error`,
            // @ts-ignore
            description: error.response.data['errors'][field],
            placement: "bottomRight",
          });
        }
      } else {
        notification.error({
          message: `Error`,
          description: `Failed to import Scan.`,
          placement: "bottomRight",
        });
      }

      setLoadingButton(false);
    });
  };

  const headerButton = (
    <Button
      loading={loadingButton}
      icon={<ImportOutlined />}
      onClick={onImportClick}
    >
      Import
    </Button>
  );

  return (
    <div>
      <PageHeader
        title={"Import"}
        icon={<ImportOutlined />}
        // mainAction={headerButton}
        mainAction={undefined}
        setMainActionDisabled={false}
        route={route}
      />
      <div className={styles.bodyContent}>
        <Card title="Building Data">
          <div className={styles.formSection}>
            {/* <Radio.Group value={radioChange} onChange={handleRadioChange}>
              <Radio value="new">New</Radio>
              <Radio value="existing">Existing</Radio>
            </Radio.Group> */}
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              // style={{ paddingLeft: "20px" }}
            >
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                xs={{ span: 14 }}
              >
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 8 },
                    lg: { span: 6 },
                  }}
                  wrapperCol={{ offset: 2 }}
                  label="Name"
                  name="name"
                >
                  <Input
                    value={request?.name}
                    onInput={onNameInputChange}
                    placeholder="Name"
                  />
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                xs={{ span: 14 }}
              >
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 8 },
                    lg: { span: 6 },
                  }}
                  wrapperCol={{ offset: 2 }}
                  label="Address"
                  name="address"
                >
                  <Input
                    value={request?.address}
                    onInput={onAddressInputChange}
                    placeholder="Address"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              // style={{ paddingLeft: "20px" }}
            >
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                xs={{ span: 14 }}
              >
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 8 },
                    lg: { span: 6 },
                  }}
                  wrapperCol={{ offset: 2 }}
                  label="Region"
                  name="region"
                >
                  <Select
                    placeholder="Select Region"
                    allowClear
                    showSearch={true}
                    showArrow={true}
                    value={selectedRegion}
                    onChange={handleSelectedRegion}
                    options={regions
                      .map((x: any) => ({
                        value: x.id,
                        label: x.name,
                      }))
                      .sort(function (a: any, b: any) {
                        if (a.label.toLowerCase() < b.label.toLowerCase())
                          return -1;
                        if (a.label.toLowerCase() > b.label.toLowerCase())
                          return 1;
                        return 0;
                      })}
                    filterOption={(inputValue: any, option: any) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                xs={{ span: 14 }}
              >
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 8 },
                    lg: { span: 6 },
                  }}
                  wrapperCol={{ offset: 2 }}
                  label="Vertical"
                  name="vertical"
                >
                  <Select
                    placeholder="Select Vertical"
                    allowClear
                    showSearch={true}
                    showArrow={true}
                    value={selectedVertical}
                    onChange={handleSelectedVertical}
                    options={verticals
                      .map((x: any) => ({
                        value: x.id,
                        label: x.name,
                      }))
                      .sort(function (a: any, b: any) {
                        if (a.label.toLowerCase() < b.label.toLowerCase())
                          return -1;
                        if (a.label.toLowerCase() > b.label.toLowerCase())
                          return 1;
                        return 0;
                      })}
                    filterOption={(inputValue: any, option: any) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              // style={{ paddingLeft: "20px" }}
            >
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                xs={{ span: 14 }}
              >
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 8 },
                    lg: { span: 6 },
                  }}
                  wrapperCol={{
                    offset: 2,
                    xs: { span: 10 },
                    sm: { span: 12 },
                    md: { span: 14 },
                    lg: { span: 16 },
                  }}
                  label="Country"
                  name="country"
                >
                  <Select
                    placeholder="Select Country"
                    disabled={!selectedRegion}
                    allowClear
                    showSearch
                    dropdownMatchSelectWidth={false}
                    value={request?.countryId}
                    onChange={handleSelectedCountry}
                    options={filteredCountries
                      .map((x: any) => ({
                        label: x.name,
                        value: x.id,
                      }))
                      .sort(function (a: any, b: any) {
                        if (a.label.toLowerCase() < b.label.toLowerCase())
                          return -1;
                        if (a.label.toLowerCase() > b.label.toLowerCase())
                          return 1;
                        return 0;
                      })}
                    filterOption={(inputValue: any, option: any) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                xs={{ span: 14 }}
              >
                <Form.Item
                  style={{ marginBottom: "0" }}
                  labelAlign="left"
                  labelCol={{
                    xs: { span: 12 },
                    sm: { span: 10 },
                    md: { span: 8 },
                    lg: { span: 6 },
                  }}
                  wrapperCol={{ offset: 2 }}
                  label="Type"
                  name="Type"
                >
                  <Select
                    placeholder="Select Type"
                    allowClear
                    disabled={!selectedVertical}
                    value={request?.typeId}
                    onChange={handleSelectedType}
                    options={buildingTypes
                      .filter((x: any) => x.verticalId == selectedVertical)
                      .map((x: any) => ({
                        label: x.name,
                        value: x.id,
                      }))}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
        {request?.scans?.map((scan, index) => (
          <Card title="Import source data">
            <Form className={styles.formSection} key={index}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              >
                <Col
                  lg={{ span: 8 }}
                  md={{ span: 10 }}
                  sm={{ span: 12 }}
                  xs={{ span: 14 }}
                >
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 12 },
                      sm: { span: 10 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Source"
                    name="source"
                  >
                    <Select
                      placeholder="Select Source"
                      allowClear
                      value={scan.type}
                      onChange={(value: any) =>
                        handleSelectedSource(value, index)
                      }
                      options={sourceTypes}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              {scan.type === 5 && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 14 }}
                  >
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 12 },
                        sm: { span: 10 },
                        md: { span: 8 },
                        lg: { span: 6 },
                      }}
                      wrapperCol={{ offset: 2 }}
                      label="Scan"
                      name="scan"
                    >
                      <Input
                        value={scan.matterportSpaceId}
                        onInput={(event: any) =>
                          onScanInputChange(event.target.value, index)
                        }
                        placeholder="Scan"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 14 }}
                  >
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 12 },
                        sm: { span: 10 },
                        md: { span: 8 },
                        lg: { span: 6 },
                      }}
                      wrapperCol={{ offset: 2 }}
                      label="Password"
                      name="password"
                    >
                      <Input
                        value={scan.matterportSpacePassword}
                        onInput={(event: any) =>
                          onPasswordInputChange(event.target.value, index)
                        }
                        placeholder="Matterport space password"
                      />
                      {floorsLoading && <Spin></Spin>}
                    </Form.Item>
                  </Col>
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 14 }}
                  >
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 12 },
                        sm: { span: 10 },
                        md: { span: 8 },
                        lg: { span: 6 },
                      }}
                      wrapperCol={{ offset: 2 }}
                      label=""
                      name="demo"
                    >
                      <Checkbox
                          defaultChecked={false}
                          onChange={(event) => onDemoCheckboxChange(event, index)}>
                        Demo
                      </Checkbox>
                      {floorsLoading && <Spin></Spin>}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {scan.type === 0 && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 14 }}
                  >
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 12 },
                        sm: { span: 10 },
                        md: { span: 8 },
                        lg: { span: 6 },
                      }}
                      wrapperCol={{ offset: 2 }}
                      label="Scan"
                      name="scan"
                    >
                      <Select
                        placeholder="Select Scan"
                        allowClear
                        value={scan.matterportSpaceId}
                        onChange={(value: any) =>
                          handleSelectedScanChange(value, index)
                        }
                        options={spaces.map((x: any) => ({
                          label: x.name,
                          value: x.id,
                        }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col
                      lg={{ span: 8 }}
                      md={{ span: 10 }}
                      sm={{ span: 12 }}
                      xs={{ span: 14 }}
                  >
                    <Form.Item
                        style={{ marginBottom: "0" }}
                        labelAlign="left"
                        labelCol={{
                          xs: { span: 12 },
                          sm: { span: 10 },
                          md: { span: 8 },
                          lg: { span: 6 },
                        }}
                        wrapperCol={{ offset: 2 }}
                        label=""
                        name="demo"
                    >
                      <Checkbox
                          defaultChecked={false}
                          onChange={(event) => onDemoCheckboxChange(event, index)}>
                        Demo
                      </Checkbox>
                      {floorsLoading && <Spin></Spin>}
                    </Form.Item>
                  </Col>
                  {floorsLoading && <Spin></Spin>}
                </Row>
              )}
              {scan.type === 1 && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 14 }}
                  >
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 12 },
                        sm: { span: 10 },
                        md: { span: 8 },
                        lg: { span: 6 },
                      }}
                      wrapperCol={{ offset: 2 }}
                      label="Video File"
                      name="videoFile"
                    >
                      <Upload>
                        <Button icon={<UploadOutlined />}>
                          Click to upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col
                  lg={{ span: 8 }}
                  md={{ span: 10 }}
                  sm={{ span: 12 }}
                  xs={{ span: 14 }}
                >
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 12 },
                      sm: { span: 10 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Name"
                    name="name"
                  >
                    <Input
                      value={scan.name}
                      onInput={(event: any) =>
                        onSourceNameInputChange(event.target.value, index)
                      }
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={{ span: 8 }}
                  md={{ span: 10 }}
                  sm={{ span: 12 }}
                  xs={{ span: 14 }}
                >
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 12 },
                      sm: { span: 10 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Operator"
                    name="operator"
                  >
                    <Input
                      value={scan.operator}
                      onInput={(event: any) =>
                        onOperatorInputChange(event.target.value, index)
                      }
                      placeholder="Operator"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col
                  lg={{ span: 8 }}
                  md={{ span: 10 }}
                  sm={{ span: 12 }}
                  xs={{ span: 14 }}
                >
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 12 },
                      sm: { span: 10 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Description"
                    name="description"
                  >
                    <Input
                      value={descriptionInput}
                      onInput={onDescriptionInputChange}
                      placeholder="Description"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                // style={{ paddingLeft: "20px" }}
              >
                {floors.map((floor: any, floorIndex: number) => (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 14 }}
                  >
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 12 },
                        sm: { span: 10 },
                        md: { span: 8 },
                        lg: { span: 6 },
                      }}
                      wrapperCol={{ offset: 2 }}
                      label={floor}
                      name={floor}
                    >
                      <Input
                        value={scan.floors[floorIndex]?.name}
                        defaultValue={floor}
                        onInput={(event: any) =>
                          onFloorNameChange(
                            event.target.value,
                            index,
                            floorIndex
                          )
                        }
                        placeholder={floor}
                      />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Form>
          </Card>
        ))}
        {/* {importSourceForm.map((element, index) => (
          <Card
            // size="small"
            title="Import source data"
            //Remove source button - hidden for now
            // extra={
            //   <Button
            //     icon={<CloseOutlined />}
            //     size="small"
            //     danger
            //     type="dashed"
            //     onClick={() => removeAdditionalForm(index)}
            //   >
            //     Remove source
            //   </Button>
            // }
          >
            <Form className={styles.formSection} key={index}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 2 }}
                    label="Source"
                    name="source"
                  >
                    <Select
                      placeholder="Select Source"
                      allowClear
                      value={item?.scans}
                      onChange={handleSelectedSource}
                    >
                      <Option value="0" label="Matterport Account">
                        Matterport Account
                      </Option>
                      <Option value="1" label="Matterport Scan URL">
                        Matterport Scan URL
                      </Option>
                      <Option value="2" label="VideoFile">
                        VideoFile
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {selectedSource === "matterportScan" && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ offset: 2 }}
                      label="Scan"
                      name="scan"
                    >
                      <Input
                        value={scanInput}
                        onInput={onScanInputChange}
                        placeholder="scan"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {selectedSource === "matterportAccount" && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ offset: 2 }}
                      label="Scan"
                      name="scan"
                    >
                      <Select
                        placeholder="Select Scan"
                        allowClear
                        value={selectedScan}
                        onChange={handleSelectedScanChange}
                        options={spaces.map((x: any) => ({
                          label: x.name,
                          value: x.id,
                        }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {selectedSource === "videoFile" && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  // style={{ paddingLeft: "20px" }}
                >
                  <Col span={8}>
                    <Form.Item
                      style={{ marginBottom: "0" }}
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ offset: 2 }}
                      label="Video File"
                      name="videoFile"
                    >
                      <Upload>
                        <Button icon={<UploadOutlined />}>
                          Click to upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 2 }}
                    label="Name"
                    name="name"
                  >
                    <Input
                      value={sourceNameInput}
                      onInput={onSourceNameInputChange}
                      placeholder="name"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 2 }}
                    label="Operator"
                    name="operator"
                  >
                    <Input
                      value={operatorInput}
                      onInput={onOperatorInputChange}
                      placeholder="operator"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                // style={{ paddingLeft: "20px" }}
              >
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ offset: 2 }}
                    label="Description"
                    name="description"
                  >
                    <Input
                      value={descriptionInput}
                      onInput={onDescriptionInputChange}
                      placeholder="name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        ))} */}
        {/* Add source button - hidden for now */}
        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <Button
              onClick={() => addFormFields()}
              style={{ width: "50%" }}
              type="dashed"
              icon={<PlusOutlined />}
            >
              Add additional source
            </Button>
          </Col>
        </Row> */}
        <Button
          loading={loadingButton}
          icon={<ImportOutlined />}
          onClick={onImportClick}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default Import;
