import { Layout, Image, ConfigProvider } from "antd";
import styles from "./SidebarLayout.module.scss";
import SidebarMenu from "./components/Menu";
import { useState } from "react";
import { ROUTES } from "../../../../config/constants";
import { Link, useParams } from "react-router-dom";
import logo from "../../../../img/Logo_colour-icon_white-text.png";
import logosmall from "../../../../img/Logomark_colour.png";

const { Sider } = Layout;

const Sidebar = () => {
  let { buildingId } = useParams();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  return (
    <ConfigProvider>
      <Sider
        breakpoint="lg"
        collapsible
        onCollapse={(collapsed, type) => {
          setSidebarCollapsed(collapsed);
        }}
        width={"256px"}
        style={{
          height: "100vh",
          background: "#141421",
        }}
        className={styles.displaySidebar}
      >
        {buildingId === "251" && !sidebarCollapsed && (
          <div className={styles.logo}>
            <Link to={`${ROUTES.benchmark.welcome.getRoute(buildingId)}`}>
              <Image preview={false} src={"https://lightprojects.co.uk/wp-content/uploads/2019/04/logo.png"} style={{ padding: "10px" }} />
            </Link>
          </div>
        )}
        {buildingId === "251" && sidebarCollapsed && (
           <div
           style={{ display: "flex", width: "100%", justifyContent: "center" }}
         >
           {/* //TODO - change back to homepage */}
           <Link to={`${ROUTES.benchmark.welcome.getRoute(buildingId)}`}>
             <Image
               style={{ height: "48px" }}
               className={styles.textLogoWhite}
               preview={false}
               src={"https://lightprojects.co.uk/wp-content/uploads/2023/07/LP_logo_LP_64x64_black.jpg"}
             />
           </Link>
         </div>
        )}
        {buildingId !== "251" && !sidebarCollapsed && (
          <div className={styles.logo}>
            <Link to={`${ROUTES.benchmark.welcome.getRoute(buildingId)}`}>
              <Image preview={false} src={logo} style={{ padding: "10px" }} />
            </Link>
          </div>
        )}
        {buildingId !== "251" && sidebarCollapsed && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            {/* //TODO - change back to homepage */}
            <Link to={`${ROUTES.benchmark.welcome.getRoute(buildingId)}`}>
              <Image
                style={{ height: "48px" }}
                className={styles.textLogoWhite}
                preview={false}
                src={logosmall}
              />
            </Link>
          </div>
        )}

        <SidebarMenu sidebarCollapsed={sidebarCollapsed} />
      </Sider>
    </ConfigProvider>
  );
};

export default Sidebar;
