import { Col, Form, Input, InputNumber, Row } from "antd";

import styles from "../Regions.module.css";

import { useEffect, useState } from "react";
import { Typography } from "antd";
import { UpsertRegionRequest } from "../../../../../../models/regions/UpsertRegionRequest";
import { useForm } from "antd/es/form/Form";

const { Title } = Typography;

const RegionsEditForm = ({
  item,
  setItem,
}: {
  item: UpsertRegionRequest | undefined;
  setItem: any;
}) => {
  const [drawerForm] = useForm();

  const [inputSupervisorWorkingHours, setInputSupervisorWorkingHours] =
    useState<any>("");

  useEffect(() => {
    if (item) {
      drawerForm.setFieldsValue({
        name: item.name,
        productivityRate: item.productivityRate,

        setupTime: item.standardCleaningTemplate?.setupTimeSeconds,
        finishTime: item?.standardCleaningTemplate?.finishTimeSeconds,
        walkingSpeed: item?.standardCleaningTemplate?.walkingSpeedPerSecond,
        superWorkingHours: item?.eveningCleaningTemplate.secondsPerSupervisor,
        operativeWorkingHr: item?.eveningCleaningTemplate?.secondsPerCleaner,
        operativesPerSup: item?.eveningCleaningTemplate?.cleanersPerSupervisor,
        auditPassRate: item?.eveningCleaningTemplate?.auditPassRatePercentage,
        superHourlyWage: item?.eveningCleaningTemplate?.supervisorHourlyWage,
        hourlyWagePeriodic: item?.periodicCleaningTemplate?.hourlyWage,
        profitMargin: item?.periodicCleaningTemplate?.profitMarginPercentage,
        cleaningMaterials: item?.overheadTemplate?.cleaningMaterialsPercentage,
        employerNI: item?.overheadTemplate?.employerNIPercentage,
        equipement: item?.overheadTemplate?.equipmentPercentage,
        holidaybonus: item?.overheadTemplate?.holidayBonusPercentage,
        hourly: item?.overheadTemplate?.hourlyPercentage,
        hourlyWagPercentage: item?.overheadTemplate?.hourlyWagePercentage,
        insurance: item?.overheadTemplate?.insurancePercentage,
        managementFee: item?.overheadTemplate?.managementPercentage,
        pension: item?.overheadTemplate?.pensionPercentage,
        profits: item?.overheadTemplate?.profitsPercentage,
        total: item?.overheadTemplate?.totalPercentage,
      });
    }
  }, [item, drawerForm]);

  const onNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, name: inputValue });
  };

  const onProductivityRateChange = (value: number | null) => {
    setItem({ ...item, productivityRate: value });
  };

  const onSetupTimeChange = (value: number | null) => {
    setItem({
      ...item,
      standardCleaningTemplate: {
        ...item?.standardCleaningTemplate,
        setupTimeSeconds: value,
      },
    });
  };

  const onInputFinishTime = (value: number | null) => {
    setItem({
      ...item,
      standardCleaningTemplate: {
        ...item?.standardCleaningTemplate,
        finishTimeSeconds: value,
      },
    });
  };

  const onWalkingSpeedChange = (value: number | null) => {
    setItem({
      ...item,
      standardCleaningTemplate: {
        ...item?.standardCleaningTemplate,
        walkingSpeedPerSecond: value,
      },
    });
  };

  const onSecondsperCleanerChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        secondsPerCleaner: value,
      },
    });
  };

  const onCleanerperSupervisorChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        cleanersPerSupervisor: value,
      },
    });
  };

  const onAuditPassRateChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        auditPassRatePercentage: value,
      },
    });
  };

  const onSupervisorWorkingHoursChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        secondsPerSupervisor: value,
      },
    });
  };

  const onHourlyWageChange = (value: number | null) => {
    setItem({ ...item, hourlyWage: value });
  };

  const onSupervisorHourlyWageChange = (value: number | null) => {
    setItem({
      ...item,
      eveningCleaningTemplate: {
        ...item?.eveningCleaningTemplate,
        supervisorHourlyWage: value,
      },
    });
  };

  const onHourlyWagePeriodic = (value: number | null) => {
    // setInputHourlyWagePeriodic(value);
    setItem({
      ...item,
      periodicCleaningTemplate: {
        ...item?.periodicCleaningTemplate,
        hourlyWage: value,
      },
    });
  };

  const onProfitMarginChange = (value: number | null) => {
    setItem({
      ...item,
      periodicCleaningTemplate: {
        ...item?.periodicCleaningTemplate,
        profitMarginPercentage: value,
      },
    });
  };

  const onCleaningMaterialsChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        cleaningMaterialsPercentage: value,
      },
    });
  };

  const onEmployerNiChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        employerNIPercentage: value,
      },
    });
  };

  const onEquipementChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        equipmentPercentage: value,
      },
    });
  };
  const onHolidayBonusChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        holidayBonusPercentage: value,
      },
    });
  };
  const onHourlyChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        hourlyPercentage: value,
      },
    });
  };
  const onHourlyWagePercentageChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        hourlyWagePercentage: value,
      },
    });
  };
  const onInsuranceChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        insurancePercentage: value,
      },
    });
  };
  const onManagementFeeChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        managementPercentage: value,
      },
    });
  };
  const onPensionChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        pensionPercentage: value,
      },
    });
  };
  const onProfitsChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        profitsPercentage: value,
      },
    });
  };
  const onTotalChange = (value: number | null) => {
    setItem({
      ...item,
      overheadTemplate: {
        ...item?.overheadTemplate,
        totalPercentage: value,
      },
    });
  };

  return (
    <Form form={drawerForm} className={styles.formWrap}>
      <>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            General
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Name"
                name="name"
              >
                <Input
                  value={item?.name}
                  onInput={onNameInputChange}
                  placeholder="name"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Productivity Rate"
                name="productivityRate"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  value={item?.productivityRate}
                  onChange={onProductivityRateChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            Cleaning
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Setup Time (s)"
                name="setupTime"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  value={item?.standardCleaningTemplate?.setupTimeSeconds}
                  onChange={onSetupTimeChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Finish Time (s)"
                name="finishTime"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  value={item?.standardCleaningTemplate?.finishTimeSeconds}
                  onChange={onInputFinishTime}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Walking Speed"
                name="walkingSpeed"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  value={item?.standardCleaningTemplate?.walkingSpeedPerSecond}
                  onChange={onWalkingSpeedChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            Evening Cleaning
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Supervisor Working Hours"
                name="superWorkingHours"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  value={item?.eveningCleaningTemplate.secondsPerSupervisor}
                  onChange={onSupervisorWorkingHoursChange}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Operatives per Supervisor"
                name="operativesPerSup"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  value={item?.eveningCleaningTemplate?.cleanersPerSupervisor}
                  onChange={onCleanerperSupervisorChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Supervisor Hourly Wage"
                name="superHourlyWage"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  value={item?.eveningCleaningTemplate?.supervisorHourlyWage}
                  onChange={onSupervisorHourlyWageChange}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Operative hourly wage"
                name="hourlyWage"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  value={item?.eveningCleaningTemplate?.hourlyWage}
                  onChange={onHourlyWageChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Audit Pass Rate (%)"
                name="auditPassRate"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  value={item?.eveningCleaningTemplate?.auditPassRatePercentage}
                  onChange={onAuditPassRateChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Operative working hours"
                name="operativeWorkingHr"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  value={item?.eveningCleaningTemplate?.secondsPerCleaner}
                  onChange={onSecondsperCleanerChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            Periodic Cleaning
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Hourly Wage"
                name="hourlyWagePeriodic"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  value={item?.periodicCleaningTemplate?.hourlyWage}
                  onChange={onHourlyWagePeriodic}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Profit margin (%)"
                name="profitMargin"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  // defaultValue={0}
                  value={item?.periodicCleaningTemplate?.profitMarginPercentage}
                  onChange={onProfitMarginChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            Overheads
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Cleaning materials (%)"
                name="cleaningMaterials"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  // defaultValue={0}
                  value={item?.overheadTemplate.cleaningMaterialsPercentage}
                  onChange={onCleaningMaterialsChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Employer NI (%)"
                name="employerNI"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.employerNIPercentage}
                  onChange={onEmployerNiChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Equipement (%)"
                name="equipement"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.equipmentPercentage}
                  onChange={onEquipementChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Holiday Bonus (%)"
                name="holidaybonus"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.holidayBonusPercentage}
                  onChange={onHolidayBonusChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Hourly (%)"
                name="hourly"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.hourlyPercentage}
                  onChange={onHourlyChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Hourly Wage (%)"
                name="hourlyWagPercentage"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.hourlyWagePercentage}
                  onChange={onHourlyWagePercentageChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Insurance (%)"
                name="insurance"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.insurancePercentage}
                  onChange={onInsuranceChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Management fee (%)"
                name="managementFee"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.managementPercentage}
                  onChange={onManagementFeeChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Pension (%)"
                name="pension"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.pensionPercentage}
                  onChange={onPensionChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Profits (%)"
                name="profits"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.profitsPercentage}
                  onChange={onProfitsChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 18 },
                  sm: { span: 18 },
                  md: { span: 18 },
                  lg: { span: 14 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Total (%)"
                name="total"
                initialValue={0}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // defaultValue={0}
                  max={100}
                  value={item?.overheadTemplate.totalPercentage}
                  onChange={onTotalChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    </Form>
  );
};

export default RegionsEditForm;
