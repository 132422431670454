import PageHeader from "../../../shared/header/PageHeader";

import {
  PlusOutlined,
  ApartmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { Button, Checkbox, Drawer, Space, Table } from "antd";

import styles from "../itemmasterlist/ItemMasterlist.module.css";
import { useEffect, useState } from "react";
import ItemMasterlistEditForm from "./components/ItemMasterlistEditForm";
import { ROUTES } from "../../../../../config/constants";
import { useNavigate, useParams } from "react-router-dom";

const ItemMasterlist = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [masterlistData, setMasterlistData] = useState<any[] | null>(null);
  const [item, setItem] = useState<any>();

  const { id } = useParams();
  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.adminItemMasterlist.route,
    section: "Configuration",
    subcategory: "Cleaning",
    name: ROUTES.general.adminItemMasterlist.name,
  };

  useEffect(() => {
    if (masterlistData && masterlistData.length > 0 && id) {
      const frequency = masterlistData.find((x: any) => x.id === parseInt(id));
      if (frequency) {
        setShowAddNewForm(true);
        setItem(frequency);
      }
    } else {
      setShowAddNewForm(false);
    }
  }, [masterlistData, id]);

  const handleAddNewItemMasterlist = () => {
    setShowAddNewForm(true);
  };

  const handleCancelAdd = () => {
    setShowAddNewForm(false);
    navigate(ROUTES.general.adminItemMasterlist.route);
  };

  const handleEditItemMasterlis = (record: any) => {
    setShowAddNewForm(true);
    navigate(`${ROUTES.general.adminItemMasterlist.route}/${record.id}`);
  };

  const headerButton = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={handleAddNewItemMasterlist}
    >
      Add
    </Button>
  );

  const dataSource = [
    {
      key: "1",
      name: "Air Conditioning",
      description: "",
      timing: "10s",
      fixedCost: "20",
    },
    {
      key: "2",
      name: "Kitchen",
      description: "",
      timing: "10s",
      fixedCost: "20",
    },
    {
      key: "3",
      name: "Toilet",
      description: "",
      timing: "10s",
      fixedCost: "20",
    },
    {
      key: "4",
      name: "Living Room",
      description: "",
      timing: "10s",
      fixedCost: "20",
    },
    {
      key: "5",
      name: "Bedroom",
      description: "",
      timing: "10s",
      fixedCost: "20",
    },
    {
      key: "6",
      name: "Bathroom",
      description: "",
      timing: "10s",
      fixedCost: "20",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      elipsis: true,
    },
    {
      title: "Timing",
      dataIndex: "timing",
      key: "timing",
      elipsis: true,
    },
    {
      title: "Fixed cost",
      dataIndex: "fixedCost",
      key: "fixedCost",
      elipsis: true,
    },
    {
      title: "Periodic",
      dataIndex: "periodic",
      key: "periodic",
      render: () => <Checkbox></Checkbox>,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={handleEditItemMasterlis}
            style={{ color: "#313AB8" }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{ color: "#313AB8" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Item Masterlist"}
        icon={<ApartmentOutlined />}
        mainAction={headerButton}
        setMainActionDisabled={false}
        route={route}
      />
      <div className={styles.bodyContent}>
        <Table size="middle" dataSource={dataSource} columns={columns} />
        <Drawer
          title="Add/Edit Form"
          placement="right"
          open={showAddNewForm}
          width="80%"
          closable={false}
          extra={
            <Space size="middle">
              <Button
                type="default"
                // onClick={handleAddNewRegion}
              >
                Audit
              </Button>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleCancelAdd}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                // onClick={}
              >
                Save
              </Button>
            </Space>
          }
        >
          <ItemMasterlistEditForm />
        </Drawer>
      </div>
    </div>
  );
};

export default ItemMasterlist;
