import http, { requestConfig } from "../../http-common";

const RESOURCE_NAME = "currencies";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const CurrencyService = {
  getAll,
};
export default CurrencyService;
