import http, { requestConfig } from "../../http-common";

const RESOURCE_NAME = "assetcleaningtasks";

const get = (
  buildingTypeId?: string | number | null,
  assetId?: string | number | null
) => {
  let queryString = "";
  if (buildingTypeId) queryString = `?buildingTypeId=${buildingTypeId}`;
  else if (assetId)
    queryString += buildingTypeId
      ? `&assetId=${assetId}`
      : `?assetId=${assetId}`;
  return http.get(`${RESOURCE_NAME}${queryString}`, requestConfig());
};

const getAll = () => {
  return http.get(`${RESOURCE_NAME}`, requestConfig());
};

const AssetCleaningTasksService = {
  get,
  getAll,
};
export default AssetCleaningTasksService;
