import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const authBenchmark: any = createAsyncThunk(
    "authBenchmark",
    async ({
               buildingId,
               password,
           }: {
        buildingId: string | number;
        password: string;
    }) => {
        const model = {
            buildingId: buildingId,
            password: password
        }
        const response = await axios.post(
            // `http://localhost:5057/benchmark/authorize`, model);
        `https://scanquo-platform-api.azurewebsites.net/benchmark/authorize`, model);
        return response;
    }
);
