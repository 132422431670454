export const sortByStringProperty = (a?: string | null, b?: string | null) => {
  if ((a ?? "") < (b ?? "")) {
    return -1;
  }
  if ((a ?? "") < (b ?? "")) {
    return 1;
  }
  return 0;
};

export const sortByNumberProperty = (a?: number | null, b?: number | null) => {
  return (a ?? 0) - (b ?? 0);
};

export const sortByFixedOrder = (
  order: any[],
  a?: any | null,
  b?: any | null
) => {
  return order.indexOf(a) - order.indexOf(b);
};

export const sortByDateTimeProperty = (
  a?: string | null,
  b?: string | null
) => {
  const result = new Date(a ?? "").getTime() - new Date(b ?? "").getTime();

  return result;
};

//Sort rooms and departments with departments first
export const sortByRoomsAndDepartment = (
  a?: string | null,
  b?: string | null
) => {
  if (a?.toLowerCase().includes("dpt")) return -1;
  if (b?.toLowerCase().includes("dpt")) return 1;
  return 0;
};
