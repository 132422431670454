import classNames from "classnames";
import { ICard } from "../../../../models/benchmark/shared/Card";
import styles from "./NhsCard.module.scss";

const Card = ({
  selectable = true,
  data,
  isActive,
  compareActive,
  handleCardActivate,
}: {
  selectable?: boolean | undefined;
  data: ICard;
  isActive: boolean;
  compareActive: boolean;
  handleCardActivate: (cardId: string) => void;
}) => {
  if (!compareActive && data.title === "Custom") return null;

  return data.title === "NHS Huddersfield" ? (
    <div
      className={classNames(
        styles.nhsCardContainer,
        styles.nhsCardContainerRow,
        cardStyle(data.title ?? "Premium"),
        isActive ? styles.active : ""
      )}
      onClick={() =>
        selectable ? handleCardActivate(data.title ?? "Premium") : null
      }
    >
      <div>
        <div>
          <h2>
            <span>ScanQuo Benchmark</span>
          </h2>
          {data.keypoint && (
            <div>
              <span
                className={styles.cardPrice}
              >{`${data.keypoint?.prefix}${data.keypoint?.main}`}</span>
              <span className={styles.cardPeriod}>{data.keypoint?.sufix}</span>
            </div>
          )}
          {data.details?.map((detail, index) => (
            <span key={index} className={styles.cardPeriod}>
              {detail}
            </span>
          ))}
        </div>
        <div>
          <h2>
            <span>Current NHS spend</span>
          </h2>
          <div>
            <span className={styles.cardPrice}>£ 3,500,000</span>
            <span className={styles.cardPeriod}>/year</span>
          </div>
          <span className={styles.cardPeriod} style={{ visibility: "hidden" }}>
            none
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        styles.nhsCardContainer,
        styles.nhsCardContainerCol,
        cardStyle(data.title ?? "Premium"),
        isActive ? styles.active : ""
      )}
      onClick={() =>
        selectable ? handleCardActivate(data.title ?? "Premium") : null
      }
    >
      <div>
        <h2>{data.title}</h2>
        {data.keypoint && (
          <div>
            <span
              className={styles.cardPrice}
            >{`${data.keypoint?.prefix}${data.keypoint?.main}`}</span>
            <span className={styles.cardPeriod}>{data.keypoint?.sufix}</span>
          </div>
        )}
        {data.details?.map((detail, index) => (
          <span key={index} className={styles.cardPeriod}>
            {detail}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Card;

const cardStyle = (title: string) => {
  switch (title) {
    case "Premium":
      return styles.cardPremiumContainer;
    case "Optimal":
      return styles.cardOptimalContainer;
    case "Basic":
      return styles.cardBasicContainer;
    case "Savings":
      return styles.cardPremiumContainer;
    case "Accumulated return":
      return styles.cardOptimalContainer;
    case "Manual totals":
      return styles.cardBasicContainer;
    case "Custom":
      return styles.cardCustomContainer;
    case "NHS Huddersfield":
      return styles.cardBasicContainer;
    case "2007":
      return styles.cardOptimalContainer;
    case "2021":
      return styles.cardPremiumContainer;
    default:
      return styles.cardPremiumContainer;
  }
};
