import PageHeader from "../../../shared/header/PageHeader";

import {
  PlusOutlined,
  ApartmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { Button, Drawer, message, Popconfirm, Space, Table } from "antd";

import styles from "../roomtypes/RoomTypes.module.css";
import { useEffect, useState } from "react";
import RoomTypeEditForm from "./components/RoomTypeEditForm";
import { UpsertRoomTypesRequest } from "../../../../../models/roomtypes/UpsertRoomTypesRequest";
import RoomTypesService from "../../../../../services/roomytypes/RoomTypesService";
import { ROUTES } from "../../../../../config/constants";
import { useNavigate, useParams } from "react-router-dom";

const RoomTypes = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [item, setItem] = useState<UpsertRoomTypesRequest>();
  const [roomTypes, setRoomTypes] = useState<any>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.adminRoomTypes.route,
    section: "Configuration",
    subcategory: "Building",
    name: ROUTES.general.adminRoomTypes.name,
  };

  useEffect(() => {
    resetItem();
  }, []);

  useEffect(() => {
    if (roomTypes && roomTypes.length > 0 && id) {
      const roomType = roomTypes.find((x: any) => x.id === parseInt(id));
      if (roomType) {
        setShowAddNewForm(true);
        setItem(roomType);
      }
    } else {
      setShowAddNewForm(false);
    }
  }, [roomTypes, id]);

  const resetItem = () => {
    const model: UpsertRoomTypesRequest = {
      name: "",
    };
    setItem({ ...model });
  };

  const handleAddNewRoom = () => {
    setShowAddNewForm(true);
  };

  const handleCancelAdd = () => {
    resetItem();
    setShowAddNewForm(false);
    setLoadingButton(false);
    navigate(ROUTES.general.adminRoomTypes.route);
  };

  const handleEditRoom = (record: any) => {
    setShowAddNewForm(true);
    setItem(record);
    navigate(`${ROUTES.general.adminRoomTypes.route}/${record.id}`);
  };

  const onDoubleClickRow = (record: any) => {
    console.log(record);
    setItem(record);
    navigate(`${ROUTES.general.adminRoomTypes.route}/${record.id}`);
  };

  useEffect(() => {
    if (isTableLoading) {
      RoomTypesService.getAll().then((response: any) => {
        const data = response.data;
        setRoomTypes(data);
        setIsTableLoading(false);
      });
    }
  }, [isTableLoading]);

  const onSaveRoomType = () => {
    setLoadingButton(true);
    if (item?.id) {
      RoomTypesService.update(item!).then((response: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        setItem(item);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminRoomTypes.route);
      });
    } else {
      RoomTypesService.create(item!).then((respone: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminRoomTypes.route);
      });
    }
  };

  const handleDeleteRoomType = (id: number) => {
    RoomTypesService.remove(id).then((response: any) => {
      resetItem();
      setIsTableLoading(true);
    });
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };

  const headerButton = (
    <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNewRoom}>
      Add
    </Button>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditRoom(record)}
            style={{ color: "#313AB8" }}
          />
          <Popconfirm
            title="Are you sure you want to delete this room type?"
            placement="topRight"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteRoomType(record?.id!)}
            onCancel={() => cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{ color: "#313AB8" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Room Types"}
        icon={<ApartmentOutlined />}
        mainAction={headerButton}
        setMainActionDisabled={false}
        additionalBreadcrumbs={
          !showAddNewForm && !item?.id ? null : [item?.name ?? ""]
        }
        route={route}
      />
      <div className={styles.bodyContent}>
        <Table
          size="middle"
          dataSource={roomTypes}
          columns={columns}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                setShowAddNewForm(true);
                onDoubleClickRow(record);
              },
            };
          }}
        />
        <Drawer
          title={item?.id ? "Edit" : "Add"}
          placement="right"
          open={showAddNewForm}
          width="30%"
          closable={false}
          extra={
            <Space size="middle">
              <Button
                type="default"
                // onClick={handleAddNewRegion}
              >
                Audit
              </Button>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleCancelAdd}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={onSaveRoomType}
                loading={loadingButton}
              >
                Save
              </Button>
            </Space>
          }
        >
          <RoomTypeEditForm item={item} setItem={setItem} />
        </Drawer>
      </div>
    </div>
  );
};

export default RoomTypes;
