import { Layout, Image, ConfigProvider } from "antd";
import styles from "./SidebarLayout.module.scss";
import SidebarMenu from "./components/Menu";
import { useState } from "react";
import { ROUTES } from "../../../../config/constants";
import { Link } from "react-router-dom";
import logo from "../../../../img/Logo_colour-icon_white-text.png";
import logosmall from "../../../../img/Logomark_colour.png";

const { Sider } = Layout;

const Sidebar = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  return (
    <ConfigProvider>
      <Sider
        breakpoint="lg"
        collapsible
        onBreakpoint={(broken) => {}}
        onCollapse={(value, type) => {
          setSidebarCollapsed(value);
        }}
        width={"256px"}
        style={{
          height: "100vh",
          background: "#141421",
        }}
        className={styles.displaySidebar}
      >
        {!sidebarCollapsed ? (
          <div className={styles.logo}>
            <Link to={""}>
              <Image preview={false} src={logo} style={{ padding: "10px" }} />
            </Link>
          </div>
        ) : (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Link to={ROUTES.general.main.route}>
              <Image
                style={{ height: "48px" }}
                className={styles.textLogoWhite}
                preview={false}
                src={logosmall}
              />
            </Link>
          </div>
        )}

        <SidebarMenu sidebarCollapsed={sidebarCollapsed} />
      </Sider>
    </ConfigProvider>
  );
};

export default Sidebar;
