import { Checkbox, Col, Form, Input, Row } from "antd";

import styles from "../../buildingtypes/BuildingTypes.module.css";

import { useEffect } from "react";
import { Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { UpsertCleaningTasks } from "../../../../../../models/cleaningTasks/UpsertCleaningTasks";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const { Title } = Typography;

const CleaningTasksEditForm = ({
  item,
  setItem,
}: {
  item: UpsertCleaningTasks | undefined;
  setItem: any;
}) => {
  const [drawerForm] = useForm();

  useEffect(() => {
    if (item) {
      drawerForm.setFieldsValue({
        name: item.name,
        description: item.description,
        isFloorcare: item.isFloorcare,
      });
    }
  }, [item, drawerForm]);

  const onNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, name: inputValue });
  };

  const onDescriptionInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, description: inputValue });
  };

  const handleIsFlorcare = (e: CheckboxChangeEvent) => {
    const inputValue = e.target.value;
    setItem({ ...item, isFloorcare: inputValue });
  };

  return (
    <Form form={drawerForm} className={styles.formWrap}>
      <>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            General
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={20}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ offset: 2 }}
                label="Name"
                name="name"
              >
                <Input
                  value={item?.name}
                  onInput={onNameInputChange}
                  placeholder="name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={20}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ offset: 2 }}
                label="Description"
                name="description"
              >
                <Input
                  value={item?.description}
                  onInput={onDescriptionInputChange}
                  placeholder="description"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={20}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 6 }}
                // wrapperCol={{ offset: 2 }}
                name="isFloorcare"
              >
                <Checkbox onChange={handleIsFlorcare}>Is Floorcare</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    </Form>
  );
};

export default CleaningTasksEditForm;
