import { Layout, Image, ConfigProvider, Button, Drawer } from "antd";
import styles from "./NavbarLayout.module.scss";
import { useState } from "react";
import { ROUTES } from "../../../../config/constants";
import { Link, useParams } from "react-router-dom";
import logo from "../../../../img/Logo_colour-icon_white-text.png";
import logosmall from "../../../../img/Logomark_colour.png";
import { MenuOutlined } from "@ant-design/icons";

const Navbar = () => {
  const [draverVisible, setDrawerVisible] = useState(false);

  const showMenuDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <>
      <div className={styles.navbarContainer}>
        <Image
          preview={false}
          src={logo}
          style={{ padding: "10px", width: "134px" }}
        />
        <Button
          ghost
          className={styles.menuContainer}
          icon={<MenuOutlined />}
          onClick={showMenuDrawer}
        />
      </div>
      <Drawer width={"70%"} open={draverVisible} onClose={onClose}>
        test
      </Drawer>
    </>
  );
};

export default Navbar;
