import { createSlice } from '@reduxjs/toolkit';
import {
    getBenchmark 
  } from './actions'
import { BenchmarkState } from './benchmark.dto';

const initialState: BenchmarkState = {
      benchmark: null,
      pending: false,
      error: false
};

export const benchmarkSlice = createSlice({
    name: 'benchmark',
    initialState,
    reducers: {
      resetBenchmark: (state) => {}
    },
    extraReducers: (builder) => {
          builder
              .addCase(getBenchmark.pending, (state) => {
              state.pending = true;
              })
              .addCase(getBenchmark.fulfilled, (state, {payload}) => {
                state.pending = false;
                state.benchmark = payload.data;
              })
              .addCase(getBenchmark.rejected, (state) => {
              state.pending = false;
              state.error = true;
              });
          }
});

export const {
  reducer: benchmarkReducer, actions 
} = benchmarkSlice;