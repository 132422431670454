import PageHeader from "../../../shared/header/PageHeader";

import {
  PlusOutlined,
  ApartmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { Button, Drawer, message, Popconfirm, Space, Table } from "antd";

import styles from "../cleaningtasks/CleaningTasks.module.css";
import { useEffect, useState } from "react";
import { UpsertCleaningTasks } from "../../../../../models/cleaningTasks/UpsertCleaningTasks";
import CleaningTasksService from "../../../../../services/cleaningtasks/CleaningTasksSerivice";
import CleaningTasksEditForm from "./components/CleaningTasksEditForm";
import { ROUTES } from "../../../../../config/constants";
import { useNavigate, useParams } from "react-router-dom";

const CleaningTasks = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [generalTasks, setGeneralTasks] = useState<any>([]);
  const [item, setItem] = useState<UpsertCleaningTasks>();
  const [loadingButton, setLoadingButton] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.adminCleaningTasks.route,
    section: "Configuration",
    subcategory: "Cleaning",
    name: ROUTES.general.adminCleaningTasks.name,
  };

  const resetItem = () => {
    const model: UpsertCleaningTasks = {
      tenantId: 1,
      name: "",
      description: "",
      isFloorcare: false,
    };
    setItem({ ...model });
  };

  useEffect(() => {
    resetItem();
  }, []);

  useEffect(() => {
    if (generalTasks && generalTasks.length > 0 && id) {
      const generalTask = generalTasks.find((x: any) => x.id === parseInt(id));
      if (generalTask) {
        setShowAddNewForm(true);
        setItem(generalTask);
      }
    } else {
      setShowAddNewForm(false);
    }
  }, [generalTasks, id]);

  const handleAddNewTask = () => {
    setShowAddNewForm(true);
  };

  const handleCancelAdd = () => {
    resetItem();
    setShowAddNewForm(false);
    setLoadingButton(false);
    navigate(ROUTES.general.adminCleaningTasks.route);
  };

  const handleEditTask = (record: any) => {
    setShowAddNewForm(true);
    setItem(record);
    navigate(`${ROUTES.general.adminCleaningTasks.route}/${record.id}`);
  };

  const handleDeleteTask = (id: number) => {
    CleaningTasksService.remove(id).then((response: any) => {
      resetItem();
      setIsTableLoading(true);
    });
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };

  const onDoubleClickRow = (record: any) => {
    console.log(record);
    setItem(record);
    navigate(`${ROUTES.general.adminCleaningTasks.route}/${record.id}`);
  };

  const onSaveTask = () => {
    setLoadingButton(true);
    if (item?.id) {
      CleaningTasksService.update(item!).then((response: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        setItem(item);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminCleaningTasks.route);
      });
    } else {
      CleaningTasksService.create(item!).then((respone: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminCleaningTasks.route);
      });
    }
  };

  useEffect(() => {
    if (isTableLoading) {
      CleaningTasksService.getAll().then((response: any) => {
        const data = response.data;
        setGeneralTasks(data);
        setIsTableLoading(false);
      });
    }
  }, [isTableLoading]);

  const headerButton = (
    <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNewTask}>
      Add
    </Button>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      elipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditTask(record)}
            style={{ color: "#313AB8" }}
          />
          <Popconfirm
            title="Are you sure you want to delete this task?"
            placement="topRight"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteTask(record?.id!)}
            onCancel={() => cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{ color: "#313AB8" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Cleaning Tasks"}
        icon={<ApartmentOutlined />}
        mainAction={headerButton}
        setMainActionDisabled={false}
        additionalBreadcrumbs={
          !showAddNewForm && !item?.id ? null : [item?.name ?? ""]
        }
        route={route}
      />
      <div className={styles.bodyContent}>
        <Table
          size="middle"
          dataSource={generalTasks}
          columns={columns}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                setShowAddNewForm(true);
                onDoubleClickRow(record);
              },
            };
          }}
        />
        <Drawer
          title={item?.id ? "Edit" : "Add"}
          placement="right"
          open={showAddNewForm}
          width="30%"
          closable={false}
          extra={
            <Space size="middle">
              <Button type="default" onClick={handleAddNewTask}>
                Audit
              </Button>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleCancelAdd}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={onSaveTask}
                loading={loadingButton}
              >
                Save
              </Button>
            </Space>
          }
        >
          <CleaningTasksEditForm item={item} setItem={setItem} />
        </Drawer>
      </div>
    </div>
  );
};

export default CleaningTasks;
