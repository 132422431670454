import http, { requestConfig } from "../../http-common";
import { UpsertRoomTypesRequest } from "../../models/roomtypes/UpsertRoomTypesRequest";

const RESOURCE_NAME = "roomtypes";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const create = (model: UpsertRoomTypesRequest) => {
  return http.post(RESOURCE_NAME, model, requestConfig());
};

const update = (model: UpsertRoomTypesRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const remove = (regionId: number) => {
  return http.delete(`${RESOURCE_NAME}/${regionId}`, requestConfig());
};

const RoomTypesService = {
  getAll,
  create,
  update,
  remove,
};
export default RoomTypesService;
