import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Image,
  Input,
  notification,
} from "antd";

import styles from "../auth/Login.module.css";
import "./Login.css";
import logoWhiteVertical from "../../img/vertical_logo_colour_icon_white-text.png";
import logoWhite from "../../img/Logo_colour-icon_white-text.png";
import {
  LoginOutlined,
  CloseOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { setProfile } from "../../redux/actions";

import { Typography } from "antd";
import AuthService from "../../services/auth/AuthService";
import { NotificationPlacement } from "antd/es/notification/interface";
import { login } from "../../store/profile";
import { useAppDispatch } from "../../store/hooks";
const { Title } = Typography;

const Login = () => {
  const dispatch = useAppDispatch()<any>;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = () => {
    setIsLoading(true);
    dispatch(login({ username: inputEmail, password: inputPassword })); // TODO: FIX
    // AuthService.login(model)
    //   .then((response: any) => {
    //     if (!response.data) {
    //       openFailedNotification(
    //         "Failed to login: Invalid username or password",
    //         "bottomRight"
    //       );
    //       setIsLoading(false);
    //     } else {
    //       const profile = {
    //         id: response.data.id,
    //         accessToken: response.data.accessToken,
    //         permissions: response.data.permissions,
    //         isSocial: response.data.isSocial,
    //       };
    //       dispatch(setProfile(profile));
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((error: any) => {
    //     openFailedNotification(
    //       "Failed to login: " + error.response.data,
    //       "bottomRight"
    //     );
    //     setIsLoading(false);
    //   });
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const onClose = () => {
    setShowLoginModal(false);
  };

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const openFailedNotification = (
    description: string,
    placement: NotificationPlacement
  ) => {
    notification.error({
      message: `Error`,
      description: description,
      placement,
    });
  };

  const onEmailInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setInputEmail(inputValue);
  };

  const onPasswordInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setInputPassword(inputValue);
  };

  return (
    <div className={styles.loginWrap} style={{ height: "100vh" }}>
      {!showLoginModal ? (
        <>
          <Image
            // width="450px"
            preview={false}
            src={logoWhiteVertical}
            className={styles.loginImage}
          />
          <div className={styles.textAndButton}>
            <Divider style={{ border: "1px solid #fff" }} />
            <Title
              level={3}
              style={{ color: "white" }}
              className={styles.titleText}
            >
              The Global Benchmark for Contract Price Modelling in {"\n"}{" "}
              Facility Management & Proptech
            </Title>
            <Divider style={{ border: "1px solid #fff" }} />
            <Button
              className={styles.coloredButton}
              icon={<LoginOutlined />}
              onClick={handleLoginClick}
            >
              Sign In
            </Button>
          </div>
        </>
      ) : (
        <div>
          <Image
            preview={false}
            src={logoWhite}
            className={styles.loginImage}
          />
          <div className={styles.loginModal}>
            <div className={styles.modalHeader}>
              <Title style={{ margin: "0px" }} level={4}>
                Welcome!
              </Title>
              <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
            </div>
            <div className={styles.formContainer}>
              {/* <div>Microsoft login - will be</div>
              <Divider style={{ margin: "0" }}>or</Divider> */}
              <Form
                name="normal_login"
                className={styles.loginInputs}
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    value={inputEmail}
                    onInput={onEmailInputChange}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    value={inputPassword}
                    onInput={onPasswordInputChange}
                  />
                </Form.Item>
                <Form.Item className={styles.checkboxAndLink}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  {/* <a className="login-form-forgot" href="">
                    Forgot password?
                  </a> */}
                </Form.Item>

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    className={styles.coloredButton}
                    icon={<LoginOutlined />}
                    onClick={handleSignIn}
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
