import { Col, Form, Input, Row, Select } from "antd";

import styles from "../../buildingtypes/BuildingTypes.module.css";

import { useEffect, useState } from "react";
import { Typography } from "antd";
import { UpsertBuildingTypeRequest } from "../../../../../../models/buildingTypes/UpsertBuildingTypeRequest";
import { useForm } from "antd/es/form/Form";
import VerticalsService from "../../../../../../services/verticals/VerticalsService";

const { Title } = Typography;

const BuildingTypesEditForm = ({
  item,
  setItem,
  verticals,
  setVerticals,
}: {
  item: UpsertBuildingTypeRequest | undefined;
  setItem: any;
  verticals: any;
  setVerticals: any;
}) => {
  const [drawerForm] = useForm();

  useEffect(() => {
    if (item) {
      drawerForm.setFieldsValue({
        name: item.name,
        verticals: item.verticalId,
      });
    }
  }, [item, drawerForm]);

  useEffect(() => {
    VerticalsService.getAll().then((response: any) => {
      const data = response.data;
      setVerticals(data);
    });
  }, []);

  const onNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, name: inputValue });
  };

  const handleVerticalSelect = (value: number) => {
    setItem({ ...item, verticalId: value });
  };

  return (
    <Form form={drawerForm} className={styles.formWrap}>
      <>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            General
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={24}>
              <Form.Item
                label="Verticals"
                name="verticals"
                labelAlign="left"
                labelCol={{ span: 4 }}
                wrapperCol={{ offset: 2 }}
                style={{ marginBottom: "0" }}
              >
                <Select
                  allowClear
                  placeholder="Select Verticals"
                  // style={{ width: "300px" }}
                  value={item?.verticalId}
                  onChange={handleVerticalSelect}
                  options={verticals.map((x: any) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={24}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 4 }}
                wrapperCol={{ offset: 2 }}
                label="Name"
                name="name"
              >
                <Input
                  disabled={!item?.verticalId}
                  value={item?.name}
                  onInput={onNameInputChange}
                  placeholder="name"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    </Form>
  );
};

export default BuildingTypesEditForm;
