import http, { requestConfig } from "../../http-common";

const RESOURCE_NAME = "measurementsystems";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const MeasurementSystemService = {
  getAll,
};
export default MeasurementSystemService;
