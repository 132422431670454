import http, { requestConfig } from "../../http-common";
import { UpsertFrequencyRequest } from "../../models/frequencies/UpsertFrequencyRequest";

const RESOURCE_NAME = "frequencies";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const create = (model: UpsertFrequencyRequest) => {
  return http.post(RESOURCE_NAME, model, requestConfig());
};

const update = (model: UpsertFrequencyRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const remove = (id: number) => {
  return http.delete(`${RESOURCE_NAME}/${id}`, requestConfig());
};

const FrequencyService = {
  getAll,
  create,
  update,
  remove,
};
export default FrequencyService;
