import http, { requestConfig } from "../../http-common";
import { IEveningCleaningTemplate } from "../../models/buildings/UpdateBuildingRequest";

const RESOURCE_NAME = "eveningcleaningtemplates";

const put = (model: IEveningCleaningTemplate) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const EveningCleaningTemplatesService = {
  put,
};
export default EveningCleaningTemplatesService;
