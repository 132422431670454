import PageHeader from "../../../shared/header/PageHeader";

import {
  PlusOutlined,
  ApartmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";

import styles from "../countries/Countries.module.css";
import { useEffect, useState } from "react";
import CountriesEditForm from "./components/CountriesEditForm";
import { UpsertCountriesRequest } from "../../../../../models/countries/UpsertCountriesRequest";
import CountriesService from "../../../../../services/countries/CountriesService";
import RegionsService from "../../../../../services/regions/RegionsService";
import { useForm } from "antd/es/form/Form";
import { ROUTES } from "../../../../../config/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Countries = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [item, setItem] = useState<UpsertCountriesRequest>();
  const [countries, setCountries] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [drawerForm] = useForm();

  const [selectedRegionId, setSelectedRegionId] = useState<
    number | null | undefined
  >(undefined);

  const { id } = useParams();
  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.adminCountries.route,
    section: "Configuration",
    subcategory: "Location",
    name: ROUTES.general.adminCountries.name,
  };

  useEffect(() => {
    resetItem();
  }, []);

  const resetItem = () => {
    const model: UpsertCountriesRequest = {
      tenantId: 1,
      name: "",
      productivityRate: 0,
      regionId: 0,
      currencyId: 1,
      measurementSystemId: 1,
      overheadTemplate: {
        tenantId: 1,
        name: "",
        cleaningMaterialsPercentage: 0,
        holidayBonusPercentage: 0,
        insurancePercentage: 0,
        profitsPercentage: 0,
        employerNIPercentage: 0,
        hourlyPercentage: 0,
        managementPercentage: 0,
        totalPercentage: 0,
        equipmentPercentage: 0,
        hourlyWagePercentage: 0,
        pensionPercentage: 0,
      },
      standardCleaningTemplate: {
        tenantId: 1,
        name: "",
        setupTimeSeconds: 0,
        finishTimeSeconds: 0,
        walkingSpeedPerSecond: 0,
      },
      eveningCleaningTemplate: {
        tenantId: 1,
        name: "",
        totalTimeSeconds: 0,
        secondsPerCleaner: 0,
        hourlyWage: 0,
        cleanersPerSupervisor: 0,
        auditPassRatePercentage: 0,
        secondsPerSupervisor: 0,
        supervisorHourlyWage: 0,
      },
      periodicCleaningTemplate: {
        tenantId: 1,
        name: "",
        profitMarginPercentage: 0,
        hourlyWage: 0,
      },
      roboticVacuumingTemplate: {
        tenantId: 1,
        name: "",
      },
    };

    setItem({ ...model });
  };

  useEffect(() => {
    RegionsService.getAll().then((response: any) => {
      const data = response.data;
      setRegions(data);
    });
  }, []);

  useEffect(() => {
    if (regions && regions.length > 0 && id) {
      setIsTableLoading(true);
      CountriesService.getAll().then((response: any) => {
        const countriesData = response.data;
        const selectedCountry = countriesData.find(
          (x: any) => x.id === parseInt(id)
        );
        if (selectedCountry) {
          setShowAddNewForm(true);
          setSelectedRegionId(
            regions.find((x: any) => x.id === selectedCountry.regionId).id
          );
          setCountries(
            countriesData.filter(
              (x: any) => x.regionId === selectedCountry.regionId
            )
          );
          setItem(selectedCountry);
        }
        setIsTableLoading(false);
      });
    } else if (selectedRegionId) {
      setIsTableLoading(true);
      CountriesService.getAll().then((response: any) => {
        const data = response.data;
        setCountries(data.filter((x: any) => x.regionId === selectedRegionId));
        setIsTableLoading(false);
      });
      setShowAddNewForm(false);
    }
  }, [regions, id, selectedRegionId]);

  const handleAddNewCountry = () => {
    setShowAddNewForm(true);
  };

  const handleCancelAdd = () => {
    resetItem();
    setShowAddNewForm(false);
    setLoadingButton(false);
    navigate(ROUTES.general.adminCountries.route);
  };

  const handleEditCountry = (record: any) => {
    setShowAddNewForm(true);
    setItem(record);
    navigate(`${ROUTES.general.adminCountries.route}/${record.id}`);
  };

  const onSaveCountries = () => {
    setLoadingButton(true);

    item!.eveningCleaningTemplate.secondsPerSupervisor =
      item!.eveningCleaningTemplate.secondsPerSupervisor * 3600;
    item!.eveningCleaningTemplate.secondsPerCleaner =
      item!.eveningCleaningTemplate.secondsPerCleaner * 3600;

    if (item?.id) {
      CountriesService.update(item!).then((response: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        setItem(item);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminCountries.route);
      });
    } else {
      CountriesService.create(item!).then((respone: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminCountries.route);
      });
    }
  };

  const handleDeleteCountry = (id: number) => {
    CountriesService.remove(id).then((response: any) => {
      resetItem();
      setIsTableLoading(true);
    });
  };

  const onDoubleClickRow = (record: any) => {
    setItem(record);
    navigate(`${ROUTES.general.adminCountries.route}/${record.id}`);
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };

  useEffect(() => {
    if (isTableLoading && selectedRegionId) {
      CountriesService.getAll().then((response: any) => {
        const data = response.data;
        setCountries(data.filter((x: any) => x.regionId === selectedRegionId));
        setIsTableLoading(false);
      });
    } else {
      setIsTableLoading(false);
    }
  }, [isTableLoading, selectedRegionId]);

  const handleRegionsSelect = (value: number) => {
    setSelectedRegionId(value);
    setIsTableLoading(true);
  };

  const headerButton = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={handleAddNewCountry}
    >
      Add
    </Button>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Productivity Rate",
      dataIndex: "productivityRate",
      key: "productivityRate",
      elipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditCountry(record)}
            style={{ color: "#313AB8" }}
          />
          <Popconfirm
            title="Are you sure you want to delete this country?"
            placement="topRight"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteCountry(record?.id!)}
            onCancel={() => cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{ color: "#313AB8" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Countries"}
        icon={<ApartmentOutlined />}
        mainAction={headerButton}
        setMainActionDisabled={false}
        additionalBreadcrumbs={
          !showAddNewForm && !item?.id ? null : [item?.name ?? ""]
        }
        route={route}
      />
      <div className={styles.bodyContent}>
        <Form>
          <Form.Item label="Region" name="region" style={{ marginBottom: "0" }}>
            <Select
              placeholder="Select Region"
              style={{ width: "300px" }}
              onChange={handleRegionsSelect}
              options={regions.map((x: any) => ({
                label: x.name,
                value: x.id,
              }))}
              defaultValue={selectedRegionId}
            />
          </Form.Item>
        </Form>

        <Table
          size="middle"
          dataSource={countries}
          columns={columns}
          loading={isTableLoading}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                setShowAddNewForm(true);
                onDoubleClickRow(record);
              },
            };
          }}
        />
        <Drawer
          title={item?.id ? "Edit" : "Add"}
          placement="right"
          open={showAddNewForm}
          width="80%"
          closable={false}
          extra={
            <Space size="middle">
              <Button
                type="default"
                // onClick={handleAddNewRegion}
              >
                Audit
              </Button>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleCancelAdd}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={onSaveCountries}
                loading={loadingButton}
              >
                Save
              </Button>
            </Space>
          }
        >
          <CountriesEditForm item={item} setItem={setItem} />
        </Drawer>
      </div>
    </div>
  );
};

export default Countries;
