import L from "leaflet";
import polylabel from "polylabel";
import { IScanResponse } from "../models/scans/ScanResponse";

export const calculateActiveFeatureTooltipPosition = (
  feature: any,
  zoomLv?: number | null
) => {
  if (!feature) return null;

  var polylineCenterCoords = polylabel(
    feature.transformedGeometry.coordinates,
    1.0
  );
  let roundedZoomlv = Math.floor(zoomLv || 0);
  var latlng = L.latLng(
    polylineCenterCoords[1] - 50 / Math.pow(2, roundedZoomlv),
    polylineCenterCoords[0] + 25 / Math.pow(2, roundedZoomlv)
  ); // modify the latlng to adjust the tooltip position

  return latlng;
};

export const generateUuid = () => {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const bytesArrToBase64 = (arr: Uint8Array) => {
  const abc =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"; // base64 alphabet
  const bin = (n: any) => n.toString(2).padStart(8, 0); // convert num to 8-bit binary string
  const l = arr.length;
  let result = "";

  for (let i = 0; i <= (l - 1) / 3; i++) {
    const c1 = i * 3 + 1 >= l; // case when "=" is on end
    const c2 = i * 3 + 2 >= l; // case when "=" is on end
    const chunk =
      bin(arr[3 * i]) +
      bin(c1 ? 0 : arr[3 * i + 1]) +
      bin(c2 ? 0 : arr[3 * i + 2]);
    const r = chunk
      .match(/.{1,6}/g)
      .map((x: any, j: any) =>
        j === 3 && c2 ? "=" : j === 2 && c1 ? "=" : abc[+("0b" + x)]
      );
    result += r.join("");
  }

  return result;
};

export const firstCharToUpperCase = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const camelCaseToNormalCase = (s: string) => {
  if (typeof s !== "string") return "";
  return (
    s.charAt(0).toUpperCase() +
    s
      .slice(1)
      .replace(/([A-Z])/g, " $1")
      .trim()
  );
};

export const formatNumberTo2DecimalPlaces = (n: any) => {
  if (n === null || n === undefined) {
    return "0.00";
  }
  return parseFloat(n)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const randomInteger = (max: number, min: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + 1;
};

export const randomRgbColor = () => {
  let r = randomInteger(245, 10);
  let g = randomInteger(245, 10);
  let b = randomInteger(245, 10);
  return [r, g, b];
};

export const randomHexColor = () => {
  let [r, g, b] = randomRgbColor();

  let hr = r.toString(16).padStart(2, "0");
  let hg = g.toString(16).padStart(2, "0");
  let hb = b.toString(16).padStart(2, "0");

  return "#" + hr + hg + hb;
};

export const findMissingRoomNumbers = (listOfRoomNumbers: number[]) => {
  let missingRoomNumbers = [];
  for (var i = 1; i <= listOfRoomNumbers.length; i++) {
    if (listOfRoomNumbers.indexOf(i) === -1) {
      missingRoomNumbers.push(i);
    }
  }

  return missingRoomNumbers.length > 0 ? missingRoomNumbers[0] : 0;
};

export const extractMatterportFileId = (scan: IScanResponse | undefined) => {
  if (!scan) return "";
  const matterportScanId = scan?.benchmarkRawFootageLink
    ?.split("/")
    .pop()
    ?.split("=")
    .pop();

  return matterportScanId ?? "";
};
