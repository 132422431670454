import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Upload,
} from "antd";
import { UploadOutlined, EditOutlined } from "@ant-design/icons";

import styles from "../Buildings.module.css";

import { useEffect, useState } from "react";
import { Typography } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { IUpdateBuildingRequest } from "../../../../../models/buildings/UpdateBuildingRequest";
import { useForm } from "antd/es/form/Form";

const { Title } = Typography;

const PresentationData = ({
  item,
  setItem,
}: {
  item: IUpdateBuildingRequest | undefined;
  setItem: any;
}) => {
  const [presentationDataForm] = useForm();
  const [selectedFileList, setSelectedFileList] = useState<any>([]);

  const onPageTitleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, benchmarkLandingPageTitle: inputValue });
  };

  const onVirtualWalkInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, benchmarkRawFootageLink: inputValue });
  };

  const handleFileChange = (value: UploadChangeParam<UploadFile>) => {
    if (value.fileList.length === 0) {
      setSelectedFileList([]);
      setItem({ ...item, benchmarkLandingPageImage: null });
    } else {
      const fileObject: any = value?.fileList[0].originFileObj;
      setSelectedFileList(value?.fileList);

      const reader = new FileReader();
      reader.readAsDataURL(fileObject);

      reader.onload = () => {
        const res = reader.result?.toString();
        setItem({ ...item, benchmarkLandingPageImage: res });
      };
    }
  };

  const customRequest = (options: any) => {
    // options?.onSuccess?("ok")
  };

  const preventRequest = () => false;

  useEffect(() => {
    if (item?.benchmarkLandingPageImage) {
      const newUploadFile: UploadFile = {
        uid: "-1",
        name: "LandingPageImage.png",
        status: "done",
        url: item.benchmarkLandingPageImage,
      };
      setSelectedFileList([newUploadFile]);
    }
  }, []);

  useEffect(() => {
    if (item) {
      presentationDataForm.setFieldsValue({
        virtualWalkLink: item.benchmarkRawFootageLink,
        landingPageTitle: item.benchmarkLandingPageTitle,
      });
    }
  }, [item]);

  const dataSource = [
    {
      key: "1",
      number: "1",
      name: "Executive Summary",
    },
    // {
    //   key: "2",
    //   number: "2",
    //   name: "Scanning Benefits",
    // },
    {
      key: "3",
      number: "3",
      name: "Evening Cleaning",
    },
    {
      key: "4",
      number: "4",
      name: "Daytime Janitor",
    },
    {
      key: "5",
      number: "5",
      name: "Periodic Cleaning",
    },
    {
      key: "6",
      number: "6",
      name: "Robotic Vacuuming",
    },
    {
      key: "7",
      number: "7",
      name: "Floor Mapping",
    },
  ];

  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: "80px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "visible",
      width: "80px",
      render: () => <Checkbox></Checkbox>,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined />}
            style={{ color: "#313AB8" }}
            // onClick={handleEditBuilding}
          />
        </Space>
      ),
    },
  ];

  return (
    <Form form={presentationDataForm} className={styles.formWrap}>
      <>
        <div className={styles.formSection}>
          <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
            General
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            // style={{ paddingLeft: "20px" }}
          >
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 10 }}
                wrapperCol={{ offset: 2 }}
                label="Landing Page Background"
                name="landingPageBackground"
                className={styles.uploadItem}
              >
                <Upload
                  fileList={selectedFileList}
                  onChange={handleFileChange}
                  beforeUpload={preventRequest}
                  customRequest={customRequest}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            // style={{ paddingLeft: "20px" }}
          >
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 10 }}
                wrapperCol={{ offset: 2 }}
                label="Landing Page Title"
                name="landingPageTitle"
              >
                <Input
                  value={item?.benchmarkLandingPageTitle ?? ""}
                  onInput={onPageTitleInputChange}
                  placeholder="name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            // style={{ paddingLeft: "20px" }}
          >
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 10 }}
                wrapperCol={{ offset: 2 }}
                label="Virtual Walk Link"
                name="virtualWalkLink"
              >
                <Input
                  value={item?.benchmarkRawFootageLink ?? ""}
                  onInput={onVirtualWalkInputChange}
                  placeholder="name"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <Title level={4} style={{ margin: "0", color: "#313AB8" }}>
            Slides
          </Title>
          <Table size="middle" dataSource={dataSource} columns={columns} />
        </div>
      </>
    </Form>
  );
};

export default PresentationData;
