import { createReducer } from "@reduxjs/toolkit";
import { authBenchmark } from "./actions";
import {AuthState} from "./auth.dto";

const initialState: AuthState = {
  auth: null,
  pending: false,
  error: false,
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(authBenchmark.pending, (state) => {
      state.pending = true;
    })
    .addCase(authBenchmark.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.auth = payload;
    })
    .addCase(authBenchmark.rejected, (state) => {
      state.pending = false;
      state.error = true;
    });
});
