import http, { requestConfig } from "../../http-common";
import { UpsertRegionRequest } from "../../models/regions/UpsertRegionRequest";

const RESOURCE_NAME = "regions";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const create = (model: UpsertRegionRequest) => {
  return http.post(RESOURCE_NAME, model, requestConfig());
};

const update = (model: UpsertRegionRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const remove = (regionId: number) => {
  return http.delete(`${RESOURCE_NAME}/${regionId}`, requestConfig());
};

const RegionsService = {
  getAll,
  create,
  update,
  remove,
};
export default RegionsService;
