export interface ITable {
  title?: string | null;
  headers?: IHeader[] | null;
  rows?: IRow[] | null;
}

export interface IHeader {
  name?: string | null;
  isVisible?: boolean | null;
  width?: string | null;
}

export interface IRow {
  cells?: ICell[] | null;
}

export interface ICell {
  data?: IData | null;
}

export interface IData {
  value?: any | null;
  formattedValue?: string | null;
  dataType?: DataType | null;
}

export enum DataType {
  Text, // RAW - string
  Number, // RAW - double
  TimeSeconds, // RAW - double in seconds
  TimeMinutes, // RAW - double in seconds
  TimeHours, // RAW - double in seconds
  TimeDays,
  AreaMetric, // RAW - double in sq. meters
  AreaImperial, // RAW - double in sq. meters
  Currency, // RAW - double in US dollars
  Percentage, // RAW - double >= 0 && <= 1
}
