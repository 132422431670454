import styles from "./Layout.module.scss";
import {
  LeftOutlined,
  RightOutlined,
  ExpandAltOutlined,
  CloseOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getCurrentPageNum,
  getPageName,
  getPageRoute,
} from "../../../utils/routing";
import { Button, Input, Modal, Space, Form, Divider } from "antd";
import PageHeader from "../../platform/shared/header/PageHeader";
import { ROUTES } from "../../../config/constants";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { actions, getBenchmark } from "../../../store/benchmark";

const Head = () => {
  const [dispatched, setDispatched] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const [pageNum, setPageNum] = useState<number>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [shareModalActive, setShareModalActive] = useState(false);
  const [form] = Form.useForm();
  const [shareList, setShareList] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);
  const [screenRoutes, setScreenRoutes] = useState<any[]>([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (dispatched && benchmark && buildingId) {
      let screenNames = ["Floor Mapping"];
      if (benchmark.welcome) screenNames.push("Welcome");
      if (benchmark.executiveSummary && buildingId !== "251") screenNames.push("Executive Summary");

      benchmark.screens?.map((screen) => {
        if (buildingId !== "251") {
          screenNames.push(screen.title ?? "-");
          return; 
        }
        if (screen.title !== "Daily Cleaning") {
          screenNames.push(screen.title ?? "-");
          return; 
        }
      });
      const scnRts: any[] = Object.entries(ROUTES.benchmark)
        .filter((routeObj) => screenNames.includes(routeObj[1].name))
        .map(([routeKey, routeValues]) => {
          return [routeKey, routeValues];
        });
      const crtPgeIndx = scnRts.findIndex(
        (route, indx) =>
          route[1].route === location.pathname.split("/").pop() ?? ""
      );
      setPageNum(crtPgeIndx + 1);
      setScreenRoutes(scnRts);
    }
  }, [dispatched, benchmark, buildingId, location.pathname]);

  useEffect(() => {
    setPageNum(
      screenRoutes.findIndex(
        (route) => route[1].route === location.pathname.split("/").pop() ?? ""
      ) + 1
    );
  }, [location, screenRoutes]);

  useEffect(() => {
    const handlePageChange = (e: any) => {
      if ((pageNum ?? 0) < screenRoutes.length && e.key === "ArrowRight") {
        navigate(
          screenRoutes
            .find((route, indx) => indx === pageNum)[1]
            .getRoute(buildingId)
        );
      } else if ((pageNum ?? 0) > 1 && e.key === "ArrowLeft") {
        navigate(
          screenRoutes
            .find((route, indx) => indx === (pageNum ?? 2) - 2)[1]
            .getRoute(buildingId)
        );
      }
    };

    window.addEventListener("keydown", handlePageChange, true);
    return () => {
      window.removeEventListener("keydown", handlePageChange, true);
    };
  }, [pageNum, navigate, buildingId, screenRoutes]);

  function toggleFullScreen() {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  }

  const validateMessages = {
    required: "Email is required!",
    types: {
      email: "Email is not valid!",
    },
  };

  const onFinish = (values: any) => {
    updateShareList(values.email);
  };

  const updateShareList = (value: string) => {
    if (!shareList?.includes(value)) {
      setShareList([...shareList, value]);
      form.resetFields();
    }
  };

  //TODO connect with backend
  // const handleShare = () => {
  //   console.log("shareList", shareList);
  // }

  return (
    <div className={classNames(styles.headContainer, styles.subHead)}>
      <PageHeader
        title={renderPageTitle(screenRoutes, pageNum ?? 0)}
        icon={null}
        mainAction={null}
        setMainActionDisabled={true}
        breadcrumbsDisabled={true}
      />
      {pageNum !== 0 && (
        <div className={styles.pagination}>
          <LeftOutlined
            className={classNames(
              styles.button,
              pageNum === 1 ? styles.disabled : ""
            )}
            onClick={() =>
              (pageNum ?? 0) > 1
                ? navigate(
                    screenRoutes
                      .find((route, indx) => indx === (pageNum ?? 2) - 2)[1]
                      .getRoute(buildingId)
                  )
                : null
            }
            style={{
              background: "#313ab8",
            }}
          />
          <div className={styles.display}>
            <span>{pageNum}</span>
            <span>of</span>
            <span>{screenRoutes.length}</span>
          </div>
          <RightOutlined
            className={classNames(
              styles.button,
              pageNum === screenRoutes.length ? styles.disabled : ""
            )}
            onClick={() =>
              (pageNum ?? 0) < screenRoutes.length
                ? navigate(
                    screenRoutes
                      .find((route, indx) => indx === pageNum)[1]
                      .getRoute(buildingId)
                  )
                : null
            }
            style={{
              background: "#313ab8",
            }}
          />
          {isFullScreen ? (
            <CloseOutlined
              className={styles.button}
              onClick={toggleFullScreen}
              style={{
                background: "#313ab8",
              }}
            />
          ) : (
            <ExpandAltOutlined
              className={styles.button}
              onClick={toggleFullScreen}
              style={{
                background: "#313ab8",
              }}
            />
          )}
          <Button
            type="primary"
            className={styles.shareButton}
            style={{
              alignItems: "center",
              border: "1px solid #FFFFFF",
              background:
                "linear-gradient(to right, #313ab8 30%, #88227c  100%)",
            }}
            onClick={() => setShareModalActive(true)}
          >
            <ShareAltOutlined style={{ color: "#fff" }} />
            <span>Share</span>
          </Button>
          <Button
              type="primary"
              className={styles.shareButton}
              style={{
                alignItems: "center",
                border: "1px solid #FFFFFF",
                background:
                    "linear-gradient(to right, #313ab8 30%, #88227c  100%)",
              }}
              onClick={() => setShareModalActive(true)}
          >
            <FilePdfOutlined style={{ color: "#fff" }} />
            <span>PDF</span>
          </Button>
          <Button
            type="primary"
            className={styles.shareButtonSmall}
            style={{
              border: "1px solid #FFFFFF",
              background:
                "linear-gradient(to right, #313ab8 30%, #88227c  100%)",
              // background: "#88227c",
            }}
            onClick={() => setShareModalActive(true)}
            icon={<ShareAltOutlined />}
          />
          <Modal
            open={shareModalActive}
            onOk={() => setShareModalActive(false)}
            onCancel={() => setShareModalActive(false)}
            footer={[
              <div className={styles.modalFooterContainer}>
                <div style={{ width: "100%" }}>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setShareModalActive(false)}
                  >
                    Share
                  </Button>
                </div>
              </div>,
            ]}
          >
            <div className={styles.modalContainer}>
              <h3>Share Benchmark</h3>
              <Form
                form={form}
                onFinish={onFinish}
                validateMessages={validateMessages}
              >
                <Space
                  direction="vertical"
                  style={{ width: "100%", marginTop: "16px" }}
                >
                  <label>Enter email/s you want to share the app with</label>
                  <div className={styles.modalRow}>
                    <Form.Item
                      name={"email"}
                      rules={[{ required: true, type: "email" }]}
                      style={{ marginBottom: "0px", flex: "1" }}
                    >
                      <Input />
                    </Form.Item>
                    <Button type="default" htmlType="submit">
                      Add
                    </Button>
                  </div>
                  <div>
                    <ul>
                      {shareList.map((email) => {
                        return (
                          <li key={email}>
                            <div>
                              <span>{email}</span>
                              <DeleteOutlined
                                style={{ color: "red" }}
                                onClick={() =>
                                  setShareList(
                                    shareList.filter((item) => item !== email)
                                  )
                                }
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Space>
              </Form>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Head;

const renderPageTitle = (screenRoutes: any[], pageNum: number) => {
  if (!screenRoutes || screenRoutes.length === 0) return null;
  const pageName = screenRoutes.find((route, index) => index === pageNum - 1)[1]
    .name;

  return (
    <div className={styles.pageTitle}>
      <span className={styles.pageNumber}>{pageNum}</span>
      <Divider
        type="vertical"
        style={{
          margin: "0px 18px",
          height: "22px",
          border: "1px solid rgba(0, 0, 0, 0.06)",
        }}
      />
      <span>{pageName}</span>
    </div>
  );
};
