import http, { requestConfig } from "../../http-common";
const RESOURCE_NAME = "floors";
const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};
const get = (id: number | string) => {
  return http.get(`${RESOURCE_NAME}/${id}`, requestConfig());
};
const FloorsService = {
  getAll,
  get,
};
export default FloorsService;
