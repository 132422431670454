import http, { requestConfig } from "../../http-common";

const RESOURCE_NAME = "matterport";

const get = (spaceId: string) => {
  return http.get(`${RESOURCE_NAME}/space/${spaceId}`, requestConfig());
};

const getAll = () => {
  return http.get(`${RESOURCE_NAME}/spaces`, requestConfig());
};

const getSummary = (spaceId: string, password: string) => {
  if (password) {
    console.log(password);
    return http.get(
      `${RESOURCE_NAME}/space/${spaceId}/summary?password=${password}`,
      requestConfig()
    );
  }
  return http.get(`${RESOURCE_NAME}/space/${spaceId}/summary`, requestConfig());
};

const MatterportSpacesService = {
  get,
  getAll,
  getSummary,
};
export default MatterportSpacesService;
