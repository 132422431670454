import { Navigate, Outlet } from "react-router-dom";

import { ROUTES } from "../../config/constants";

const ProtectedRoute = ({
  profile,
  redirectPath = ROUTES.general.login.route,
}: {
  profile: any;
  redirectPath?: string;
}) => {
  if (!profile) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
