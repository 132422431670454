import { Divider, Spin, Switch, Table } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../config/constants";
import { ICard } from "../../../../models/benchmark/shared/Card";
import { ITable } from "../../../../models/benchmark/shared/Table";
import { actions, getBenchmark } from "../../../../store/benchmark";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import Card from "../../shared/card/Card";
import ScreenTable from "../../shared/table/Table";
import styles from "./RoboticVacuuming.module.scss";
interface IManualLabor {
  key: React.Key;
  manualLabor: string;
  value: string;
}

interface IDefault {
  key: React.Key;
  description: string;
  value: string;
}

const RoboticVacuuming = () => {
  const [dispatched, setDispatched] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<string>("Premium");
  const [cardData, setCardData] = useState<ICard[]>();
  const [tablesData, setTablesData] = useState<ITable[]>();

  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);

  const { buildingId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (dispatched && benchmark) {
      const screenData = benchmark.screens?.find(
        (scr) => scr.title === ROUTES.benchmark.roboticVacuuming.name
      );
      if (screenData?.dashboards) {
        const dashboard = screenData.dashboards[0];
        dashboard?.cards && setCardData(dashboard.cards);
        dashboard?.tables && setTablesData(dashboard.tables);
      }
    }
  }, [dispatched, benchmark]);

  return (
    <>
      <div className={styles.mobileRobotic}>
        {pending && (
          <Spin
            style={{
              display: "inline-flex",
              height: "calc(50vh - 64px)",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          />
        )}
        {!pending && benchmark && (
          <>
            <div className={styles.bodyContainer}>
              {cardData &&
                cardData.map((card, index) => (
                  <Card
                    selectable={false}
                    key={index}
                    data={card}
                    isActive={card.title === activeOption}
                    compareActive={true}
                    handleCardActivate={setActiveOption}
                  />
                ))}
            </div>
            <Divider style={{ margin: "16px 0" }} />
            <div className={styles.columnContainer}>
              <div>
                {tablesData &&
                  tablesData.map((table, index) => {
                    if (index === 1) return null;
                    return (
                      <div key={index} className={styles.tableContainer}>
                        <ScreenTable
                          key={index}
                          title={table.title}
                          headers={table.headers}
                          rows={table.rows ?? []}
                          activeOption={activeOption}
                        />
                      </div>
                    );
                  })}
              </div>
              {tablesData &&
                tablesData.map((table, index) => {
                  if (index !== 1) return null;
                  return (
                    <div key={index} className={styles.tableContainer}>
                      <ScreenTable
                        key={index}
                        title={table.title}
                        headers={table.headers}
                        rows={table.rows ?? []}
                        activeOption={activeOption}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      <div className={styles.container}>
        {pending && (
          <Spin
            style={{
              display: "inline-flex",
              height: "calc(50vh - 64px)",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          />
        )}
        {!pending && benchmark && (
          <>
            <div className={styles.bodyContainer}>
              {cardData &&
                cardData.map((card, index) => (
                  <Card
                    selectable={false}
                    key={index}
                    data={card}
                    isActive={card.title === activeOption}
                    compareActive={true}
                    handleCardActivate={setActiveOption}
                  />
                ))}
            </div>
            <Divider style={{ margin: "16px 0" }} />
            <div className={styles.columnContainer}>
              <div>
                {tablesData &&
                  tablesData.map((table, index) => {
                    if (index === 1) return null;
                    return (
                      <div key={index} className={styles.tableContainer}>
                        <ScreenTable
                          key={index}
                          title={table.title}
                          headers={table.headers}
                          rows={table.rows ?? []}
                          activeOption={activeOption}
                        />
                      </div>
                    );
                  })}
              </div>
              {tablesData &&
                tablesData.map((table, index) => {
                  if (index !== 1) return null;
                  return (
                    <div key={index} className={styles.tableContainer}>
                      <ScreenTable
                        key={index}
                        title={table.title}
                        headers={table.headers}
                        rows={table.rows ?? []}
                        activeOption={activeOption}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RoboticVacuuming;
