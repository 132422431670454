import PageHeader from "../../../shared/header/PageHeader";

import {
  PlusOutlined,
  ApartmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import {
  Button,
  Drawer,
  Form,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";

import styles from "../buildingtypes/BuildingTypes.module.css";
import { useEffect, useState } from "react";
import BuildingTypeEditForm from "./components/BuildingTypeEditForm";
import { UpsertBuildingTypeRequest } from "../../../../../models/buildingTypes/UpsertBuildingTypeRequest";
import BuildingTypesService from "../../../../../services/buildingtypes/BuildingTypesService";
import { useForm } from "antd/es/form/Form";
import VerticalsService from "../../../../../services/verticals/VerticalsService";
import { ROUTES } from "../../../../../config/constants";
import { useNavigate, useParams } from "react-router-dom";

const BuildingTypes = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [item, setItem] = useState<UpsertBuildingTypeRequest>();
  const [buildingTypes, setBuildingTypes] = useState<any>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [drawerForm] = useForm();

  const [verticals, setVerticals] = useState<any>([]);
  const [selectedVerticalId, setSelectedVerticalId] = useState<any>(undefined);

  const { id } = useParams();
  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.adminBuildingTypes.route,
    section: "Configuration",
    subcategory: "Building",
    name: ROUTES.general.adminBuildingTypes.name,
  };

  useEffect(() => {
    resetItem();
  }, []);

  const resetItem = () => {
    const model: UpsertBuildingTypeRequest = {
      name: "",
      verticalId: undefined,
    };
    setItem({ ...model });
  };

  useEffect(() => {
    VerticalsService.getAll().then((response: any) => {
      const data = response.data;
      setVerticals(data);
    });
  }, []);

  useEffect(() => {
    if (verticals && verticals.length > 0 && id) {
      setIsTableLoading(true);
      BuildingTypesService.getAll().then((response: any) => {
        const selectedBuildingType = response.data.find(
          (x: any) => x.id === parseInt(id)
        );
        if (selectedBuildingType) {
          setShowAddNewForm(true);
          setBuildingTypes(
            response.data.filter(
              (x: any) => x.verticalId === selectedBuildingType.verticalId
            )
          );
          setItem(selectedBuildingType);
        }
        setIsTableLoading(false);
      });
    } else if (selectedVerticalId) {
      setIsTableLoading(true);
      BuildingTypesService.getAll().then((response: any) => {
        const data = response.data;
        setBuildingTypes(
          data.filter((x: any) => x.verticalId === selectedVerticalId)
        );
        setIsTableLoading(false);
      });
      setShowAddNewForm(false);
    }
  }, [verticals, id, selectedVerticalId]);

  const handleAddNewBuilding = () => {
    setItem({ ...item!, verticalId: selectedVerticalId });
    setShowAddNewForm(true);
  };

  const handleCancelAdd = () => {
    resetItem();
    setShowAddNewForm(false);
    setLoadingButton(false);
    navigate(ROUTES.general.adminBuildingTypes.route);
  };

  const handleEditBuilding = (record: any) => {
    setShowAddNewForm(true);
    setItem(record);
    navigate(`${ROUTES.general.adminBuildingTypes.route}/${record.id}`);
  };

  const onDoubleClickRow = (record: any) => {
    console.log(record);
    setItem(record);
    navigate(`${ROUTES.general.adminBuildingTypes.route}/${record.id}`);
  };

  useEffect(() => {
    if (isTableLoading) {
      if (selectedVerticalId) {
        BuildingTypesService.getAll().then((response: any) => {
          const data = response.data;
          setBuildingTypes(
            data.filter((x: any) => x.verticalId === selectedVerticalId)
          );
          setIsTableLoading(false);
        });
      } else {
        BuildingTypesService.getAll().then((response: any) => {
          const data = response.data;
          setBuildingTypes(data);
          setIsTableLoading(false);
        });
      }
    }
  }, [isTableLoading, selectedVerticalId]);

  const onSaveBuildingType = () => {
    setLoadingButton(true);
    if (item?.id) {
      BuildingTypesService.update(item!).then((response: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        setItem(item);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminBuildingTypes.route);
      });
    } else {
      BuildingTypesService.create(item!).then((respone: any) => {
        setShowAddNewForm(false);
        setIsTableLoading(true);
        resetItem();
        setLoadingButton(false);
        navigate(ROUTES.general.adminBuildingTypes.route);
      });
    }
  };

  const handleDeleteBuildingType = (id: number) => {
    BuildingTypesService.remove(id).then((response: any) => {
      resetItem();
      setIsTableLoading(true);
    });
  };

  const handleVerticalSelect = (value: number) => {
    setSelectedVerticalId(value);
    setIsTableLoading(true);
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };

  const headerButton = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={handleAddNewBuilding}
    >
      Add
    </Button>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditBuilding(record)}
            style={{ color: "#313AB8" }}
          />
          <Popconfirm
            title="Are you sure you want to delete this building type?"
            placement="topRight"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteBuildingType(record?.id!)}
            onCancel={() => cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{ color: "#313AB8" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Buiding Types"}
        icon={<ApartmentOutlined />}
        mainAction={headerButton}
        setMainActionDisabled={false}
        additionalBreadcrumbs={
          !showAddNewForm && !item?.id ? null : [item?.name ?? ""]
        }
        route={route}
      />
      <div className={styles.bodyContent}>
        <Form>
          <Form.Item
            label="Verticals"
            name="verticals"
            style={{ marginBottom: "0" }}
          >
            <Select
              allowClear
              placeholder="Select Verticals"
              style={{ width: "300px" }}
              onChange={handleVerticalSelect}
              options={verticals.map((x: any) => ({
                label: x.name,
                value: x.id,
              }))}
            />
          </Form.Item>
        </Form>
        <Table
          size="middle"
          dataSource={buildingTypes}
          columns={columns}
          loading={isTableLoading}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                setShowAddNewForm(true);
                onDoubleClickRow(record);
              },
            };
          }}
        />
        <Drawer
          title={item?.id ? "Edit" : "Add"}
          placement="right"
          open={showAddNewForm}
          width="30%"
          closable={false}
          extra={
            <Space size="middle">
              <Button
                type="default"
                // onClick={handleAddNewRegion}
              >
                Audit
              </Button>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleCancelAdd}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={onSaveBuildingType}
                loading={loadingButton}
              >
                Save
              </Button>
            </Space>
          }
        >
          <BuildingTypeEditForm
            item={item}
            setItem={setItem}
            verticals={verticals}
            setVerticals={setVerticals}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default BuildingTypes;
