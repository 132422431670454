import {
  ImportOutlined,
  HomeOutlined,
  TableOutlined,
  ApartmentOutlined,
  LogoutOutlined,
  SettingOutlined,
  BuildOutlined,
  RadarChartOutlined,
  GlobalOutlined,
  ShopOutlined,
  ClearOutlined,
  EnvironmentOutlined,
  BulbOutlined,
  FieldBinaryOutlined,
} from "@ant-design/icons";
import { Avatar, MenuProps, Button, Popover, Space, Divider } from "antd";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../SidebarLayout.module.scss";
import "../SidebarLayout.css";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { resetProfile } from "../../../../../redux/actions";
import { logout } from "../../../../../store/profile";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

function getProfileItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const profileItems: MenuItem[] = [
  getProfileItem(
    "Zvonimir Gračak",
    "sub1",
    <Avatar size="default" className={styles.profileAvatar}>
      ZG
    </Avatar>,
    // <BuildOutlined />,
    [
      getProfileItem("Settings", "1", <SettingOutlined />),
      getProfileItem("Logout", "2", <LogoutOutlined />),
    ]
  ),
];

const scanItems: MenuItem[] = [
  getItem(
    <div>
      <Link to={ROUTES.general.import.route}>
        <ImportOutlined />
        <span>{ROUTES.general.import.name}</span>
      </Link>
    </div>,
    ROUTES.general.import.route,
    null
  ),
  // getItem(
  //   <div>
  //     <Link to={ROUTES.general.importSuccess.route}>
  //       <ImportOutlined />
  //       <span>{ROUTES.general.importSuccess.name}</span>
  //     </Link>
  //   </div>,
  //   ROUTES.general.importSuccess.route,
  //   null
  // ),
  getItem(
    <div>
      <Link to={ROUTES.general.managementBuildings.route}>
        <BuildOutlined />
        <span>{ROUTES.general.managementBuildings.name}</span>
      </Link>
    </div>,
    ROUTES.general.managementBuildings.route,
    null
  ),
];

const generalItems: MenuItem[] = [
  getItem(
    <div>
      <Link to={ROUTES.general.main.route}>
        <HomeOutlined />
        <span>{ROUTES.general.main.name}</span>
      </Link>
    </div>,
    ROUTES.general.main.route,
    null
  ),
];

const configurationItems: MenuItem[] = [
  getItem(
    <div>
      <GlobalOutlined />
      <span>Location</span>
      {/* <Link to={ROUTES.general.adminRegions.route}>
        <ApartmentOutlined />
        <span>{ROUTES.general.adminRegions.name}</span>
      </Link> */}
    </div>,
    "admin-location",
    null,
    [
      getItem(
        <div>
          <Link to={ROUTES.general.adminRegions.route}>
            <EnvironmentOutlined />
            <span>{ROUTES.general.adminRegions.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminRegions.route,
        null
      ),
      getItem(
        <div>
          <Link to={ROUTES.general.adminCountries.route}>
            <EnvironmentOutlined />
            <span>{ROUTES.general.adminCountries.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminCountries.route,
        null
      ),
    ]
  ),
  getItem(
    <div>
      <ShopOutlined />
      <span>Building</span>
      {/* <Link to={ROUTES.general.adminRegions.route}>
        <ApartmentOutlined />
        <span>{ROUTES.general.adminRegions.name}</span>
      </Link> */}
    </div>,
    "admin-building",
    null,
    [
      getItem(
        <div>
          <Link to={ROUTES.general.adminVerticals.route}>
            <ApartmentOutlined />
            <span>{ROUTES.general.adminVerticals.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminVerticals.route,
        null
      ),
      getItem(
        <div>
          <Link to={ROUTES.general.adminBuildingTypes.route}>
            <ApartmentOutlined />
            <span>{ROUTES.general.adminBuildingTypes.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminBuildingTypes.route,
        null
      ),
      getItem(
        <div>
          <Link to={ROUTES.general.adminRoomTypes.route}>
            <ApartmentOutlined />
            <span>{ROUTES.general.adminRoomTypes.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminRoomTypes.route,
        null
      ),
    ]
  ),
  getItem(
    <div>
      <ClearOutlined />
      <span>Cleaning</span>
      {/* <Link to={ROUTES.general.adminRegions.route}>
        <ApartmentOutlined />
        <span>{ROUTES.general.adminRegions.name}</span>
      </Link> */}
    </div>,
    "admin-cleaning",
    null,
    [
      getItem(
        <div>
          <Link to={ROUTES.general.adminCleaningTasks.route}>
            <ClearOutlined />
            <span>{ROUTES.general.adminCleaningTasks.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminCleaningTasks.route,
        null
      ),
      getItem(
        <div>
          <Link to={ROUTES.general.adminFrequencies.route}>
            <FieldBinaryOutlined />
            <span>{ROUTES.general.adminFrequencies.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminFrequencies.route,
        null
      ),
      getItem(
        <div>
          <Link to={ROUTES.general.adminItemMasterlist.route}>
            <BulbOutlined />
            <span>{ROUTES.general.adminItemMasterlist.name}</span>
          </Link>
        </div>,
        ROUTES.general.adminItemMasterlist.route,
        null
      ),
    ]
  ),
];

const SidebarMenu = ({ sidebarCollapsed }: { sidebarCollapsed: boolean }) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(
    location.pathname.includes("admin")
      ? `/admin/${location.pathname.split("/")[2]}`
      : `/${location.pathname.split("/")[1]}`
  );
  const [currentOpenKey, setCurrentOpenKey] = useState<any>([]);
  const { profile } = useSelector((state: any) => ({
    profile: state.profile.profile,
  }));

  useEffect(() => {
    if (location) {
      if (
        current !==
        (location.pathname.includes("admin")
          ? `/admin/${location.pathname.split("/")[2]}`
          : `/${location.pathname.split("/")[1]}`)
      ) {
        setCurrent(
          location.pathname.includes("admin")
            ? `/admin/${location.pathname.split("/")[2]}`
            : `/${location.pathname.split("/")[1]}`
        );
      }
    }
  }, [location, current]);

  function handleClick(e: any) {
    setCurrent(e.key);
    // setCurrentOpenKey([]);
  }

  const rootSubmenuKeys: any = [];

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find(
      (key) => currentOpenKey.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setCurrentOpenKey(keys);
    } else {
      setCurrentOpenKey(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleLogoutClick = () => {
    dispatch(logout({ username: "" })); // TODO: remove hardcode
  };

  const profileMenu = (
    <div>
      <Space direction="vertical">
        <Button block type="text" icon={<SettingOutlined />}>
          Settings
        </Button>
        <Button
          style={{ width: "100%" }}
          type="text"
          icon={<LogoutOutlined />}
          onClick={handleLogoutClick}
        >
          Logout
        </Button>
      </Space>
    </div>
  );

  return (
    <div id="app-sidebar" className={styles.sidebarMenuWrap}>
      {sidebarCollapsed ? (
        <div className={styles.sectionTitle}>
          <Divider
            style={{
              color: "var(--font-purple)",
              borderTop: "1px solid var(--font-purple",
              margin: "0",
            }}
          />
        </div>
      ) : (
        <div className={styles.sectionTitle}>
          <span>GENERAL</span>
        </div>
      )}
      <Menu
        defaultSelectedKeys={[""]}
        defaultOpenKeys={[""]}
        onClick={handleClick}
        selectedKeys={[current]}
        openKeys={currentOpenKey}
        onOpenChange={onOpenChange}
        mode="inline"
        theme="dark"
        items={generalItems}
      />
      {sidebarCollapsed ? (
        <div className={styles.sectionTitle}>
          <Divider
            style={{
              color: "var(--font-purple)",
              borderTop: "1px solid var(--font-purple",
              margin: "0",
            }}
          />
        </div>
      ) : (
        <div className={styles.sectionTitle}>
          {!profile.isGuestUser && (
            <span>SCAN</span>
          )}
        </div>
      )}
      <Menu
        defaultSelectedKeys={[""]}
        defaultOpenKeys={[""]}
        onClick={handleClick}
        selectedKeys={[current]}
        openKeys={currentOpenKey}
        onOpenChange={onOpenChange}
        mode="inline"
        theme="dark"
        items={profile.isGuestUser ? [] : scanItems}
      />
      {sidebarCollapsed ? (
        <div className={styles.sectionTitle}>
          <Divider
            style={{
              color: "var(--font-purple)",
              borderTop: "1px solid var(--font-purple",
              margin: "0",
            }}
          />
        </div>
      ) : (
        <div className={styles.sectionTitle}>
          {!profile.isGuestUser && (
            <span>CONFIGURATION</span>
          )}
        </div>
      )}
      <Menu
        defaultSelectedKeys={[""]}
        defaultOpenKeys={[""]}
        onClick={handleClick}
        selectedKeys={[current]}
        openKeys={currentOpenKey}
        onOpenChange={onOpenChange}
        mode="inline"
        theme="dark"
        items={profile.isGuestUser ? [] : configurationItems}
      />
      {/* <div className={styles.profileName}> */}
      <div className={styles.profileName}>
        <Popover placement="right" content={profileMenu}>
          <Avatar className={styles.profileAvatar} size="large">
            {profile && profile.firstName && profile.lastName ? (profile.firstName[0] + profile.lastName[0]) : ("User")}
          </Avatar>
          <Button
            type="text"
            style={{ color: "#fff", display: sidebarCollapsed ? "none" : "" }}
          >
            {profile && (profile.firstName + ' ' + profile.lastName + ' ')}
          </Button>
        </Popover>
      </div>
      {/* <Menu
        defaultSelectedKeys={[""]}
        defaultOpenKeys={[""]}
        // onClick={handleClick}
        // selectedKeys={[current]}
        // openKeys={currentOpenKey}
        // onOpenChange={onOpenChange}
        mode="vertical"
        theme="dark"
        items={profileItems}
      /> */}
    </div>
  );
};

export default SidebarMenu;
