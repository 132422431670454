import {
  PlusOutlined,
  ApartmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  FileAddOutlined,
  BuildOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";

import {
  Button,
  Drawer,
  message,
  Popconfirm,
  Result,
  Space,
  Table,
} from "antd";

import styles from "../import/Import.module.css";
import { useEffect, useState } from "react";
import PageHeader from "../../shared/header/PageHeader";
import { Typography } from "antd";
const { Title } = Typography;

const ImportSuccess = () => {
  return (
    <div>
      <PageHeader
        title={"Verticals"}
        icon={<ApartmentOutlined />}
        mainAction={""}
        setMainActionDisabled={false}
      />
      <div className={styles.bodyContent}>
        <Result
          status="success"
          title="Successful Import"
        />
        <div className={styles.tablesWrap}>
          <div className={styles.tableContainer}>
            <Title
              level={4}
              style={{ margin: "0", color: "#000", paddingLeft: "10px" }}
            >
              Scan Data
            </Title>
            <table>
              <tr>
                <td>Name</td>
                <td>Scan #001</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>Test description</td>
              </tr>
              <tr>
                <td>Operator</td>
                <td>Zvonimir</td>
              </tr>
            </table>
          </div>
          <div className={styles.tableContainer}>
            <Title
              level={4}
              style={{ margin: "0", color: "#000", paddingLeft: "10px" }}
            >
              Building Data
            </Title>
            <table>
              <tr>
                <td>Name</td>
                <td>NHS</td>
              </tr>
              <tr>
                <td>Region</td>
                <td>Europe</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>UK</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>Healthcare</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>London</td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <Title level={4}>What to do next?</Title>
          <div className={styles.nextContainer}>
            <div className={styles.linkContainer}>
              <Space align="center" direction="vertical" size="middle">
                <FileAddOutlined style={{ fontSize: "24px" }} />
                <span>New import</span>
              </Space>
            </div>
            <div className={styles.linkContainer}>
              <Space align="center" direction="vertical" size="middle">
                <BuildOutlined style={{ fontSize: "24px" }} />
                <span>View building data</span>
              </Space>
            </div>
            <div className={styles.linkContainer}>
              <Space align="center" direction="vertical" size="middle">
                <Loading3QuartersOutlined style={{ fontSize: "24px" }} />
                <span>View processing status</span>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportSuccess;
