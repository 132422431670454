import { Form, Spin, Table, Tag } from "antd";

import { useEffect, useState } from "react";
import AssetsService from "../../../../../services/assets/AssetsService";
import ScansService from "../../../../../services/scans/ScansService";

import { SyncOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  IAsset,
  IAssetCleaningTask,
  IGeometry,
  IRoom,
  IRoomAsset,
} from "../../../../../models/floorplan/Floorplan";
import {
  IScanAsset,
  IScanResponse,
} from "../../../../../models/scans/ScanResponse";
import { IUpdateBuildingRequest } from "../../../../../models/buildings/UpdateBuildingRequest";
import BuildingsService from "../../../../../services/buildings/BuildingsService";

const ScanResults = ({
  item,
  setItem,
}: {
  item: IUpdateBuildingRequest | undefined;
  setItem: any;
}) => {
  const [scansLoading, setScansLoading] = useState<boolean>(false);
  const [scanAssetsLoading, setScanAssetsLoading] = useState<boolean>(false);
  const [scans, setScans] = useState<IScanResponse[]>();
  const [scanAssets, setScanAssets] = useState<IAssetCleaningTask[]>();

  useEffect(() => {
    setScanAssetsLoading(true);
    if (item?.id) {
      BuildingsService.getScans(item.id).then((response: any) => {
        setScans(response.data);
      });
    }
  }, [item]);

  const columns = [
    {
      title: "Asset",
      dataIndex: "name",
      key: "name",
      elipsis: true,
      width: "40%",
    },
    {
      title: "X,Y,Z",
      dataIndex: "xyz",
      key: "xyz",
      elipsis: true,
    },
    {
      title: "Room",
      dataIndex: "roomName",
      key: "roomName",
      elipsis: true,
    },
    {
      title: "Floor",
      dataIndex: "floorName",
      key: "floorName",
      elipsis: true,
    },
  ];

  return (
    <div>
      {scansLoading && (
        <Spin
          style={{
            transform: "translate(-50%, -50%)",
            left: "calc(50vw + 125px)",
            top: "50vh",
            position: "fixed",
          }}
        />
      )}
      {scans &&
        scans?.map((scan: IScanResponse, index: number) => {
          const scanAssets = scan.floors?.flatMap((floor) =>
            floor.rooms?.flatMap((room) =>
              room.scanAssets?.flatMap((scanAsset: IRoomAsset) => {
                return {
                  ...scanAsset,
                  floorId: floor.id,
                };
              })
            )
          );
          return (
            <div key={index}>
              <Form>
                <Form.Item label={`${index + 1}. Scan Status:`}>
                  <div>
                    {scan.status === "Completed" && (
                      <Tag icon={<CheckCircleOutlined />} color="success">
                        completed
                      </Tag>
                    )}
                    {scan.status === "InQueue" && (
                      <Tag icon={<SyncOutlined spin />} color="processing">
                        processing
                      </Tag>
                    )}
                  </div>
                </Form.Item>
              </Form>
              <Table
                size="middle"
                columns={columns}
                dataSource={scanAssets?.map((scanAsset: any) => {
                  const floorName = scan.floors?.find(
                    (floor) => floor.id === scanAsset.floorId
                  )?.name;
                  const transformedGeometry: any = scanAsset.geoJsonGeometry
                    ? JSON.parse(scanAsset.geoJsonGeometry)
                    : null;
                  const coordinates = transformedGeometry?.coordinates;
                  return {
                    name: scanAsset.asset?.name,
                    xyz: coordinates
                      ? coordinates[0].toFixed(2).toString() +
                        ", " +
                        coordinates[1].toFixed(2).toString() +
                        ", " +
                        coordinates[2].toFixed(2).toString()
                      : "Not available",
                    roomName: scanAsset.roomName,
                    floorName: floorName,
                  };
                })}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ScanResults;
