import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from "redux";
import ThunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import reducer from './redux/reducer';
import store from './store/store';
 
const fetchedItem = localStorage.getItem("reduxState");
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(fetchedItem !== null ? fetchedItem : "")
  : {};

// export const store = createStore(
//   reducer,
//   persistedState,
//   applyMiddleware(ThunkMiddleware)
// );

// store.subscribe(() => {
//   localStorage.setItem("reduxState", JSON.stringify(store.getState()));
// });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
