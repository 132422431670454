import { IHeader, IRow } from "../../../../models/benchmark/shared/Table";
import styles from "./Table.module.scss";
import Table, { ColumnsType } from "antd/es/table";

const ScreenTable = ({
  title,
  headers,
  rows,
  activeOption,
}: {
  title?: string | null;
  headers?: IHeader[] | null;
  rows?: IRow[] | null;
  activeOption?: string | null;
}) => {
  return (
    <>
      <div className={styles.mobileTableContainer}>
        {title && <h3>{title}</h3>}
        <Table
          className={styles.table}
          size="small"
          dataSource={rows?.map((row: IRow, index: number) => {
            const rowProps: any = {};
            headers?.forEach((col: IHeader, indx: number) => {
              const cellValue = row.cells?.[indx].data?.formattedValue;
              rowProps[col.name as string] = cellValue;
            });
            return {
              key: index,
              ...rowProps,
            };
          })}
          columns={
            headers
              ?.filter(
                (col: IHeader, index: number) =>
                  index === 0 || col.name === activeOption
              )
              .map((col: IHeader, index: number) => {
                return {
                  title: col.name,
                  dataIndex: col.name,
                  key: col.name ?? index,
                  className:
                    col.name === activeOption
                      ? columnStyle(activeOption ?? "")
                      : "",
                  hidden: !col.isVisible,
                  width: "300px",
                };
              }) as ColumnsType<any>
          }
          pagination={false}
        />
      </div>
      <div className={styles.bodyContainer} style={{flex: "0 0 100%"}}>
        {title && <h3>{title}</h3>}
        <Table
          className={styles.table}
          size="small"
          dataSource={rows?.map((row: IRow, index: number) => {
            const rowProps: any = {};
            headers?.forEach((col: IHeader, indx: number) => {
              const cellValue = row.cells?.[indx].data?.formattedValue;
              rowProps[col.name as string] = cellValue;
            });
            return {
              key: index,
              ...rowProps,
            };
          })}
          columns={
            headers?.map((col: IHeader, index: number) => {
              return {
                title: col.name,
                dataIndex: col.name,
                key: col.name ?? index,
                className:
                  col.name === activeOption
                    ? columnStyle(activeOption ?? "")
                    : "",
                hidden: !col.isVisible,
                width: "300px",
              };
            }) as ColumnsType<any>
          }
          pagination={false}
        />
      </div>
    </>
  );
};

export default ScreenTable;

const columnStyle = (title: string) => {
  switch (title) {
    case "Premium":
      return styles.activePremium;
    case "Optimal":
      return styles.activeOptimal;
    case "Basic":
      return styles.activeBasic;
    case "Custom":
      return styles.activeCustom;
    default:
      return styles.activePremium;
  }
};
