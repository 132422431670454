export const transformGeometry = (geometry: any) => {
  if (!geometry)
    return {
      type: "None",
      coordinates: [],
    };
  let { type, coordinates } = geometry;
  const transformedCoordinates = coordinates.flatMap((elem: any[]) => {
    return elem.map((coordinate: any[]) => {
      const newArr = [coordinate[1], -1 * coordinate[0], coordinate[2] ?? 0];
      return newArr;
    });
  });
  return {
    type,
    coordinates: [transformedCoordinates],
  };
};

export const revertTransformGeometry = (
  geometry: any,
  ceilingHeight?: number | null
) => {
  if (!geometry)
    return {
      type: "None",
      coordinates: [],
    };
  let { type, coordinates } = geometry;
  const transformedCoordinates = coordinates.flatMap((elem: any[]) => {
    return elem.map((coordinate: any[]) => {
      const newArr = [
        -1 * coordinate[1],
        coordinate[0],
        coordinate[2] ?? ceilingHeight ?? 0,
      ];
      return newArr;
    });
  });
  return {
    type,
    coordinates: [transformedCoordinates],
  };
};
