import styles from "./ScanningBenefits.module.scss";
import { Divider } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

interface IScanningBenefit {
  summary: string;
  title: string;
  content: string[];
}

const data: IScanningBenefit[] = [
  {
    summary: "Currently, 3D data (digital twin) mainly used operationally in Construction, Surveying, Architectural and Design.  As well used commercially in Property and  Realestate.",
    title: "KEY OUTCOMES",
    content: [
      "Benchmark",
      "99 % accuracy versus  80% onsite walkthrough or any time and motion study",
      "10 X faster than a time and motion study",
      "Measures all dimensions, objects, assets, colours, surfaces areas and geo tag of locations",
      "Accurate contract  pricing from the start or re-tender",
      "Cost savings achieved from 20 % +",
      "Time savings 10 X within bid & tendering process",
      "Onboarding of matched suppliers ",
    ],
  },
  {
    summary: "ScanQuo is the first of its kind to tie these data sets and digitise operational efficiency and benchmark contract pricing within the total range of  Facilities Management Services.",
    title: "SOFTWARE AND SERVICES ROADMAP BUILD",
    content: [
      "Asset Tracking",
      "Dilapidation",
      "HVAC",
      "Electrical Compliance",
      "M&E",
      "Painting",
      "Security",
      "Workspace Utilisation",
      "Service modules",
    ],
  },
];

const ScanningBenefits = () => {
  //TODO Fetch Data from DB
  return (
    <div className={styles.container}>
      <div>
        {data.map((benefit, index) => {
          return scanBenefit(benefit, index);
        })}
      </div>
    </div>
  );
};

export default ScanningBenefits;

const scanBenefit = (benefit: IScanningBenefit, index: number) => {
  return (
    <div key={index} className={styles.contentContainer}>
      <Divider className={styles.sqBorder} />
      <p>{benefit.summary}</p>
      <div className={styles.contentList}>
        <h2>{benefit.title}</h2>
        <i className="x-icon x-graphic-child x-graphic-icon x-graphic-primary" aria-hidden="true" data-x-icon-o=""></i>
        <ul>
          {benefit.content.map((item: any, index: number) => {
            return <li key={index}><CheckCircleOutlined className={styles.listItemIcon} /> {item}</li>
          })}          
        </ul>
      </div>
    </div>
  );
};
