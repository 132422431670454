import { Spin, Image } from "antd";
import styles from "./ExecutiveSummary.module.scss";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { useEffect, useState } from "react";
import { sortByNumberProperty } from "../../../../utils/sort";
import {
  TrophyOutlined,
  DollarOutlined,
  SettingOutlined,
  BorderHorizontalOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { actions, getBenchmark } from "../../../../store/benchmark";
import { useParams, useSearchParams } from "react-router-dom";
import { IExecutiveSummary } from "../../../../models/benchmark/executive-summary/ExecutiveSummary";
import workTasksIcon from "../../../../img/work_tasks_icon.svg";
import systematicIcon from "../../../../img/sys_spproach_icon.svg";
import productivityIcon from "../../../../img/productivity_icon.png";
import benchmarkIcon from "../../../../img/benchmark_quality_icon.svg";
import roboticIcon from "../../../../img/robotic_icon.svg";
import costIcon from "../../../../img/cost_icon.svg";

const ExecutiveSummaries = () => {
  const [execSummaries, setExecSummaries] = useState<
    IExecutiveSummary[] | null
  >(null);
  const [dispatched, setDispatched] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);

  const { buildingId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (dispatched && benchmark) {
      setExecSummaries(benchmark.executiveSummary ?? null);
    }
  }, [dispatched, benchmark]);

  return (
    <div className={styles.container} style={{flexDirection: "column"}}>
      {pending && (
          <Spin
              style={{
                transform: "translate(-50%, -50%)",
                left: "calc(50vw + 125px)",
                top: "50vh",
                position: "fixed",
              }}
          />
      )}
      {buildingId == "217" && (
          <div className={styles.cardContainer} style={{width: "100%", height: "50px"}}>
            <div className={styles.cardItem} style={{width: "100%", height: "50px", maxWidth: "100%"}}>
              <div className={styles.cardContent} style={{width: "100%"}}>
                <div className={styles.cardTitle} style={{textAlign: "center", width: "100%"}}>{"CUSTOMIZABLE REPORT"}</div>
              </div>
            </div>
          </div>
      )}
      <div className={styles.cardContainer}>
        {execSummaries &&
            [...execSummaries]
                .sort((a, b) => sortByNumberProperty(a.order, b.order))
                .map((summItem: IExecutiveSummary, index: any) => {
                  return (
                      <CardItem
                          key={index}
                          summItem={summItem}
                          ordinalNum={index + 1}
                      />
                  );
                })}
      </div>
    </div>
  );
};

export default ExecutiveSummaries;

const CardItem = ({
                    summItem,
                    ordinalNum,
                  }: {
  summItem: IExecutiveSummary;
  ordinalNum: number;
}) => {
  return (
      <div className={styles.cardItem}>
        <div className={styles.cardIcon}>
          {/* <div>
          <span>{ordinalNum}</span>
        </div>
        {renderIcon(ordinalNum)} */}
        {ordinalNum === 1 && <Image src={workTasksIcon} preview={false} />}
        {ordinalNum === 2 && <Image src={systematicIcon} preview={false} />}
        {ordinalNum === 3 && <Image src={productivityIcon} preview={false} />}
        {ordinalNum === 4 && <Image src={benchmarkIcon} preview={false} />}
        {ordinalNum === 5 && <Image src={roboticIcon} preview={false} />}
        {ordinalNum === 6 && <Image src={costIcon} preview={false} />}
      </div>
      <div className={styles.cardContent}>
        <div className={styles.cardTitle}>{summItem.title?.toUpperCase()}</div>
        <div className={styles.cardDescription}>{summItem.text}</div>
      </div>
    </div>
  );
};

const renderIcon = (ordinalNum: number) => {
  switch (ordinalNum) {
    case 1:
      return <TrophyOutlined style={{ fontSize: "28px", color: "#FFFFFF" }} />;
    case 2:
      return <DollarOutlined style={{ fontSize: "28px", color: "#FFFFFF" }} />;
    case 3:
      return <SettingOutlined style={{ fontSize: "28px", color: "#FFFFFF" }} />;
    case 4:
      return (
        <BorderHorizontalOutlined
          style={{ fontSize: "28px", color: "#FFFFFF" }}
        />
      );
    case 5:
      return (
        <PieChartOutlined style={{ fontSize: "28px", color: "#FFFFFF" }} />
      );
    default:
      return <TrophyOutlined style={{ fontSize: "28px", color: "#FFFFFF" }} />;
  }
};
