import { Breadcrumb, Space } from "antd";
import React from "react";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import headerStyles from "../header/PageHeader.module.css";
import { Typography } from "antd";

const { Title } = Typography;

const PageHeader = ({
  title,
  icon,
  mainAction,
  setMainActionDisabled,
  breadcrumbsDisabled = false,
  additionalBreadcrumbs: optionalBreadcrumbs = null,
  route = {},
}: {
  title: string | React.ReactNode;
  icon: any;
  mainAction: any;
  setMainActionDisabled: boolean;
  breadcrumbsDisabled?: boolean | null;
  additionalBreadcrumbs?: string[] | null;
  route?: any;
}) => {
  return (
    <div className={headerStyles.title_and_btns}>
      <div className={headerStyles.title_and_breadcrumbs}>
        <div className={headerStyles.title}>
          {icon}
          <Title style={{ margin: 0 }} level={3}>
            {title}
          </Title>
        </div>
        {!breadcrumbsDisabled && (
          <div>
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{route.section}</span>
              </Breadcrumb.Item>
              {route.subcategory && (
                <Breadcrumb.Item>
                  <span>{route.subcategory}</span>
                </Breadcrumb.Item>
              )}
              <Breadcrumb.Item>{route.name}</Breadcrumb.Item>
              {optionalBreadcrumbs &&
                optionalBreadcrumbs?.length > 0 &&
                optionalBreadcrumbs.map((breadcrumb, index) => (
                  <Breadcrumb.Item key={index}>
                    <span>{breadcrumb}</span>
                  </Breadcrumb.Item>
                ))}
            </Breadcrumb>
          </div>
        )}
      </div>

      <div>
        <Space direction="horizontal">{mainAction}</Space>
      </div>
    </div>
  );
};

export default PageHeader;
