import http, { requestConfig } from "../../http-common";
import { UpsertCountriesRequest } from "../../models/countries/UpsertCountriesRequest";

const RESOURCE_NAME = "countries";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const create = (model: UpsertCountriesRequest) => {
  return http.post(RESOURCE_NAME, model, requestConfig());
};

const update = (model: UpsertCountriesRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const remove = (regionId: number) => {
  return http.delete(`${RESOURCE_NAME}/${regionId}`, requestConfig());
};

const CountriesService = {
  getAll,
  create,
  update,
  remove,
};
export default CountriesService;
