import http, { requestConfig } from "../../http-common";
import { CreateBuildingRequest } from "../../models/buildings/CreateBuildingRequest";
import { IUpdateBuildingRequest } from "../../models/buildings/UpdateBuildingRequest";

const RESOURCE_NAME = "buildings";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const get = (id: number | string) => {
  return http.get(`${RESOURCE_NAME}/${id}`, requestConfig());
};

const create = (model: CreateBuildingRequest) => {
  return http.post(RESOURCE_NAME, model, requestConfig());
};

const update = (model: IUpdateBuildingRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const getScans = (id: number | string) => {
  return http.get(`${RESOURCE_NAME}/${id}/scans`, requestConfig());
};

const BuildingsService = {
  getAll,
  get,
  create,
  update,
  getScans,
};
export default BuildingsService;
