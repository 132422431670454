import http from "../../http-common";
import { LoginRequest } from "../../models/auth/LoginRequest";
import { LogoutRequest } from "../../models/auth/LogoutRequest";

const RESOURCE_NAME_LOGIN = "auth";
const RESOURCE_NAME_LOGOUT = "logout";

const login = (model: any) => {
  return http.post(RESOURCE_NAME_LOGIN, model);
};
const logout = (model: any) => {
  return http.post<LogoutRequest>(RESOURCE_NAME_LOGOUT, model);
};

const AuthService = {
  login,
  logout,
};
export default AuthService;
