import http, { requestConfig } from "../../http-common";
import { IOverheadTemplate } from "../../models/buildings/UpdateBuildingRequest";

const RESOURCE_NAME = "overheadtemplates";

const put = (model: IOverheadTemplate) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const OverheadTemplatesService = {
  put,
};
export default OverheadTemplatesService;
