import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";
import { profileReducer } from "./profile";
import { benchmarkReducer } from "./benchmark";
import { authReducer } from "./auth";

const combinedReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  benchmark: benchmarkReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "benchmark/resetBenchmark") {
    state = {
      auth: state.auth,
      profile: state.profile,
      benchmark: {
        benchmark: null,
        pending: false,
        error: false,
      },
    } as RootState;
  }
  return combinedReducer(state, action);
};

const fetchedItem = localStorage.getItem("reduxState");
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(fetchedItem !== null ? fetchedItem : "")
  : {};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

store.subscribe(() => {
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;

export default store;
