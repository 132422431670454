import http, { requestConfig } from "../../http-common";
import { UpsertBuildingTypeRequest } from "../../models/buildingTypes/UpsertBuildingTypeRequest";

const RESOURCE_NAME = "buildingtypes";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const create = (model: UpsertBuildingTypeRequest) => {
  return http.post(RESOURCE_NAME, model, requestConfig());
};

const update = (model: UpsertBuildingTypeRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const remove = (regionId: number) => {
  return http.delete(`${RESOURCE_NAME}/${regionId}`, requestConfig());
};

const BuildingTypesService = {
  getAll,
  create,
  update,
  remove,
};
export default BuildingTypesService;
