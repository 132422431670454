import { Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";

import styles from "../../itemmasterlist/ItemMasterlist.module.css";

import { useState } from "react";
import { Typography } from "antd";

const { TextArea } = Input;

const { Title } = Typography;
const { Option } = Select;

const ItemMasterListEditForm = () => {
  const [inputName, setInputName] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [inputFixedCost, setInputFixedCost] = useState<any>("");
  const [inputBasic, setInputBasic] = useState<any>("");
  const [inputOptimal, setInputOptimal] = useState<any>("");
  const [inputPremium, setInputPremium] = useState<any>("");
  const [inputTiming, setInputTiming] = useState<any>("");
  const [selectedAssociatedBuildingType, setSelectedAssociatedBuildingType] =
    useState<any>("");

  const onNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setInputName(inputValue);
  };

  const handleSelectedBuildingType = (value: string) => {
    setSelectedAssociatedBuildingType(value);
  };

  const onDescriptionInputChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (event) => {
    setDescriptionInput(event.target.value);
  };

  const onTimingChange = (value: number | null) => {
    setInputTiming(value);
  };

  const onFixedCostChange = (value: number | null) => {
    setInputFixedCost(value);
  };

  const onBasicInputChange = (value: number | null) => {
    setInputBasic(value);
  };

  const onOptimalInputChange = (value: number | null) => {
    setInputOptimal(value);
  };

  const onPremiumInputChange = (value: number | null) => {
    setInputPremium(value);
  };

  return (
    <div className={styles.formWrap}>
      <>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            General
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 8 },
                  sm: { span: 8 },
                  md: { span: 8 },
                  lg: { span: 6 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Name"
                name="name"
              >
                <Input
                  value={inputName}
                  onInput={onNameInputChange}
                  placeholder="name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{
                  xs: { span: 10 },
                  sm: { span: 10 },
                  md: { span: 10 },
                  lg: { span: 8 },
                }}
                wrapperCol={{ offset: 2 }}
                label="Associated building types"
                name="associatedBuildingTypes"
              >
                <Select
                  placeholder="Select associated building types"
                  mode="multiple"
                  allowClear
                  value={selectedAssociatedBuildingType}
                  onChange={handleSelectedBuildingType}
                >
                  <Option value="hospital">Hospital</Option>
                  <Option value="Airport">Airport</Option>
                  <Option value="Office">Office</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={12}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 8 },
                      sm: { span: 8 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Description"
                    name="name"
                  >
                    <TextArea
                      value={descriptionInput}
                      autoSize
                      onChange={onDescriptionInputChange}
                      placeholder="Input your description"
                    ></TextArea>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginBottom: "16px" }}
              >
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 8 },
                      sm: { span: 8 },
                      md: { span: 8 },
                      lg: { span: 8 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Timing(s)"
                    name="timing"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      defaultValue={0}
                      value={inputTiming}
                      onChange={onTimingChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginBottom: "16px" }}
              >
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 8 },
                      sm: { span: 8 },
                      md: { span: 8 },
                      lg: { span: 8 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Fixed cost($)"
                    name="fixedCost"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      defaultValue={0}
                      value={inputFixedCost}
                      onChange={onFixedCostChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    // labelCol={{ span: 7 }}
                    // wrapperCol={{ offset: 2 }}
                    // label="Timing(s)"
                    name="isPeriodic"
                  >
                    <Checkbox>Is Periodic</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            Frequencies
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={12}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginBottom: "16px" }}
              >
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 8 },
                      sm: { span: 8 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Basic"
                    name="basic"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      defaultValue={0}
                      value={inputBasic}
                      onChange={onBasicInputChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginBottom: "16px" }}
              >
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 8 },
                      sm: { span: 8 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Optimal"
                    name="optimal"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      defaultValue={0}
                      value={inputOptimal}
                      onChange={onOptimalInputChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 8 },
                      sm: { span: 8 },
                      md: { span: 8 },
                      lg: { span: 6 },
                    }}
                    wrapperCol={{ offset: 2 }}
                    label="Premium"
                    name="premium"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      defaultValue={0}
                      value={inputPremium}
                      onChange={onPremiumInputChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    </div>
  );
};

export default ItemMasterListEditForm;
