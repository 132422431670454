import http, { requestConfig } from "../../http-common";
import { UpsertVerticalsRequest } from "../../models/verticals/UpsertVerticalsRequest";

const RESOURCE_NAME = "verticals";

const getAll = () => {
  return http.get(RESOURCE_NAME, requestConfig());
};

const create = (model: UpsertVerticalsRequest) => {
  return http.post(RESOURCE_NAME, model, requestConfig());
};

const update = (model: UpsertVerticalsRequest) => {
  return http.put(`${RESOURCE_NAME}/${model.id}`, model, requestConfig());
};

const remove = (id: number) => {
  return http.delete(`${RESOURCE_NAME}/${id}`, requestConfig());
};

const VerticalsService = {
  getAll,
  create,
  update,
  remove,
};
export default VerticalsService;
