import { Button, Divider, Drawer, Space, Spin, Switch } from "antd";
import { useState } from "react";
import { IDashboard } from "../../../../models/benchmark/Benchmark";
import ScreenTable from "../table/Table";
import styles from "./Dashboard.module.scss";
import Card from "../card/Card";
import {
  QuestionCircleOutlined,
  MoreOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  ToolOutlined,
  FieldTimeOutlined,
  WalletOutlined,
  LineChartOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { ROUTES } from "../../../../config/constants";
import NhsCard from "../card/NhsCard";
import { useParams } from "react-router-dom";

const Dashboard = ({
  pending,
  dashboardData,
  dashboardFrRatingsData,
}: {
  pending?: boolean | null;
  dashboardData?: IDashboard | null;
  dashboardFrRatingsData?: IDashboard | null;
}) => {
  const [compareActive, setCompareActive] = useState<boolean>(true);
  const [detailsActive, setDetailsActive] = useState<boolean>(true);
  const [frRatingsActive, setFrRatingsActive] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<string>("Premium");
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const { buildingId } = useParams();

  const onCardClick = (cardId: string) => {
    setActiveOption(cardId);
    setShowDetailsDrawer(true);
  };

  const onClose = () => {
    setShowDetailsDrawer(false);
  };
  return (
    <>
      <div className={styles.mobileSummary}>
        <div className={styles.btnContainer}>
          {/* <Dropdown
          // menu={{ items }}
          placement="bottomLeft"
          overlayClassName={styles.dropdownMenu}
          trigger={["click"]}
        >
          <Button type="primary" ghost icon={<MoreOutlined />}>
            Adjustments
          </Button>
        </Dropdown> */}
          {/* <Button
          style={{
            border: "1px solid #FFFFFF",
            background:
              "linear-gradient(to right, #313ab8 30%, #88227c  100%)",
            // background: "#130F21"
          }}
          type="primary"
          icon={<SearchOutlined />}
          // onClick={() => showWalkModal()}
        >
          Walking time
        </Button> */}
        </div>
        <div>
          {pending && (
            <Spin
              style={{
                display: "inline-flex",
                height: "calc(50vh - 64px)",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            />
          )}
          {!pending && dashboardData && (
            <>
              {(dashboardData?.cards?.length ?? 0) > 0 && (
                <div className={styles.mobileHeadContainer}>
                  {dashboardData?.cards?.some(
                    (card) => card.title === "Custom"
                  ) && (
                    <div>
                      <label>Compare with custom benchmark</label>
                      <Switch
                        defaultChecked={true}
                        onChange={() => setCompareActive(!compareActive)}
                      />
                    </div>
                  )}

                  {/* <div>
                <label>Show details</label>
                <Switch
                  defaultChecked={false}
                  onChange={() => setDetailsActive(!detailsActive)}
                />
              </div> */}
                </div>
              )}
              {dashboardData?.cards && (
                <>
                  <div className={styles.mobileCardContainer}>
                    {dashboardData?.cards.map((card, index) => {
                      if ((buildingId == "73" || buildingId == "217")) {
                        return (
                          <NhsCard
                            key={index}
                            data={card}
                            isActive={card.title === activeOption}
                            compareActive={compareActive}
                            handleCardActivate={onCardClick}
                          />
                        );
                      }
                      return (
                        <Card
                          key={index}
                          data={card}
                          isActive={card.title === activeOption}
                          compareActive={compareActive}
                          handleCardActivate={onCardClick}
                        />
                      );
                    })}
                  </div>
                  <Divider style={{ margin: "16px 0" }} />
                </>
              )}
              {detailsActive &&
                dashboardData?.tables &&
                dashboardData?.tables.map((table, index) => {
                  return (
                    <div key={index} className={styles.bodyContainer}>
                      <ScreenTable
                        key={index}
                        title={table.title}
                        headers={
                          compareActive &&
                          dashboardData?.cards?.some(
                            (card) => card.title === "Custom"
                          )
                            ? table.headers
                            : table.headers?.filter(
                                (col) => col.name !== "Custom"
                              )
                        }
                        rows={table.rows ?? []}
                        activeOption={activeOption}
                      />
                    </div>
                  );
                })}
            </>
          )}
        </div>
        <Drawer
          title="Details"
          placement="bottom"
          closable={false}
          onClose={onClose}
          open={showDetailsDrawer}
          height="600px"
          extra={
            <Space size="middle">
              <Button
                type="primary"
                // danger
                icon={<CloseOutlined />}
                onClick={onClose}
              ></Button>
            </Space>
          }
        >
          {dashboardData?.tables &&
            dashboardData?.tables.map((table, index) => {
              return (
                <div key={index} className={styles.bodyContainer}>
                  <ScreenTable
                    key={index}
                    title={table.title}
                    headers={
                      compareActive &&
                      dashboardData?.cards?.some(
                        (card) => card.title === "Custom"
                      )
                        ? table.headers
                        : table.headers?.filter((col) => col.name !== "Custom")
                    }
                    rows={table.rows ?? []}
                    activeOption={activeOption}
                  />
                </div>
              );
            })}
        </Drawer>
      </div>
      <div className={styles.totalSummaryContainer}>
        {pending && (
          <Spin
            style={{
              display: "inline-flex",
              height: "calc(50vh - 64px)",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          />
        )}
        {!pending && dashboardData && !frRatingsActive && (
          <>
            {((dashboardData?.cards?.length ?? 0) > 0 ||
              dashboardFrRatingsData) && (
              <div className={styles.headContainer}>
                {(dashboardData?.cards?.length ?? 0) > 0 &&
                  dashboardData?.cards?.some(
                    (card) => card.title === "Custom"
                  ) && (
                    <div>
                      <label>Compare with custom benchmark</label>
                      <Switch
                        defaultChecked={true}
                        onChange={() => setCompareActive(!compareActive)}
                      />
                    </div>
                  )}
                {(dashboardData?.cards?.length ?? 0) > 0 && (
                  <div>
                    <label>Show details</label>
                    <Switch
                      defaultChecked={false}
                      checked={detailsActive}
                      onChange={() => setDetailsActive(!detailsActive)}
                    />
                  </div>
                )}
                {dashboardFrRatingsData && (
                  <div>
                    <label>Show FR Ratings</label>
                    <Switch
                      defaultChecked={false}
                      checked={frRatingsActive}
                      onChange={() => setFrRatingsActive(!frRatingsActive)}
                    />
                  </div>
                )}
              </div>
            )}
            {dashboardData?.cards && dashboardData.cards.length > 0 && (
              <>
                <div className={styles.bodyContainer}>
                  {dashboardData?.cards.map((card, index) => {
                    if ((buildingId == "73" || buildingId == "217")) {
                      return (
                        <NhsCard
                          key={index}
                          data={card}
                          isActive={card.title === activeOption}
                          compareActive={compareActive}
                          handleCardActivate={onCardClick}
                        />
                      );
                    }
                    return (
                      <Card
                        key={index}
                        data={card}
                        isActive={card.title === activeOption}
                        compareActive={compareActive}
                        handleCardActivate={onCardClick}
                      />
                    );
                  })}
                </div>
                {dashboardData.title === "Floorcare" && (
                  <div className={styles.infoContainer}>
                    <InfoCircleOutlined className={styles.icon} />
                    <span>
                      Total square feet / Total Basic cost = Cost per square
                      feet
                    </span>
                  </div>
                )}
                <Divider style={{ margin: "16px 0" }} />
              </>
            )}
            {detailsActive &&
              dashboardData?.tables &&
              dashboardData?.tables.map((table, index) => {
                return (
                  <div key={index} className={styles.bodyContainer}>
                    <ScreenTable
                      key={index}
                      title={table.title}
                      headers={
                        compareActive &&
                        dashboardData?.cards?.some(
                          (card) => card.title === "Custom"
                        )
                          ? table.headers
                          : table.headers?.filter(
                              (col) => col.name !== "Custom"
                            )
                      }
                      rows={table.rows ?? []}
                      activeOption={activeOption}
                    />
                  </div>
                );
              })}
          </>
        )}
        {!pending && dashboardFrRatingsData && frRatingsActive && (
          <>
            {((dashboardFrRatingsData?.cards?.length ?? 0) > 0 ||
              dashboardData) && (
              <div className={styles.headContainer}>
                {(dashboardFrRatingsData?.cards?.length ?? 0) > 0 &&
                  dashboardFrRatingsData?.cards?.some(
                    (card) => card.title === "Custom"
                  ) && (
                    <div>
                      <label>Compare with custom benchmark</label>
                      <Switch
                        defaultChecked={true}
                        onChange={() => setCompareActive(!compareActive)}
                      />
                    </div>
                  )}

                {(dashboardFrRatingsData?.cards?.length ?? 0) > 0 && (
                  <div>
                    <label>Show details</label>
                    <Switch
                      defaultChecked={false}
                      checked={detailsActive}
                      onChange={() => setDetailsActive(!detailsActive)}
                    />
                  </div>
                )}
                {dashboardFrRatingsData && (
                  <div>
                    <label>Show FR Ratings</label>
                    <Switch
                      defaultChecked={false}
                      checked={frRatingsActive}
                      onChange={() => setFrRatingsActive(!frRatingsActive)}
                    />
                  </div>
                )}
              </div>
            )}
            {dashboardFrRatingsData?.cards &&
              dashboardFrRatingsData.cards.length > 0 && (
                <>
                  <div className={styles.bodyContainer}>
                    {dashboardFrRatingsData?.cards.map((card, index) => (
                      <Card
                        key={index}
                        data={card}
                        isActive={card.title === activeOption}
                        compareActive={compareActive}
                        handleCardActivate={setActiveOption}
                      />
                    ))}
                  </div>
                  {dashboardFrRatingsData.title === "Floorcare" && (
                    <div className={styles.infoContainer}>
                      <InfoCircleOutlined className={styles.icon} />
                      <span>
                        Total square feet / Total Basic cost = Cost per square
                        feet
                      </span>
                    </div>
                  )}
                  <Divider style={{ margin: "16px 0" }} />
                </>
              )}
            {detailsActive &&
              dashboardFrRatingsData?.tables &&
              dashboardFrRatingsData?.tables.map((table, index) => {
                return (
                  <div key={index} className={styles.bodyContainer}>
                    <ScreenTable
                      key={index}
                      title={table.title}
                      headers={
                        compareActive &&
                        dashboardFrRatingsData?.cards?.some(
                          (card) => card.title === "Custom"
                        )
                          ? table.headers
                          : table.headers?.filter(
                              (col) => col.name !== "Custom"
                            )
                      }
                      rows={table.rows ?? []}
                      activeOption={activeOption}
                    />
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
