import { Col, Form, Input, Row } from "antd";

import styles from "../../buildingtypes/BuildingTypes.module.css";

import { useEffect } from "react";
import { Typography } from "antd";
import { UpsertRoomTypesRequest } from "../../../../../../models/roomtypes/UpsertRoomTypesRequest";
import { useForm } from "antd/es/form/Form";

const { Title } = Typography;

const RoomTypesEditForm = ({
  item,
  setItem,
}: {
  item: UpsertRoomTypesRequest | undefined;
  setItem: any;
}) => {
  const [drawerForm] = useForm();

  useEffect(() => {
    if (item) {
      drawerForm.setFieldsValue({
        name: item.name,
      });
    }
  }, [item, drawerForm]);

  const onNameInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    setItem({ ...item, name: inputValue });
  };

  return (
    <div className={styles.formWrap}>
      <>
        <div className={styles.formSection}>
          <Title
            level={4}
            style={{ margin: "0", color: "#313AB8", paddingLeft: "10px" }}
          >
            General
          </Title>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ paddingLeft: "20px" }}
          >
            <Col span={24}>
              <Form.Item
                style={{ marginBottom: "0" }}
                labelAlign="left"
                labelCol={{ span: 4 }}
                wrapperCol={{ offset: 2 }}
                label="Name"
                name="name"
              >
                <Input
                  value={item?.name}
                  onInput={onNameInputChange}
                  placeholder="name"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    </div>
  );
};

export default RoomTypesEditForm;
