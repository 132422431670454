import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginRequest } from "../../models/auth/LoginRequest";
import { LogoutRequest } from "../../models/auth/LogoutRequest";
import AuthService from "../../services/auth/AuthService";

export const login = createAsyncThunk(
  "auth/login",
  async (model: LoginRequest) => {
    const res = await AuthService.login(model);
    return res.data;
  }
);
export const logout: any = createAsyncThunk(
  "auth/logout",
  async (model: LogoutRequest) => {
    // const res = await AuthService.logout(model);
    // return res.data;

    return true;
  }
);
