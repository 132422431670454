import axios from "axios";

export default axios.create({
  baseURL: "https://scanquo-platform-api.azurewebsites.net",
});

export const requestConfig = () => {
  const fetchedItem = localStorage.getItem("reduxState");
  const persistedState = localStorage.getItem("reduxState")
    ? JSON.parse(fetchedItem !== null ? fetchedItem : "")
    : {};

  const config = {
    headers: {
      // "Content-type": "application/json",
      // Authorization: `Bearer ${persistedState.profile.accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  return config;
}